//================================================================
//  Component: Edit Worker Projects Modal
//================================================================

//  Purpose: Allows global admins to assign workers to projects

//  Properties:
//    - modalState = {useState - used to toggle visibility of modal}
//    - setModalState = {A setState function - used to toggle the modal visibility}

//  Example:
//  <EditWorkerProjectsModal
//    modalState={modalState}
//    setModalState={setModalState}
//  ></EditWorkerProjectsModal>

//================================================================

//Librariesis to top
import React, { useState, useReducer, useEffect, useContext } from 'react';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components

//Functions
import GetDocument from '../../../Library/GetDocument';
import WriteAuditLog from '../../../Library/WriteAuditLog';
import UpdateArrayInDocument from '../../../Library/UpdateArrayInDocument';

//Images
import AddProject from '../../../Components/Images/Icon_AddProject_Blue.svg';
import LoadingIcon from '../../../Components/Images/Image_Loading_Ripple.svg';
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';
import CloseModal from '../../../Components/Images/Icon_Close_Grey.svg';

export default function EditWorkerProjectsModal({
  modalState,
  setModalState
}) {

  //------------------------------------------------------
  //  useContext
  //------------------------------------------------------

  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Used to determine the state of the modal > 'pending', 'onload', 'error-fatal'
    const [modalStatus, setModalStatus] = useState('onload');
    
    // Used to determine if the 'Save' button is active
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to save the form inputs (email address, given name and surname)
    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'projectSearchInput': '',
        'searchResultsCount': '',
        'allProjects': [],
        'filteredProjects': [], 
        'selectedProjects': [],
        'modalErrors': ''
      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------
    
    // Handle search
    function handleSearch(value) {

      formData.projectSearchInput = value;

      if (value === '') {

        // Reset Filter
        formData.filteredProjects = formData.allProjects;
        formData.searchResultsCount = '';
      
      } else {

        const results = formData.allProjects.filter((object) => 
          object?.projectname?.toLowerCase()?.includes(value?.toLowerCase())
        );

        formData.filteredProjects = results;
        formData.searchResultsCount = results.length;

      };

      setFormData(formData);

    };

    // Handle multi-select dropdowns
    const handleMultiSelect = (event) => {

      let selected = Array.from(
        event.target.selectedOptions,
        (option) => option.value
      )

      // Get list of all of the worker's existing projects
      const existingProjects = modalState.editModalUser.projects.map((item) => item.projectname).sort();

      if(JSON.stringify(existingProjects) === JSON.stringify(selected.sort())) {
        setSaveButtonDisabled(true);

      } else {
        setSaveButtonDisabled(false);

      }

      setFormData({ [event.target.name]: [...selected]  });

    };

    // Handle Save
    function handleSave() {

      // Get list of all of the worker's existing projects
      const existingProjects = modalState.editModalUser.projects.map((item) => item.projectname).sort();

      // Prevent save if there are no changes made
      if(JSON.stringify(existingProjects) === JSON.stringify(formData.selectedProjects.sort())) return;

      //------------------------------------------------------
      //  Update user document with new list of projects
      //------------------------------------------------------

      // Get the project IDs for current and selected projects
      const currentProjects = modalState.editModalUser?.projects.map((item) => item.projectid);
      const selectedProjects = modalState.allProjects.filter((project) => formData.selectedProjects.includes(project.projectname)).map((item) => item.projectid);

      // Update User Document
      return UpdateArrayInDocument('users', modalState.editModalUser.emailaddress, currentProjects, selectedProjects, 'projects')
      .then(() => {

        // Auditing
        const message = `${getUser.emailaddress} -- Assigned worker ${modalState.editModalUser.emailaddress} to project(s) ${formData.selectedProjects.toString()}.`;
        const types = [
          'app settings'
        ];
        const references = [
          getUser.emailaddress,
          modalState.editModalUser.emailaddress,
          'projects',
        ];

        // Write Audit Log
        return WriteAuditLog(getUser.emailaddress, message, types, references)
        .then(() => {
          setFormData({
            'projectSearchInput': '',
            'searchResultsCount': '',
            'allProjects': [],
            'filteredProjects': [], 
            'selectedProjects': [],
            'modalErrors': ''
          })
          setModalState({
            'pageStatus': 'pending',
            'editModalUser': undefined 
          });

        });

      })
      .catch((error) => {
        console.log(error)
        setFormData({ 'modalErrors': error })
        setModalStatus('error-fatal');

      });

    }


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Onload > Get user's organisation doc and its projects
    useEffect(() => {

      if (modalState.editModalUser?.organisationid === undefined) return;
      
      GetDocument('organisations', modalState.editModalUser?.organisationid)
      .then((document) => {


        if (document?.projects.length === 0) return;

        const projectPromises = [];

        // For each project associated with this org > Get project document
        document?.projects.forEach((project) => {

          projectPromises.push(
            GetDocument('projects', project)
          );

        });

        // Settle all promises
        Promise.all(projectPromises)
        .then((results) => {
          const sortedResults = results.sort(function(a, b) {
            var textA = a.projectname.toLowerCase();
            var textB = b.projectname.toLowerCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });

          setFormData({
            'allProjects': sortedResults,
            'filteredProjects': sortedResults,
            'selectedProjects': modalState.editModalUser?.projects.map((item) => item.projectname)
          });
          setModalStatus('onload');

        })

      
      })
      .catch((error) => {
        setFormData({ 'modalErrors': error });
        setModalStatus('error-fatal');
       
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalState]);


  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    //====================================================
    //  Modal Hidden
    //====================================================
    
    if (modalState.editModalUser === undefined) return null;

    //====================================================
    //  Pending
    //====================================================

    else if (modalStatus === 'pending') {
        return (
          <div className='Modal-Background'>
            <dialog className='Invite-Worker-Container'>
              <div className='flex justify-center'>
                <img alt="loading-circle-icon" src={LoadingIcon} width='200px'></img>
              </div>
            </dialog>
          </div>
        )
    }
  
    //====================================================
    //  Error-Fatal
    //====================================================

    else if (modalStatus === 'error-fatal') {
      return (
      <div className='Modal-Background'>
        <dialog className='Edit-Worker-Container'>
          <div className='flex flex-col text-center gap-2'>
              <h4>Oops something went wrong!</h4>
              
              <p className=''>
                An unknown error has occurred while attempting to assign worker to project(s).
                <br></br><br></br>
                If the error persists, please speak with your administrator. 
              </p>
              <p > 
                <b>Message:</b> {formData?.modalErrors}
              </p>

              <div>
                <button className='Primary-Button' onClick={() => setFormData({
                  'pageStatus': 'pending',
                  'editModalUser': undefined
                })}>Go Back</button>
              </div>
          </div>
        </dialog>
      </div>
      )
    }

    //====================================================
    //  Onload
    //====================================================

    else {
      return (
        <div className='Modal-Background'>
          <dialog className='Invite-Worker-Container'>

            <div className='Action-Modal-Container'>
              
              {/* Modal Icon */}
              <div className='Action-Modal-Icon'>
                  <img src={AddProject} alt='Invite User Icon'></img>
              </div>

              {/* Modal Body */}
              <div className='Action-Modal-Body [@media(max-width:500px)]:mr-0'>
              
                <h4>Manage Projects</h4>
                  
                {/* ================================================ */}
                {/*              Select Project                      */}
                {/* ================================================ */}            
                
                <div className='flex flex-col'>

                  {/* <--------------SELECT PROJECT--------------> */}
                  <label className='text-black '>Please select the project(s) you would like to assign to <b className='font-medium'>{`${modalState.editModalUser?.givenname} ${modalState.editModalUser?.surname}`}</b>. </label>
                  <div className='bg-[#E8F0F2] p-3 mb-2 text-sm leading-6 rounded-md'>
                    <label className='text-black font-medium'>Note:</label>
                    <ul className='mb-2'>
                      <li className='list-disc'>When you select a new project, a prequalification for that project will be sent to this worker.</li>
                      <li className='list-disc'>When you de-select a project, the worker's existing prequalification for that project will be <b className='font-medium'>removed</b>.</li>
                      <li className='list-disc'>If a project is not listed below, it means the project has not been associated with the worker's organisation.</li>
                    </ul>
                  </div>

                  {/* Search Box */}
                  <div className='AppSettings-Search-Container my-2'>

                    <div className='flex flex-row justify-between rounded-[5px] border-[#D8D8D8] border px-[10px] py-2'>
                      <div className='flex flex-row'>
                        <img src={IconSearch} alt='search-icon' hidden={formData.searchResultsCount !== ''}></img>
                        <input
                          type='text'
                          name='projectSearchInput'
                          className='ml-2 focus:outline-none'
                          placeholder='Search projects'
                          onChange={(e) => handleSearch(e.target.value)}
                          value={formData.projectSearchInput}
                        ></input>
                      </div>
                      <img className='cursor-pointer' src={CloseModal} alt='Close'hidden={formData.searchResultsCount === ''} onClick={() => setFormData({
                        'projectSearchInput': '',
                        'searchResultsCount': '',
                        'filteredProjects': formData.allProjects,
                      })}></img>
                    </div>

                  </div>

                  {/* <--------------PROJECTS LIST--------------> */}
                  <div>
                    
                    <p className='text-sm text-slate-600 mt-2 mb-[10px] p-0'>To select more than one, press Ctrl and select your options.</p> 

                    {/* Project List */}
                    <select 
                      className='Input-Field-MultiSelect'
                      name='selectedProjects'
                      onChange={(event) => handleMultiSelect(event)}
                      value={formData.selectedProjects}
                      multiple
                    >
                      <option hidden value=''>-</option>
                      {
                        formData.filteredProjects?.length > 0 ?
                        (
                          formData.filteredProjects.map((project, index) => (
                            <option key={index} value={project.projectname}> 
                              {project.projectname} 
                            </option>
                          ))
                        )
                        :
                        (
                          <option>
                            No projects found.
                          </option>
                        )
                      }
                    </select>

                  </div>

                  {/* <------------------BUTTONS-----------------> */}
                  <div className='flex flex-row gap-2 mt-4 '>
                    <button className='Primary-Button' disabled={saveButtonDisabled} onClick={handleSave}> Save </button>
                    <button 
                      className='Secondary-Button' 
                      onClick={() => {
                          setFormData({
                            'selectedProjects': []
                          });
                          setModalState({ 'editModalUser': undefined });
                        }
                      }
                    > 
                      Cancel 
                    </button> 
                  </div>

                </div>

              </div>

            </div>

          </dialog>
        </div>
      )
  }

}