//================================================================
//  Component: Navigation Header
//================================================================
//  Purpose: This is the navigation header for the Lendlease DCAC Admin Portal
//  Example:
//    <NavHeader></NavHeader>
//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors, GetProfileSelector, SetProfileSelector } from '../../Library/GlobalContexts';

//Components
import Logout from '../../Library/Logout';
import ProfileModal from './Components/ProfileModal';

//Functions
import getDocument from '../../Library/GetDocument';
import QueryListener from '../../Library/QueryListener';

//Images
import AppMenu from '../Images/Icon_Menu_White.svg';
import Settings from '../Images/Icon_AdminSettings_White.svg';

//CSS
import './NavHeader.css';


export default function NavHeader() {

  //------------------------------------------------------
  //  React Router & useContexts
  //------------------------------------------------------

    const [ searchParms ] = useSearchParams();
    const emailAddress = searchParms.get('emailaddress');
    
    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);
    const getProfileSelector = useContext(GetProfileSelector);
    const setProfileSelector = useContext(SetProfileSelector);
    const setAppStatus = useContext(SetAppErrors);

    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to toggle visibility of the profile selector modal
    const [showModal, setShowModal] = useState(false);

    // USed to toggle visibility of the sign out menu
    const [showMenu, setShowMenu] = useState(false);

  
  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // Onload
    //  1. listen for emailAddress changes
    //  2. Updates 'selectedWorker' with user document
    useEffect(() => {

      // Conditions
      if (emailAddress === undefined) return;

      // NO 'emailAddress' was passed into the url
      if (emailAddress === null) {

        getProfileSelector.selectedWorker = getUser;
        return setProfileSelector({...getProfileSelector});

      };

      // Valid user was passed into the url
      getDocument('users', emailAddress).then((document) => {

        getProfileSelector.selectedWorker = document;
        setProfileSelector({...getProfileSelector});

      }).catch(() => {

        // Do Nothing --> Hacker could be fishing for users

      });
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailAddress]);


    // Onload
    //  1. listen for getUser changes
    //  2. Check the users role (orgAdmin only)
    //  3. Update 'projects' list based off their role
    useEffect(() => {

      // Conditions
      if (getUser === undefined) return;
      if (getUser.orgAdmin.length === 0) return;

      function onLoadChange(documents){

        getProfileSelector.workers = documents;
        setProfileSelector({...getProfileSelector});

      }
  
      function onError(error){

        setAppStatus('failed');
        setAppErrors(`Failed to get project information, unable to create the document listener error: ${error}`);  
      
      }
  
      const unsubscribe = QueryListener('users', [
        ['organisationid', '==', getUser.organisationid],
        ['status', '==', 'active']

      ], onLoadChange, onLoadChange, onError)
  
      return () =>{
        unsubscribe();
      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------
  return (
    <div className='NavHeader-Container'>

      {/* Title */}
      <h4 className='NavHeader-Title whitespace-nowrap overflow-hidden overflow-ellipsis' onClick={() => navigate('/')}> Lendlease DCAC Portal </h4>

      {/* Profile Selector Modal */}
      {
        getUser.orgAdmin.length > 0 && 
        <button className='Profile-Selector-Dropdown' onClick={() => setShowModal(!showModal)}>
          {getProfileSelector?.selectedWorker?.givenname} {getProfileSelector?.selectedWorker?.surname}
        </button>
      }

      {/* Profile Modal */}    
      <ProfileModal
        showModal={showModal}
        setShowModal={setShowModal}
      ></ProfileModal>

      {/* Button --> Opens menu */}
      <img 
        className='absolute right-[25px] cursor-pointer'
        src={AppMenu} 
        alt='Menu' 
        onClick={() => setShowMenu(!showMenu)}
      ></img>

      {/* Drop Menu */}
      {
        showMenu === true && (
          <div className='NavHeader-Menu'>
            
            {/* Settings */}
            <div 
              className='flex flex-row justify-between gap-4 px-4 py-[13px] mb-0 cursor-pointer border-b border-b-neutral-500' 
              onClick={() => {
                setShowMenu(false);
                navigate('/settings');
              }}
              hidden={getUser.orgAdmin.length === 0}
            >
              Settings
              <img 
                src={Settings} 
                alt='Settings' 
                width='25px' 
                height='25px' 
              ></img>
            </div>
            <Logout
              title={'Sign Out'}
              setStyle={'navbar'}
            ></Logout>
          </div>
        )
      }

    </div>
  )
}