/**
 * Converts a Firestore timestamp to a date string
 * 
 * @param {timestamp} date
 * 
 * ```
 * <td>
 *   {ConvertDate(object?.enddate)}
 * </td>
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/reference/js/v8/firebase.firestore.Timestamp
 */

export default function ConvertDate(date){

    try {

        return date.toDate().toLocaleString();

    } catch (error) {
        
        return
        
    }
     
}

//--------------------------------------------------------