//================================================================
//  Component: Generic Upload File Component
//  Created by Mark Bennett
//================================================================

//  Purpose: This component handles the uploading of existing DCAC file to a GCS bucket

//  Properties:
//  - formData = {useState, used to store file inputs for form}
//  - setFormData = {useState, used to update useState with uploaded files url}
//  - dataField = {useReducer Field, string of the field name}
//  - errorMessageField = {useReducer Field, string of the field name}
//  - validField = {useReducer Field, string of the field name}

//  Example:
//    <FileUploadDCACForm
//      formData={formData}
//      setFormData={setFormData}
//      dataField={existingDCACFiles}
//      errorMessageField={existingDCACErrorMessage}
//      validField={existingDCACValid}
//     ></FileUploadDCACForm>

//================================================================


//Libraries
import React, { useState, useContext } from 'react';

//Contexts
import { GetUser, GetAppSize } from '../../../Library/GlobalContexts';

//Components

//Functions
import UploadFile from '../../../Library/UploadFile';

//Images
import LoadingIcon from '../.././../Components/Images/Icon_LoadingFile_Grey.gif';
import RemoveFileIcon from '../.././../Components/Images/Remove.svg';
import ViewFileIcon from '../.././../Components/Images/View_File_Icon.svg';

//CSS
import '../../../Pages/PreQualifications/PreQualifications.css'


export default function FileUploadDCACForm({
    formData,
    setFormData,
    dataField,
    errorMessageField,
    validField,
}) {

    //------------------------------------------------------
    //  useContext
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const getAppSize = useContext(GetAppSize);

    //------------------------------------------------------
    //  useStates & useRef
    //------------------------------------------------------

    // Handles the state of the upload > 'onload', 'pending', 'error-fatal'
    const [uploadStatus, setUploadStatus] = useState('onload');

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // File Selector > Event Handler
    function handleChange(file) {

        //------------------------------------------------------
        // Validate incoming file
        //------------------------------------------------------

        if (!file) return setFormData({
            [validField]: false,
            [errorMessageField] : 'Select a File'
        });
        
        let fileType;
        try {
            fileType = file.name.split('.')
            fileType = fileType[fileType.length - 1].toLowerCase();

        } catch (error) {
            
            return setFormData({
                [validField]: false,
                [errorMessageField] : 'Supported file types include png, jpeg, jpg and pdf.'
            });

        }

        //------------------------------------------------------
        //  Client side validation --> Otherwise the upload MUST complete before the storage rules reject the request
        //------------------------------------------------------

        if (file.size > 20000000) return setFormData({
            [validField]: false,
            [errorMessageField] : 'File must be smaller then 20MB'
        });
        if (fileType !== 'png' && fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'pdf') return setFormData({
            [validField]: false,
            [errorMessageField] : 'Supported file types include png, jpeg, jpg and pdf.'
        });

        //------------------------------------------------------
        // Passed all checks > proceed with upload
        //------------------------------------------------------

        setFormData({
            [errorMessageField] : '',
            [validField] : true,
            'preventSave': true,
        });
        
        setUploadStatus('pending');

        const filename = `${Date.now()}.${fileType}`;

        //Upload the file to GCS
        UploadFile(`tempuploads/${getUser?.emailaddress}/${filename}`, file)
        .then((url) => {

            // Save the file URL and file name to formData
            formData[dataField].push({
                'fileid': `${filename}`,
                'signedurl': url,
                'filename': file.name,
                'status': 'new',
            });
            setFormData({
                [validField]: true,
                'preventSave': false,
            });
            setUploadStatus('onload');
        })
        .catch((error) => {

            setFormData({
                [errorMessageField]: error.message,
                'preventSave': false,
            });
            setUploadStatus('error-fatal');

        });

    }

    // Try Again Button Handler
    function handleUploadStatus(status) {

        setFormData({[errorMessageField] : ''});
        setUploadStatus(status);

    }

    // Remove file handler
    function removeFileHandler(object) {

        // Flag file to be deleted onsubmit!
        object.tempstatus = 'deleted';

        setFormData({[dataField]: formData[dataField]});

        // If no files exists, change status
        if (formData[dataField].filter((object) => object.tempstatus !== 'deleted').length === 0){
            setUploadStatus('onload');
        }

    }

    //------------------------------------------------------
    //  Return HTML
    //------------------------------------------------------

        //------------------------------------------------------
        //  onload
        //------------------------------------------------------

        if (uploadStatus === 'onload') {

            return (
                <div>
                    <div className='PreQ-Uploads-Container'>

                        {/* File Input Field */}
                        <input
                            className={formData[validField] ? ('Input-Field-Text') : ('Input-Field-Text-Error')}
                            type='file'
                            onChange={(e) => handleChange(e.target.files[0])}
                        ></input>

                    </div>

                    {/* Uploads Table */}
                    {
                        // Check if the object is flagged as 'deleted'
                        formData[dataField].filter((object) => object.tempstatus !== 'deleted').length > 0 &&

                        <div className='PreQ-Uploads-List-Container'>

                            <b>FILE NAME</b>

                            {/* File Link */}
                            {
                                formData[dataField].map((object, index) => (
                                    <div hidden={object.tempstatus === 'deleted' ? true : false} className='PreQ-Uploads-List-Row' key={index}>

                                        <div className='flex flex-row'>

                                            {/* Remove File Icon */}
                                            <img 
                                                className='PreQ-Uploads-List-Delete' 
                                                src={RemoveFileIcon} 
                                                alt='Remove File' 
                                                title='Clear File' 
                                                onClick={() => removeFileHandler(object)}
                                            ></img>

                                            {/* File Name */}

                                            {
                                                getAppSize.width >= 700 ? 

                                                    object.filename
                                                
                                                : 
                                                    <a
                                                        className='text-[#0d6efd]'
                                                        href={object.signedurl}
                                                        target='_blank'
                                                        rel='noreferrer'
                                                        >
                                                            {object.filename}
                                                    </a>
                                                    
                                            }

                                        </div>

                                        {/* View File */}
                                        {
                                            getAppSize.width >= 700 && 

                                                <a
                                                    href={object.signedurl}
                                                    target='_blank'
                                                    rel='noreferrer'>
                                                    <img src={ViewFileIcon} alt='View File' title='View File'></img>
                                                </a>

                                        }


                                    </div>
                                ))
                            }
                        </div>
                    }

                    {/* Error Message */}
                    {!formData[validField] && <label className='PreQ-Uploads-Error-Message'>{formData[errorMessageField]}</label>}

                </div>
            )

        }

        //------------------------------------------------------
        //  pending
        //------------------------------------------------------

        else if (uploadStatus === 'pending') {

            return (
                <div className='PreQ-Uploads-Container'>

                    <img src={LoadingIcon} alt='loading-icon' width='20px' height='20px'></img>
                    <div>
                        Uploading...
                    </div>

                </div>
            )

        }

        //------------------------------------------------------
        //  error-fatal
        //------------------------------------------------------

        else if (uploadStatus === 'error-fatal') {

            return (
                <div className='PreQ-Uploads-Error-Container'>
                    Failed to upload file, please try again.

                    {/* Try Again */}
                    <button className='Primary-Button' style={{ width: 'fit-content' }} onClick={() => handleUploadStatus('onload')}>Try Again</button>
                </div>
            )

        }

    //------------------------------------------------------
}
