//Libraries
// eslint-disable-next-line
import React, { useContext, useEffect } from 'react';
import { getAuth, onAuthStateChanged, OAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { firebaseApp } from '../index.js';

//Functions
import GetDocument from './GetDocument';
import DocumentListener from './DocumentListener';

//Contexts
import { SetMicrosoftFireBaseProvider, SetGoogleFireBaseProvider, SetFireBaseUser, GetUser, SetUser, GetAppStatus, SetAppStatus, SetAppErrors} from './GlobalContexts';



/**
 * Handles any auth interactions with FireBase and
 * saves all interactions to useContexts/session storage.
 * 
 * @param {object} children
 * 
 * ```
 * ReactDOM.render(
 *     <UserContexts>
 *       <AuthProvider>
 *         <LoginHandler></LoginHandler>
 *       </AuthProvider>
 *     </UserContexts>,
 *   document.getElementById('root')
 * );
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/web/setup
 */



export default function AuthProvider({children}) {

  //------------------------------------------------------
  //  Firebase
  //------------------------------------------------------

    const auth = getAuth(firebaseApp);

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const setFirebaseUser = useContext(SetFireBaseUser);
    const setUser = useContext(SetUser);
    const getUser = useContext(GetUser);
    const setMicrosoftFireBaseProvider = useContext(SetMicrosoftFireBaseProvider);
    const setGoogleFirebaseProvider = useContext(SetGoogleFireBaseProvider)

    const getAppStatus = useContext(GetAppStatus);
    const setAppStatus = useContext(SetAppStatus);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  //  Find the users current auth state when the apps auth state changes
  //------------------------------------------------------

    useEffect(() => {

      onAuthStateChanged(auth, (user) =>{

        //Successful sign in 
        if (user){

          //------------------------------------------------------
          //  Save Firebase profile to useContext
          //------------------------------------------------------

          setFirebaseUser(user);

          //------------------------------------------------------
          //  Get profile > Check 'organisationid' & 'status'
          //------------------------------------------------------

          GetDocument('users', user.email, true).then((document) => {

            // If we are NOT able find a user document
            if (document === undefined) {

              setUser(undefined);
              return setAppStatus('accessdenied');

            }

            // Check the user is active, has an organisation id
            if (document.status === 'active' && document?.organisationid.length > 0) {
  
              setUser(document);
              return setAppStatus('authenticated');

            }

            // Bugger off > access denied
            setAppStatus('accessdenied');
            
          }).catch(() =>{

            // Unable to query Firestore collectio
            setUser(undefined);
            setAppStatus('failed');
            return setAppErrors(`bad-network-connection`);

          });
       
        // Catch if the app any other state than 'unauthenticated' 
        } else if (getAppStatus !== 'unauthenticated') {

          setAppStatus('unauthenticated');

        }

      });

    // eslint-disable-next-line
    }, []);

    //Save the auth state to session storage
    //This allows us to presist data after refreshes
    useEffect(() => {

      sessionStorage.setItem('getAppStatus', getAppStatus);

    }, [getAppStatus]);

  //------------------------------------------------------
  //  Listens for any changes to to the user collection
  //  Update the user context with current data
  //------------------------------------------------------
  
    useEffect(() => {
      
      if (getAppStatus !== 'authenticated') return;
      if (getUser === undefined) return;

      function onLoadChange(document){

        if (document?.status !== 'active' || document?.organisationid === undefined || document?.organisationid.length === 0) {

          setAppErrors(`User does not exist`);
          return setAppStatus('accessdenied');

        }

        setUser(document);

      }

      // Handle errors
      function onError(error){

        // User not in Azure AD - this is an issue, so immediately error out
        setAppStatus('accessdenied');
        setAppErrors(error);

      }

      const unsubscribe = DocumentListener('users', getUser.emailaddress, onLoadChange, onLoadChange, onError);

      // Remove document listener if the component 'unMounts'
      return () =>{

        unsubscribe();

      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAppStatus]);

  //------------------------------------------------------
  //  Define Firebase OAuthProvider > 'microsoft.com'
  //------------------------------------------------------

    useEffect(() => {

      //We want to use the 'OAuthProvider' > 'microsoft.com'
      const MSProvider = new OAuthProvider('microsoft.com');

      // https://learn.microsoft.com/en-us/entra/identity-platform/v2-oauth2-auth-code-flow 
      MSProvider.setCustomParameters({
        // Force selection of account
        prompt: 'select_account'
      });

      //Add scopes
      MSProvider.addScope('email');
      MSProvider.addScope('openid');
      MSProvider.addScope('profile');
      MSProvider.addScope('User.Read');

      //Save to useContext
      setMicrosoftFireBaseProvider(MSProvider);

    }, [setMicrosoftFireBaseProvider]);


  //------------------------------------------------------
  //  Define Firebase GoogleAuthProvider 
  //------------------------------------------------------

    useEffect(() => {

      //We want to use the 'GoogleAuthProvider'
      const GoogleProvider = new GoogleAuthProvider();

      // https://learn.microsoft.com/en-us/entra/identity-platform/v2-oauth2-auth-code-flow
      GoogleProvider.setCustomParameters({
        prompt: 'select_account'
      });

      //Add scopes
      GoogleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
      GoogleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');
      
      //Save to useContext
      setGoogleFirebaseProvider(GoogleProvider);

    }, [setGoogleFirebaseProvider]);

  //------------------------------------------------------
  //  Pass down all Parent components to childern
  //------------------------------------------------------

    return children;

  //------------------------------------------------------
}
