//================================================================
//  Component: PreQualifications Table
//================================================================

//  Purpose: This is the table for showing all the prequalification applications for a worker

//  Properties:
//    - currentUser = {useState, stores the current user's document (from the users collection)}
//    - formData = {useReducer, formData}

//  Example:
//    <PreQualificationsTable
//      currentUser={currentUser}
//      formData={formData}
//    ></PreQualificationsTable>    

//================================================================


//Libraries
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetAppSize } from '../../../Library/GlobalContexts.js';

//Components

//Functions
import ConvertDate from '../../../Library/ConvertDate.js';

//Images

//CSS
import '../PreQualifications.css';


export default function PreQualificationsTable({
  currentUser,
  formData,
}) {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

    const navigate = useNavigate();
    const getAppSize = useContext(GetAppSize);
  
  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------


  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // Only show if the device is a laptop
  if (getAppSize.width < 1100) return null;

  return (

    <table className='Table-Container'>

      <colgroup>
        <col span='1' style={{width: '50px'}}></col>
        <col span='1' style={{width: '200px'}}></col>
        <col span='1' style={{width: '200px'}}></col>
        <col span='1' style={{width: '220px'}}></col>
        <col span='1' style={{width: '250px'}}></col>
        <col span='1' style={{width: '120px'}}></col>
      </colgroup>
      <thead>
        <tr>
          <th>ID</th>
          <th>Project</th>
          <th>Status</th>
          <th>Modified</th>
          <th>Actioned By</th>
          <th>Expires On</th>
        </tr>
      </thead>
      <tbody>
        {
          formData.filtered.length === 0 ? (

            <tr>
              <td colSpan='4'>No Pre-Qualifications found.</td>
            </tr>

          )
          : 
          (
            formData.filtered.map((prequalification, index) => (

              <tr key={index}>
              
                {/* ======================= */}
                {/*          ID             */}
                {/* ======================= */}

                <td>
                  {index + 1}
                </td>

                {/* ======================= */}
                {/*        Project          */}
                {/* ======================= */}

                <td style={{color: '#4492AB', fontWeight: '500', cursor: 'pointer', textDecoration: 'underline'}} onClick={() => navigate(`/prequalifications/project?emailaddress=${currentUser.emailaddress}&project=${prequalification.projectid}`)}>
                  {prequalification.projectname}
                </td>

                {/* ======================= */}
                {/*        Status           */}
                {/* ======================= */}

                <td>
            
                  {/* Map each type of status*/}
                  {
                    [
                      {'name': 'In Review', 'background': '#84C1D4', 'status': 'review'}, 
                      {'name': 'Requires More Info', 'background': '#E47525', 'status': 'moreinfo'}, 
                      {'name': 'Pending Approval', 'background': '#E4BA25', 'status': 'pendingapproval'}, 
                      {'name': 'Approved', 'background': '#61B280', 'status': 'approved'}, 
                      {'name': 'Rejected', 'background': '#D85C5C', 'status': 'rejected'}, 
                      {'name': 'Expired', 'background': '#C0C0C0', 'status': 'expired'}, 
                      {'name': 'Not Submitted', 'background': '#D484A6', 'status': 'not-submitted'}, 
                    ].filter((style) => style.status === prequalification.status).map((object, index) => (

                      <label
                        key={index} 
                        style={{backgroundColor: object.background}} 
                        className='PreQualifications-Status'
                      >
                        {object.name}
                      </label>
                    
                    ))
                  }

                </td>

                {/* ============================== */}
                {/*     Modified & Actioned By     */}
                {/* ============================== */}

                {

                  prequalification?.audithistory?.length === 0 ? (

                    <>
                      {/* ======================= */}
                      {/*      Modified           */}
                      {/* ======================= */}

                      <td>
                        -
                      </td>

                      {/* ======================= */}
                      {/*      Actioned By        */}
                      {/* ======================= */}

                      <td>
                        -
                      </td>

                    </>

                  ) : (

                    <>
                      {/* ======================= */}
                      {/*      Modified           */}
                      {/* ======================= */}

                      <td>
                        {ConvertDate(prequalification?.audithistory?.at(-1)?.actiondate)}
                      </td>

                      {/* ======================= */}
                      {/*      Actioned By        */}
                      {/* ======================= */}

                      <td>
                        {prequalification?.audithistory?.at(-1)?.actionedby}
                      </td>

                    </>

                  )

                }

                {/* ======================= */}
                {/*      Expires On         */}
                {/* ======================= */}

                <td>
                  {

                    prequalification?.expirydate?.length === 0 ? (

                      <>
                        -
                      </>

                    ) : (

                      <>
                        {prequalification?.expirydate}
                      </>

                    )

                  }
                </td>

              </tr>
            ))
          )
        }

      </tbody>

    </table>

  )
}
