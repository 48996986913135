//================================================================
//  Component: Workers.js
//================================================================

//  Purpose: Management of workers across all orgs & projects

//  Properties:

//  Example:
//    <Workers></Workers>    

//================================================================

//Libraries
import React, { useEffect, useReducer, useContext } from 'react';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Functions
import GetDocument from '../../../Library/GetDocument';

//Components
import InviteWorkerModal from './InviteWorkerModal';
import EditWorkerProjectsModal from './EditWorkerProjectsModal';
import RemoveWorkerModal from './RemoveWorkerModal';

//Imagess
import EditIcon from '../../../Components/Images/Icon_Edit_Grey.svg';
import DeleteIconDisabled from '../../../Components/Images/Icon_Delete_Grey.svg';
import DeleteIcon from '../../../Components/Images/Icon_Delete_Red.svg';
import LoadingIcon from '../../../Components/Images/Image_Loading_Ripple.svg';
import InviteUser from '../../../Components/Images/Icon_Add_Blue.svg';
import InfoIcon from '../../../Components/Images/Icon_Tooltip_Grey.svg';
import DocumentListener from '../../../Library/DocumentListener';

//CSS


export default function Workers() {
    
    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const getUser = useContext(GetUser);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to save the form inputs (email address, given name and surname)
    const [formData, setFormData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            // Used to determine the state of the modal > 'pending', 'onload', 'error-fatal'
            'pageStatus': 'pending',

            // Holds all users and their org & assigned projects
            'allUsers': [],

            // List of all the projects
            'allProjects': [],

            // Invite Modal
            'inviteModalVisible': false,

            //Edit Modal
            'editModalUser': undefined, // Undefined object will hide the Edit Modal

            // Delete Modal
            'deleteModalUser': undefined, // Undefined object will hide the Delete Modal
        }
    );

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------


    // onLoad
    // 1.  Get all the workers for this organisation, their user docs and their projects
    useEffect(() => {

        if (formData.pageStatus !== 'pending') return;
        if (getUser.organisationid === undefined) return;
        
        function onLoadChange(organisation){

            // -------------------------------------------------

            // Get all the worker's user documents
            const userOrgPromises = []
   
            // Skip if organisation has no workers
            if (organisation.workers?.length === 0) return setFormData({ 'pageStatus': 'onload' });

            organisation.workers.forEach((worker) => {

                userOrgPromises.push(
                    GetDocument('users', worker, true)
                );

            });

            // -------------------------------------------------

            // Get all projects associated with this organisation
            const projectPromises = []
   
            // Only get document if projects exists
            if (organisation.projects?.length > 0) {

                organisation.projects.forEach((projectid) => {
                    projectPromises.push(
                        GetDocument('projects', projectid, true)
                    );
    
                })
            };

            //------------------------------------------------------
            //  Settle NESTED promised for Orgs & Projects
            //------------------------------------------------------

            Promise.all(userOrgPromises).then((users) => {

                return Promise.all(projectPromises).then((projects) => {

                    //------------------------------------------------------
                    //  Extract User & Project Information
                    //------------------------------------------------------

                    const results = [];
    
                    users.forEach((user) => {
    
                        // Skip users without an organisationid
                        if (user?.organisationid.length === 0) return;

                        // Skip inactive users
                        if (user?.status !== 'active') return;
    
                        const projectResults = [];
                        user?.projects?.forEach((projectid) => {

                            const project = projects.filter((object) => object.projectid === projectid)[0];

                            // Skip inactive projects
                            if (project?.status !== 'active') return;
    
                            projectResults.push({
                                'projectid': project.projectid,
                                'projectname': project.projectname,
                            });
    
                        });

                        // Is the user a worker? 
                        results.push({
                            'givenname': user.givenname,
                            'surname': user.surname,
                            'emailaddress': user.emailaddress,
                            'organisationid': user.organisationid,
                            'organisationname': user.organisationname,
                            'projects': projectResults,
                            'role': user.orgAdmin.length > 0 ? 'Administrator' : 'Worker'
                        });

                    });
    
                    // Save results to use reducer
                    setFormData({
                        'pageStatus': 'onload',
                        'allUsers': results,
                        'allProjects': projects,
                    });
    
                });

            }).catch((error) => {

                console.log(error);
                setFormData({ 'pageStatus' : 'error-fatal' });
            
            });    

        }

        function onError(error){

            console.log(error);
            setFormData({ 'pageStatus' : 'error-fatal' });

        }

        const unsubscribe = DocumentListener('organisations', getUser.organisationid, onLoadChange, onLoadChange, onError);

        return () =>{
          unsubscribe();
        };
    
    // eslint-disable-next-line
    }, [formData]);


    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

        //====================================================
        //  Pending
        //====================================================
        
        if (formData.pageStatus === 'pending') {
            return (
                <div className='Page-Body-Container'>
                    <div className='flex justify-center'>
                        <img alt='loading-circle-icon' src={LoadingIcon}></img>
                    </div>
                </div>
            )
        }

        //====================================================
        //  Error-Fatal
        //====================================================

        else if (formData.pageStatus === 'error-fatal') {

            return (
                <div className='Page-Body-Container'>
                    <div className='flex flex-col text-center gap-2 my-2'>
                        <h4>Oops something went wrong!</h4>
                        
                        <p className='leading-7 mb-2'>
                            An unknown error has occurred while loading this page.
                            <br></br>
                            If the error persists, please speak reach out to your support team. 
                        </p>
        
                        <div>
                            <button className='Primary-Button' onClick={() => window.location.reload() }>Try Again</button>
                        </div>
                    </div>
                </div>
            )
        }

        //====================================================
        //  Onload
        //====================================================

        else if (formData.pageStatus === 'onload') {
            return (
                <div className='Page-Body-Container'>
        
                    {/* ======================================== */}
                    {/*          Table Header                    */}
                    {/* ======================================== */}

                    <div className='flex flex-row justify-between items-center my-2 [@media(max-width:500px)]:px-2'>
                        <label className='font-medium text-[1.125rem] p-0 m-0 break-all text-wrap [@media(max-width:500px)]:text-base'> {getUser?.organisationname} </label>
                        <button className='Primary-Button [@media(max-width:500px)]:hidden' onClick={() => setFormData({'inviteModalVisible': true})} >+ Invite Worker</button>
                        <button className='hidden m-0 [@media(max-width:500px)]:visible [@media(max-width:500px)]:block' onClick={() => setFormData({'inviteModalVisible': true})}>
                            <img src={InviteUser} alt='Invite User'></img>
                        </button>
                    </div>

                    <hr></hr>

                    <label className='w-full flex flex-row gap-2 bg-[#E8F0F2] rounded-sm p-3 mb-4 text-[15px]'>
                        <img className='mr-1 [@media(max-width:500px)]:hidden' src={InfoIcon} alt='info'></img>
                        <b className='font-medium'>Note:</b> If you would like to remove an administrator of this organisation, please reach out to your project administrator.
                    </label>
        
                    {/* ======================================== */}
                    {/*           Manage Workers Table           */}    
                    {/* ======================================== */}
        
                    {
                        formData.allUsers.length > 0 ?
                        (
                            <>
                                {/* Desktop Version */}
                                <table className='Table-Container shadow-sm mb-4 [@media(max-width:500px)]:hidden'>
                                    <thead>
                                        <tr className='text-[14px] tracking-wider bg-slate-200'>
                                            <th>WORKER</th>
                                            <th>PROJECTS</th>
                                            <th>ROLE</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            formData.allUsers?.map((worker, index) => (
                    
                                                <tr key={index} className='h-[100px] cursor-auto border-b border-[#E0E0E0] break-all'>
                    
                                                    {/* WORKER */}
                                                    <td>
                                                        <div className='flex flex-row font-medium capitalize'>
                                                            {worker?.givenname} {worker?.surname}
                                                        </div>
                                                        {worker?.emailaddress}
                                                    </td>
                    
                                                    {/* PROJECTS */}
                                                    <td>
                                                        {
                                                            worker.projects?.length === 0 ? 
                                                            (
                                                                <ul className='pl-0'>
                                                                    <li>-</li>
                                                                </ul>
                                                            ) 
                                                            : worker.projects.length > 2 ?
                                                            (       
                                                                <ul className='my-0 pl-0'>
                                                                    <li className='list-disc'>{worker.projects[0].projectname}</li>
                                                                    <li className='list-disc'>{worker.projects[1].projectname}</li>
                                                                    <li className='list-none font-[600] text-[#0972D3]'> ...</li>
                                                                </ul>
                                                            )
                                                            :
                                                            (
                                                                <ul className='pl-0'> 
                                                                {
                                                                    worker?.projects?.map((project, index) => (
                                                                        <li className='list-disc' key={index}>
                                                                            {project.projectname}
                                                                        </li>
                                                                    ))
                                                                }
                                                                </ul>                                                        
                                                            )
                    
                                                        }
                                                    </td>
                                                    
                                                    {/* ROLE */}
                                                    <td> {worker.role} </td>
                    
                                                    {/* BUTTON */}
                                                    <td>
                                                        <div className='flex flex-row gap-[5px] justify-center align-middle'>
                                                            <img className='w-[30px] cursor-pointer' onClick={() => setFormData({'editModalUser': worker})} src={EditIcon} alt='Edit-Icon'></img> 
                                                            {

                                                                // Prevent self deletion & removal of other admins
                                                                (worker.emailaddress === getUser.emailaddress || worker.role === 'Administrator') ? (
                                                                    <img 
                                                                        className='w-[30px] cursor-not-allowed' 
                                                                        src={DeleteIconDisabled} 
                                                                        alt='Delete-Icon'
                                                                    ></img>
                                                                ): (
                                                                    <img 
                                                                        className='w-[30px] cursor-pointer' onClick={() => {setFormData({'deleteModalUser': worker})}} 
                                                                        src={DeleteIcon} 
                                                                        alt='Delete-Icon'
                                                                    ></img>
                                                                )
                                                            }

                                                        </div>
                                                    </td>
                    
                                                </tr>
                    
                                            ))
                                        }
                                    </tbody>
                                </table>
                                
                                {/* Mobile Version */}
                                <div className='hidden [@media(max-width:500px)]:flex [@media(max-width:500px)]:flex-col'>
                                    {
                                       formData.allUsers?.map((worker, index) => (
                                        <div key={index} className='flex flex-col justify-between gap-1 bg-white px-4 py-3 shadow-sm rounded-md border-t-[10px] border-t-[#212431] mx-2 mb-3 h-35'>


                                            {/* Summary */}
                                            <div className='flex flex-col gap-2'>
                                                <div className='flex flex-row justify-between items-center'>
                                                    <p className='font-medium m-0 self-end capitalize'>{worker?.givenname} {worker?.surname}</p>

                                                    {/* Buttons */}
                                                    <div className='flex flex-row gap-[5px] justify-end align-middle'>
                                                        <img className='w-[30px] cursor-pointer' onClick={() => setFormData({'editModalUser': worker})} src={EditIcon} alt='Edit-Icon'></img> 
                                                        {

                                                            // Prevent self deletion & removal of other admins
                                                            (worker.emailaddress === getUser.emailaddress || worker.role === 'Administrator') ? (
                                                                <img 
                                                                    className='w-[30px] cursor-not-allowed' 
                                                                    src={DeleteIconDisabled} 
                                                                    alt='Delete-Icon'
                                                                ></img>
                                                            ): (
                                                                <img 
                                                                    className='w-[30px] cursor-pointer' onClick={() => {setFormData({'deleteModalUser': worker})}} 
                                                                    src={DeleteIcon} 
                                                                    alt='Delete-Icon'
                                                                ></img>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <label className='text-[15px] mb-1'>{worker?.emailaddress}</label>
                                                {
                                                    worker.role === 'Administrator' ? 
                                                    <label className='rounded-full w-fit bg-[#E2EBF5] px-3 py-1 m-0 text-[14px] font-medium'>Administrator</label>
                                                    :
                                                    <label className='rounded-full w-fit bg-[#CDE1E7] px-3 py-1 m-0 text-[14px] font-medium'>Worker</label>
                                                }
                                            </div>
                                        </div>
                                       )) 
                                    }
                                </div>
                            </>
                        )
                        :
                        (
                            <div className='p-3'>
                                No workers found.
                            </div>
                        )
                    }
        
                    {/* ======================================== */}
                    {/*           Invite Worker Modal            */}
                    {/* ======================================== */}
        
                    <InviteWorkerModal
                        modalState={formData}
                        setModalState={setFormData}
                    ></InviteWorkerModal>
        
                    {/* ======================================== */}
                    {/*           Edit Worker Modal              */}
                    {/* ======================================== */}
        
                    <EditWorkerProjectsModal
                        modalState={formData}
                        setModalState={setFormData}            
                    ></EditWorkerProjectsModal>
        
                    {/* ======================================== */}
                    {/*          Delete Worker Modal             */}
                    {/* ======================================== */}
            
                    <RemoveWorkerModal
                        formData={formData}
                        setFormData={setFormData}
                    ></RemoveWorkerModal>    
        
                </div>
            )
        }


}
