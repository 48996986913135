// Libraries
import { getStorage, ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage';

/**
 * Generic function to upload a file and return a download link
 * 
 * @param {string} path
 * @param {object} file
 * @param {object} metadata - <OPTIONAL> Add your own custom metadata
 * 
 * ```
 * const path = '/templates/email_template.html';
 * const file = 'HTML File Input';
 * const metadata = {
 *  'filename': 'email_template.html'
 * };
 * 
 * UploadFile(path, file, metadata).then((url) =>{
 * 
 *   console.log('url', url);
 * 
 * });
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/storage/web/upload-files
 * - https://firebase.google.com/docs/storage/web/upload-files#add_file_metadata
 */

export default async function UploadFile(path, file, metadata){

  //------------------------------------------------------
  //  Upload File
  //------------------------------------------------------

    try {

      // Create a root reference
      const storage = getStorage();
     
      // Create a reference to 'file' we want to upload
      // 'images/figma.png'
      const storageRef = ref(storage, path);

      // Upload the file
      return uploadBytesResumable(storageRef, file, metadata).then(() =>{

        return getDownloadURL(storageRef).then((results) =>{

          return results;

        })

      }).catch((error) =>{

        throw new Error(`Function UploadFile failed to complete, error ${error}`);

      });

    } catch (error) {

      throw new Error(`Function UploadFile failed to complete, error ${error}`);

    }

  //------------------------------------------------------
}
