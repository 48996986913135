//Library
import React, {useState} from 'react'

//Contexts:

//Auth config, status and errors
export const GetMicrosoftFireBaseProvider = React.createContext();
export const SetMicrosoftFireBaseProvider = React.createContext();
export const GetGoogleFireBaseProvider = React.createContext();
export const SetGoogleFireBaseProvider = React.createContext();
export const GetAppStatus = React.createContext();
export const SetAppStatus = React.createContext();
export const GetAppErrors = React.createContext();
export const SetAppErrors = React.createContext();
export const GetAppSize = React.createContext();
export const SetAppSize = React.createContext();

//Firebase user and profile
export const GetFireBaseUser = React.createContext();
export const SetFireBaseUser = React.createContext();
export const GetUser = React.createContext();
export const SetUser = React.createContext();

//For the modal
export const GetProfileSelector = React.createContext();
export const SetProfileSelector = React.createContext();

//Component and page errors
export const GetError = React.createContext();
export const SetError = React.createContext();

/**
 * Define global states that are shared across components without the need for props
 * 
 * @param {object} children
 * 
 * ```
 * <GlobalContexts>
 *     <App></App>
 * </GlobalContexts>
 * 
 * ```
 * 
 * Documentation
 * - https://www.w3schools.com/react/react_usecontext.asp
 * - https://stackoverflow.com/questions/64256481/referenceerror-cannot-access-usercontext-before-initialization-react
 * - https://mindsers.blog/post/updating-react-context-from-consumer/
 */

export default function GlobalContexts({children}) {

  //------------------------------------------------------
  //  Define a useState to each context
  //------------------------------------------------------

    const [microsoftFireBaseProvider, setMicrosoftFireBaseProvider] = useState();
    const [googleFireBaseProvider, setGoogleFireBaseProvider] = useState();
    
    const [appStatus, setAppStatus] = useState('pending');
    const [appErrors, setAppErrors] = useState();
    const [appSize, setAppSize] = useState({
      'width': 0,
      'height': 0,
    });

    const [profileSelector, setProfileSelector] = useState({
      'selectedWorker': [],
      'workers': [],
      'visible': false
    });

    const [firebaseUser, setFirebaseUser] = useState();
    const [getUser, setUser] = useState();

    const [getErrors, setErrors] = useState();

  //------------------------------------------------------
  //  Pass down all Parent components & contexts to childern
  //------------------------------------------------------

    return (

      <GetMicrosoftFireBaseProvider.Provider value={microsoftFireBaseProvider}>
      <SetMicrosoftFireBaseProvider.Provider value={setMicrosoftFireBaseProvider}>

      <GetGoogleFireBaseProvider.Provider value={googleFireBaseProvider}>
      <SetGoogleFireBaseProvider.Provider value={setGoogleFireBaseProvider}>

      <GetAppStatus.Provider value={appStatus}>
      <SetAppStatus.Provider value={setAppStatus}>
      <GetAppErrors.Provider value={appErrors}>
      <SetAppErrors.Provider value={setAppErrors}>
      <GetAppSize.Provider value={appSize}>
      <SetAppSize.Provider value={setAppSize}>

      <GetFireBaseUser.Provider value={firebaseUser}>
      <SetFireBaseUser.Provider value={setFirebaseUser}>

      <GetUser.Provider value={getUser}>
      <SetUser.Provider value={setUser}>

      <GetError.Provider value={getErrors}>
      <SetError.Provider value={setErrors}>

      {/* Profile Selector */}
      <GetProfileSelector.Provider value={profileSelector}>
      <SetProfileSelector.Provider value={setProfileSelector}>

        {children}

      </SetProfileSelector.Provider>
      </GetProfileSelector.Provider>

      </SetError.Provider>
      </GetError.Provider>

      </SetUser.Provider>
      </GetUser.Provider>

      </SetFireBaseUser.Provider>
      </GetFireBaseUser.Provider>

      </SetAppSize.Provider>
      </GetAppSize.Provider>
      </SetAppErrors.Provider>
      </GetAppErrors.Provider>
      </SetAppStatus.Provider>
      </GetAppStatus.Provider>

      </SetGoogleFireBaseProvider.Provider>
      </GetGoogleFireBaseProvider.Provider>

      </SetMicrosoftFireBaseProvider.Provider>
      </GetMicrosoftFireBaseProvider.Provider>
    )

  //------------------------------------------------------
}
