//================================================================
//  Component: PageHeader
//================================================================

//  Purpose: Standard page header for the portal

//  Properties:
//    - currentUser = <useState> with the users document

//  PageHeader:
//    <PageHeader
//      currentUser={getUser}
//    ></PageHeader>    

//================================================================

//Libraries
import React from 'react';

//CSS
import './PageHeader.css';


export default function PageHeader({
  currentUser
}) {

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div className='PageHeader-Container'>
              
        <h4>
          {currentUser?.givenname} {currentUser?.surname}
        </h4>
        
        <div>
          {currentUser?.organisationname}
        </div>
        
    </div>
  )
}
