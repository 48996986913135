//================================================================
//  Component: PageComponent
//================================================================

//  Purpose:
//    1. Provides a standardised page container that handles styling
//    2. Prevents unauthorized access via a role check
//    3. Advises customers to choose a 'selectedResource'
//    4. Provides standardised 'status' for success pages, errors, etc

//  Properties:
//
//  <REQUIRED>
//    - header = Provide HTML content
//    - body = Provide HTML content
//
//  <OPTIONAL>
//    - requiredRoles = Provide an array of roles. Refer to the predefined 'roles' in the users collection
//    - requiredRolesValues = Provide an array of strings or boolen, the user MUST have this value in ONE of the 'requiredRoles'
//    - status = Provide a useState of the page status, 'pending', 'successContent', 'error-invalid', 'error-fatal', 'error-timeout' & 'error-other'
//    - breadcrumb = Provide an Object with the 'name' and 'route', {'name': 'Home', 'route': '/home'}
//    - successContent = Provide a HTML content for the successContent page
//    - errorOtherContent = Provide a HTML content for the error-other page

//  Example:
//
//    <PageComponent
//      header={ HTML Content }
//      body={ HTML Content }
//      requiredRoles={ ['isAdmin'] }
//      requiredRolesValues={ ['true', 'prj-1234567890'] }
//      status={ 'success' }
//      breadcrumb={ {'name': 'Home', 'route': '/home'} }
//      successContent={ HTML Content }
//      errorOtherContent={ HTML Content }
//    ></PageComponent>    

//================================================================


//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, GetAppErrors, SetAppErrors } from '../../Library/GlobalContexts';

//Functions
import writeDocument from '../../Library/WriteDocument';

//Images
import AccessDenied from '../Images/Icon_AccessDenied_Blue.svg'
import LoadingIcon from '../Images/Image_Loading_Ripple.svg';
import ErrorFatal from '../Images/Icon_ErrorFatal_Red.svg';

//CSS is handled in index.css

export default function PageComponent({
  header,
  body,
  requiredRoles,
  requiredRolesValues,
  status,
  successContent,
  errorOtherContent,
}) {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const getAppErrors = useContext(GetAppErrors);
    const setAppErrors = useContext(SetAppErrors);

    const navigate = useNavigate();

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Handles what the user will see > 'pending', 'selectview', 'selectresource', 'accessdenied', 'invalidenvironment', 'complete' (catch all!)
    const [pageStatus, setPageStatus] = useState('pending');

  
  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Onload --> validate if the page can be loaded
    useEffect(()=>{

      if (getUser?.roles === undefined) return;

      // Extract the current view, resource and roles
      const userRoles = getUser.roles;

      //------------------------------------------------------
      // Validate users roles
      //------------------------------------------------------

      if (requiredRoles !== undefined && requiredRoles?.length > 0) {

        let hasRole = false;

        // Loop each of the provided roles
        requiredRoles?.forEach(role => {

          // Due to 'orgAdmin' struture (array vs map), we have to treat this separately 
          if (role === 'orgAdmin') {

            requiredRolesValues.forEach((requiredRolesValue) => {
              
              if (getUser.orgAdmin.filter((orgid) => orgid === requiredRolesValue).length > 0) {

                hasRole = true;
  
              }

            });

          // Standard 'roles' map --> proceed like standard
          } else {

            requiredRolesValues.forEach((requiredRolesValue) => {
              
              const resource = userRoles[role]

              if (resource === requiredRolesValue) {

                hasRole = true;
  
              }
            
            });

          }

        });

        if (hasRole === false) {

          return setPageStatus('accessdenied');

        }

      }

      //------------------------------------------------------
      // All validations are completed
      //------------------------------------------------------

      return setPageStatus('complete');


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser]);

    // Error hander --> Write any clientside errors to firestore
    //Checks for getAppErrors mesages and writes them to the 'failures' collection in Firestore
    useEffect(() => {

      if (getAppErrors === undefined) return;
      if (getUser === undefined) return;
      if (status !== 'error-fatal') return;

      const documentId = `${Date.now()}`;
      const document ={
        id: `${documentId}`,
        emailaddress: `${getUser.emailaddress}`,
        message: `${getAppErrors}`
      };

      //Send and forget > the unknown error could be prevent the user from accessing Firestore!
      writeDocument('failures', documentId, document, false);

      //Clear any old message
      setAppErrors(undefined);

    // eslint-disable-next-line 
    }, [getAppErrors]);


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //============================
    // Pending
    //============================

    if (pageStatus === 'pending'){
      return null;
    }

    //============================
    // Access denied > user doesn't have the correct role
    //============================

    else if (pageStatus === 'accessdenied'){

      return (
        <>
          <div className='PageComponent-AccessDenied'>
            <img alt='Error Fatal' src={AccessDenied}></img>
            <div style={{fontSize: 'var(--fontsize-subheader)'}}>
              Sorry it seems like you do not have the necessary permissions to access this page.
              <br></br>
              If you feel this is a mistake, speak with your administrator.
            </div>
            <button className='Primary-Button my-4' onClick={() => navigate('/')}> Return Home </button>
          </div>

        </>
      )
    }

    //============================
    // All validation checks were passed, load the page!
    //============================

    else {

      return (
        <>
          {

            //============================
            // Pending page
            //============================

            status === 'pending' ? (

              <>
                {header}
                <div className='PageComponent-Errors-Container'>
                  <img alt='loading-circle-icon' src={LoadingIcon}></img>
                </div>
              </>

            ):
            

            //============================
            // Pending page
            //============================

            status === 'accessdenied' ? (

              <>
                <div className='PageComponent-AccessDenied'>
                  <img alt='Error Fatal' src={AccessDenied}></img>
                  <h4>Access Denied</h4>
                  <div style={{fontSize: 'var(--fontsize-subheader)'}}>
                    Sorry it seems like you do not have the necessary permissions to access this page.
                    <br></br>
                    If you feel this is a mistake, speak with your administrator.
                  </div>
                  <button className='Primary-Button my-4' onClick={() => navigate('/')}> Return Home </button>
                </div>
              </>

            ):

            //============================
            // Success page
            //============================

            status === 'success' ? (
              <>
                {header}
                {successContent}
              
              </>

            ):


            //============================
            // Error Other page
            //============================

            status === 'error-other' ? (

              <>
                {errorOtherContent}
              </>
              
            ):


            //============================
            // Error-Invalid page
            //============================

            status === 'error-invalid' ? (

              <div className='PageComponent-Errors-Container'>
                <img className='mb-4 w-[125px]' alt='Error Fatal' src={ErrorFatal}></img>
                <h4 className='mb-3'>Oops something went wrong</h4>
                <p>
                  An error occurred while we processed your request.
                  <br></br>
                  If the error persists, please speak with your administrator. 
                </p>
                <p > 
                  <b>Message:</b> {getAppErrors}
                </p>
                <Link to='/home'>
                  <button className='Primary-Button'>Return Home</button>
                </Link>
              </div>

            ):


            //============================
            // Error-Fatal or Error-Timeout page
            //============================

            status === 'error-fatal' || status === 'error-timeout' ? (

              <div className='PageComponent-Errors-Container'>
                <img className='mb-4 w-[125px]' alt='Error Fatal' src={ErrorFatal}></img>
                <h4 className='mb-3'>Oops something went wrong</h4>
                <p>
                  An error occurred while we processed your request.
                  <br></br>
                  If the error persists, please speak with your administrator. 
                </p>
                <p> 
                  <b>Message:</b> Failed to load the page - Fatal Error.
                </p>
                <Link to='/home'>
                  <button className='Primary-Button'>Return Home</button>
                </Link>
              </div>

            ):


            //============================
            // Catch all > show the default body
            //============================

            status === 'onload' ? (

              <>
                {header}
                {body}
              </>

            ) : (

              <>
                {header}
                {body}
              </>

            )
          }
        </>
      )
    }
  
}
