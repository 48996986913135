//================================================================
//  Component: Invite Worker Modal
//================================================================

//  Purpose: Allows organisation admins to invite new workers to their organisation

//  Properties:
//    - modalState = {useState - used to toggle visibility of modal}
//    - setModalState = {A setState function - used to toggle the modal visibility}

//  Example:
//  <InviteWorkerModal
//    modalState={modalState}
//    setModalState={setModalState}
//  ></InviteWorkerModal>

//================================================================

//Librariesis to top
import React, { useContext, useState, useReducer, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../../Library/FirebaseConfig';
import { doc, updateDoc, arrayUnion, getFirestore } from 'firebase/firestore';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components

//Functions
import GetDocument from '../../../Library/GetDocument';
import WriteDocument from '../../../Library/WriteDocument';
import WriteAuditLog from '../../../Library/WriteAuditLog';

//Images
import InviteUser from '../../../Components/Images/Icon_InviteUser_Blue.svg';

//CSS
import '../Settings.css';

export default function InviteWorkerModal({
  modalState,
  setModalState
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

    // Used to determine the state of the modal >  'onload', 'error-invalid', 'error-fatal'
    const [modalStatus, setModalStatus] = useState('onload');
    
    // Used to determine if the 'Send Invite' button is active
    const [inviteButtonDisabled, setInviteButtonDisabled] = useState(true);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to save the form inputs (email address, given name and surname)
    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'emailaddress': '',
        'givenname': '',
        'surname': '',
        'subbyorg': '',
        'invalidErrorMessage': ''
      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Used to submit the form and invite added users
    function sendInvites() {

      setInviteButtonDisabled(true);
    
      //------------------------------------------------------
      //  Added a check to ensure Lendlease users do NOT lose their LLUser role
      //------------------------------------------------------

      let lendleaseUser = false;
      if (formData.emailaddress.endsWith('@lendlease.com')) lendleaseUser = true;

      //------------------------------------------------------
      //  Check worker does NOT exist in this organisation
      //------------------------------------------------------

      const matchingWorkers = modalState.allUsers.filter((user) =>  user.emailaddress === formData.emailaddress.toLowerCase());
      if (matchingWorkers.length > 0) {

        setFormData({ 'invalidErrorMessage': 'This worker already exists in this organisation.' });
        return setModalStatus('error-invalid');
    
      }

      //------------------------------------------------------
      //  Check if worker has a user document
      //------------------------------------------------------

      GetDocument('users', formData.emailaddress.toLowerCase(), true).then((document) => {
        
        // New Worker > Create user doc
        const docPromises = [];

        // Only create new user doc for new workers not existing org admins
        if (document === undefined) {

          const newWorkerDoc = {
            'emailaddress': formData.emailaddress.toLowerCase(),
            'givenname': formData.givenname,
            'surname': formData.surname,
            'created': new Date(),
            'organisationid': getUser.organisationid,
            'orgAdmin': [],
            'projectAdmin': [],
            'roles': {
              'globalAdmin': false,
              'lendleaseUser': lendleaseUser,
            },
            'projects': [],
            'status': 'active',
            'organisationname': getUser.organisationname,
            'subbyorg': formData.subbyorg,
          };

          docPromises.push(
            WriteDocument('users', formData.emailaddress.toLowerCase(), newWorkerDoc, false),
          );
        }

        //------------------------------------------------------
        //  Append org document with worker emailaddress
        //------------------------------------------------------

        // Firestore Client ~ Allows the update of elements in an array
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);

        const docRef = doc(db, 'organisations', getUser.organisationid);

        const updateOrgId = {
          'organisationid': getUser.organisationid,
          'organisationname': getUser.organisationname,
        };

        docPromises.push(
          updateDoc(docRef, {
            workers: arrayUnion(formData.emailaddress.toLowerCase()),
          }),
          WriteDocument('users', formData.emailaddress.toLowerCase(), updateOrgId, true),
        );

        //------------------------------------------------------
        //  Settle Promises
        //------------------------------------------------------

        return Promise.all(docPromises).then(() => {

          // Auditing
          const message = `${getUser.emailaddress} -- Invited worker ${formData.emailaddress} to organisation ${getUser.organisationname}.`;
          const types = [
            'organisation settings'
          ];
          const references = [
            getUser.organisationname,
            getUser.emailaddress,
            formData.emailaddress,
            'organisations',
          ];

          return WriteAuditLog(getUser.emailaddress, message, types, references).then(() => {
            
            setFormData({
              'emailaddress': '',
              'givenname': '',
              'surname': '',
              'subbyorg': ''
            })
            setModalState({
              'pageStatus': 'pending',
              'inviteModalVisible': false
            });

          });
      
        })

      }).catch((error) => {

        console.log('Worker invite failed', error);
        setModalStatus('error-fatal');

      });
  
    }


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Check if the form fields are populated and enable 'Send Invite' button
    useEffect(() => {
      
      if (
        formData.givenname.length > 0 &&
        formData.surname.length > 0 &&
        formData.emailaddress.length > 0 
      ) {
        setInviteButtonDisabled(false);

      } else {
        setInviteButtonDisabled(true);

      }

    }, [formData]);

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    //====================================================
    //  Modal Hidden
    //====================================================
    
    if (modalState?.inviteModalVisible === false) return null;
  
    //====================================================
    //  Error-Invalid
    //====================================================

    else if (modalStatus === 'error-invalid') {
      return (
        <div className='Modal-Background'>
          <dialog className='Invite-Worker-Container'>
            <div className='flex flex-col text-center gap-2'>
                <h4>Sorry you cannot perform this action.</h4>
                <p>
                  {formData?.invalidErrorMessage}
                </p>

                <div>
                  <button className='Primary-Button' onClick={() => {
                    setFormData({
                      'emailaddress': '',
                      'givenname': '',
                      'surname': '',
                      'subbyorg': ''
                    })
                    setModalStatus('pending')
                    }}>
                      Go Back
                  </button>
                </div>
            </div>
          </dialog>
        </div>
      )
    }

    //====================================================
    //  Error-Fatal
    //====================================================

    else if (modalStatus === 'error-fatal') {

      return (
        <div className='Modal-Background'>
          <dialog className='Invite-Worker-Container'>
            <div className='flex flex-col text-center gap-4'>
                <h4>Oops something went wrong!</h4>
                
                <p className='m-0'>
                  An unknown error has occurred while attempting to invite <b className='font-medium'>{formData.emailaddress}</b>.
                </p>
                
                <p className='m-0'>
                  <b>Error:</b> This worker may exist in another organisation.
                </p>

                <p className='m-0'>
                  If the error persists, please speak with your administrator. 
                </p>

                <div>
                  <button className='Primary-Button' onClick={() => 
                    {
                      setFormData({
                        'emailaddress': '',
                        'givenname': '',
                        'surname': '',
                        'subbyorg': '',
                      });
                      setModalStatus('pending');
                    }}
                  >
                      Go Back
                  </button>
                </div>
            </div>
          </dialog>
        </div>
      )
    }


    //====================================================
    //  Onload
    //====================================================

    else {
      return (
      <div className='Modal-Background'>
        <dialog className='Invite-Worker-Container'>

          <div className='Action-Modal-Container'>
            
          {/* <------------------CANCEL BUTTONS-----------------> */}
            <div className='Action-Modal-Icon'>
                <img src={InviteUser} alt='Invite User Icon'></img>
            </div>

            {/* Modal Body */}
            <div className='Action-Modal-Body [@media(max-width:500px)]:mr-0'>
            
              <h4>Invite Worker</h4>
  
              <p> Invite a worker to this organisation by entering their details and clicking the <b style={{fontWeight: '500'}}>Send Invite</b> button below. </p>
              
              {/* ================================================ */}
              {/*              Invite User Form                    */}
              {/* ================================================ */}            
              
              <div className='flex flex-col gap-3'>

                <div className='grid grid-cols-2 gap-3 [@media(max-width:500px)]:flex [@media(max-width:500px)]:flex-col'>
                  {/* <------------------FIRST NAME-----------------> */}

                  <div className='flex flex-col'>
                    <label> First Name </label>
                    <input 
                      required
                      name='givenname'
                      type='text' 
                      className='Action-Modal-Input-Field'
                      onChange={(e) => setFormData({'givenname': e.target.value})}
                      value={formData.givenname}
                      autoComplete='off'
                    ></input>
                  </div>
                  
                  {/* <-------------------LAST NAME-----------------> */}

                  <div className='flex flex-col'>
                    <label> Last Name </label>
                    <input 
                      required
                      name='surname'
                      type='text' 
                      className='Action-Modal-Input-Field'
                      onChange={(e) => setFormData({'surname': e.target.value})}
                      value={formData.surname}
                      autoComplete='off'
                    ></input>
                  </div>

                </div>
                  
                {/* <-------------------EMAIL ADDRESS-----------------> */}
                
                <div className='flex flex-col'>
                  <label> Email Address </label>
                  <input 
                    required
                    name='emailaddress'
                    type='email' 
                    className='Action-Modal-Input-Field'
                    onChange={(e) => setFormData({'emailaddress': e.target.value})}
                    value={formData.emailaddress}
                    autoComplete='off'
                  ></input>
                </div>


                {/* <-------------------ORGANISATION-----------------> */}

                <div className='flex flex-col'>
                  <label> Sub-Subcontractor Organisation (Optional) </label>
                  <input 
                    name='subbyorg'
                    type='text' 
                    className='Action-Modal-Input-Field'
                    onChange={(e) => setFormData({'subbyorg': e.target.value})}
                    value={formData.subbyorg}
                    autoComplete='off'
                  ></input>
                </div>
                

                {/* <------------------SUBMIT BUTTONS-----------------> */}
                <div className='flex flex-row gap-2 mt-3'>
                  <button className='Primary-Button' disabled={inviteButtonDisabled} onClick={sendInvites}> Send Invite </button>
                  <button className='Secondary-Button' onClick={() => {
                        setFormData({
                          'emailaddress': '',
                          'givenname': '',
                          'surname': '',
                          'subbyorg': '',
                        })
                        setModalState({ 'inviteModalVisible': false })}
                  }> 
                    Cancel 
                  </button>
                </div>

              </div>

            </div>

          </div>
        </dialog>
      </div>
      )
    }

  }
