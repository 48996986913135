//================================================================
//  Application: Events App 
//================================================================

//  Product Owner: Scott Wrigley
//  Created 11/04/2022

//================================================================

//Libraries
import React, { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

//Contexts
import { SetAppSize } from './Library/GlobalContexts.js';

//Pages
import Profile from './Pages/Profile/Profile.js';
import PreQualifications from './Pages/PreQualifications/PreQualifications.js';
import Project from './Pages/Project/Project.js';
import NotFound from './Pages/NotFound/NotFound';
import Settings from './Pages/Settings/Settings.js';

//Components
import NavHeader from './Components/NavHeader/NavHeader';
import Footer from './Components/Footer/Footer.js';

//Styles
import './App.css'
// eslint-disable-next-line
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const setAppSize = useContext(SetAppSize);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------
  
    function handleResize(e){

      setAppSize({
        'width': e.currentTarget.innerWidth,
        'height': e.currentTarget.innerHeight
      });

    };


  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // onLoad
    // 1. Setup an event listener to get width and height of app
    useEffect(() => {

      setAppSize({
        'width': window.innerWidth,
        'height': window.innerHeight
      });

      window.addEventListener('load', handleResize)
      window.addEventListener('resize', handleResize)
    
      return () => {
        window.removeEventListener('load', handleResize)
        window.removeEventListener('resize', handleResize)
      }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
  
  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    return (
      <div className='App-Container'>

        {/* Nav */}
        <div className='App-Container-Nav'>
          <NavHeader></NavHeader>
        </div>

        {/* Body > Contains all pages in the app  */}
        <div className='App-Container-Body'>

          {/* Body */}
          <div className='App-Body'>
            <Routes>          

              <Route path='/' element={<Profile/>}/>
              <Route path='/profile' element={<Profile/>}/>
              <Route path='/prequalifications' element={<PreQualifications/>}/>
              <Route path='/prequalifications/project' element={<Project/>}/>
              <Route path='/settings' element={<Settings/>}/>

              <Route path='*' element={<NotFound />}></Route>
            </Routes>
          </div>

          {/* Footer */}
          <Footer></Footer>

        </div>

      </div>
    )

    //------------------------------------------------------
}