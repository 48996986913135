//================================================================
//  Page: PreQualifications
//================================================================

//  Purpose: This is the page for showing all the prequalification applications for a worker

//  Example:
//    <PreQualifications></PreQualifications>    

//================================================================


//Libraries
import React, { useContext, useReducer, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

//Contexts
import { GetAppSize, GetUser } from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import PreQualificationsTable from './Components/PreQualificationsTable';
import PreQualificationsTableMobile from './Components/PreQualificationsTableMobile';

//Functions
import getDocument from '../../Library/GetDocument';
import queryListener from '../../Library/QueryListener';

//Images
import iconFilter from '../../Components/Images/Icon_Filter_Blue.svg'

//CSS
import './PreQualifications.css';


export default function PreQualifications() {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const [ searchParms ] = useSearchParams();
    const emailAddress = searchParms.get('emailaddress');

    const navigate = useNavigate();
    const getUser = useContext(GetUser);
    const getAppSize = useContext(GetAppSize);


  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Saves the current user on the page
    const [currentUser, setCurrentUser] = useState();

    // Stores the current user's document
    const [filterOpen, setFilterOpen] = useState(false);


  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store the prequalifications data
    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'filterby': '',
        'prequalifications': [],
        'filtered': [],
      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Handles the 'Filter By Status' PreQualifications-DropDown
    function filterBy(value){

      if (value === '') {

        // Reset Filter
        setFormData({'filtered': formData.prequalifications});
      
      } else {

        const results = formData.prequalifications.filter((object) => object.status === value);

        // Set Filtered Data
        setFormData({'filtered': results});

      }

    }


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Triggered Onload
    // 1. Check if an email address was passed
    // 2. Get User Document
    useEffect(() => {

      // Conditions
      if (emailAddress === null && emailAddress === undefined) return setPageStatus('accessdenied');

      getDocument('users', emailAddress)
      .then((document) => {

        setCurrentUser(document);

      }).catch(() => {

        setPageStatus('accessdenied');

      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailAddress]);

    // Query Listener
    // 1. Wait for 'currentUser' to be defined
    // 2. Get Pre-Qualifications for the current user
    useEffect(() => {

      // Conditions
      if (currentUser === undefined) return;

      function onLoadChange(documents) {

        // Save the data and load the page
        setFormData({
          'prequalifications': documents,
          'filtered': documents,
        });

        setPageStatus('onload');

      }

      function onError() {

        setPageStatus('accessdenied');

      }

      // Check if the user is an orgAdmin
      const unsubscribe = queryListener('prequalifications', [
        ['workeremail', '==', emailAddress],
        ['organisationid', '==', getUser.organisationid],
        ['status', '!=', 'decommissioned'],
      ], onLoadChange, onLoadChange, onError);

      return () => {
        unsubscribe();
      }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      status={pageStatus}
      body={
      <div className='Page-Container'>
        
        {/* ====================================== */}
        {/*              Page Header               */}
        {/* ====================================== */}

        <PageHeader
          currentUser={currentUser}
        ></PageHeader>

        {/* ====================================== */}
        {/*              Page Body                 */}
        {/* ====================================== */}

        <div className='TabView-Container'>

          {/* Nav Bar */}
          <div className='TabView-Nav'>
            <div className={'TabView-Nav-Default'} onClick={() => navigate(`/profile?emailaddress=${currentUser.emailaddress}`)}>Profile</div>
            <div className={'TabView-Nav-Selected'}>Pre-Qualifications</div>
          </div>

          {/* PreQualifications */}
          <div className='TabView-Body'>


            {/* ========================================== */}
            {/*               Table Header                 */}
            {/* ========================================== */}

            <div className='PreQualifications-Header mt-0'>

              <h6 className='m-0 font-medium text-[#202020]'>Manage Pre-Qualifications</h6>

              {/* ========================= */}
              {/*       Filter By           */}
              {/* ========================= */}

              {

                // Large Devices
                getAppSize.width > 480 ? (
                  <select 
                    style={{maxWidth: '200px'}}
                    className='Input-Field-Select text-black'
                    onChange={(e) => {
          
                      setFormData({'filterby': e.target.value});
                      filterBy(e.target.value);
                    
                    }}
                    value={formData.filterby}
                  >
                    <option value='Filter By' hidden>Filter By</option>
                    <option value=''>All</option>
                    <option value='review'>In Review</option>
                    <option value='moreinfo'>Requires More Info</option>
                    <option value='pendingapproval'>Pending Approval</option>
                    <option value='approved'>Approved</option>
                    <option value='rejected'>Rejected</option>
                    <option value='expired'>Expired</option>
                    <option value='notsubmitted'>Not Submitted</option>
                  </select>
                ): 
                
                // Small Devices
                getAppSize.width < 480 && getAppSize.width > 320 ? (
                  <>
                    <img style={{cursor: 'pointer'}} src={iconFilter} alt='Icon-Filter' onClick={() => setFilterOpen(!filterOpen)}></img>
                    <div className='PreQualifications-Mobile-Filter' hidden={!filterOpen}>
                      
                      {/* Map each type of filter*/}
                      {
                        [
                          {'filter': 'All', 'value': ''}, 
                          {'filter': 'In Review', 'value': 'review'}, 
                          {'filter': 'Requires More Info', 'value': 'moreinfo'}, 
                          {'filter': 'Pending Approval', 'value': 'pendingapproval'}, 
                          {'filter': 'Approved', 'value': 'approved'}, 
                          {'filter': 'Rejected', 'value': 'rejected'}, 
                          {'filter': 'Expired', 'value': 'expired'}, 
                          {'filter': 'Not Submitted', 'value': 'expired'}
                        ].map((object, index) => (

                          <label
                            key={index}
                            style={

                              // Toggle the style if this filter was selected
                              formData.filterby === object.value ? (
                                {
                                  color: 'white',
                                  backgroundColor: 'var(--teal)',
                                  borderRadius: '5px',
                                }
                              ): (
                                null
                              )

                            }
                            onClick={() => {
          
                              setFormData({'filterby': object.value});
                              filterBy(object.value);
                              setFilterOpen(false);
                              
                            }}>
                              {object.filter}
                          </label>
                        ))
                      }
                      
                    </div>
                  </>

                ) : 
                
                // Device too small --> Hide the filter
                (
                  null
                )

              }

            </div>

            {/* ========================================== */}
            {/*                 Table                      */}
            {/* ========================================== */}

            {/* Prequalifications Table */}
            <PreQualificationsTable
              currentUser={currentUser}
              formData={formData}
            ></PreQualificationsTable>

            {/* Mobile Responsive Prequalifications Table */}
            <PreQualificationsTableMobile
              currentUser={currentUser}
              formData={formData}
            ></PreQualificationsTableMobile>

          </div>
        </div>

      </div>
    }
    ></PageComponent>
  )
}
