//================================================================
//  Page: Profile 
//================================================================

//  Purpose: Profile page for this application

//  Supporting documentation
//    - N/A

//================================================================

//Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

//Contexts
import { GetUser, SetAppErrors} from '../../Library/GlobalContexts';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import ProfileForm from './Components/ProfileForm';

//Functions
import getDocument from '../../Library/GetDocument';
import queryListener from '../../Library/QueryListener';

//Images

//CSS
import './Profile.css';


export default function Profile() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
  
    const [ searchParms ] = useSearchParams();
    const emailAddress = searchParms.get('emailaddress');
    const navigate = useNavigate();

    const getUser = useContext(GetUser);
    const setAppErrors = useContext(SetAppErrors);


  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Saves the current user on the page
    const [currentUser, setCurrentUser] = useState();
    const [profileUploads, setProfileUploads] = useState([]);

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------
    
    // onLoad
    // 1. Check if an email address was passed
    // 2. Get the users document
    useEffect(() => {

      //Conditions
      if (getUser?.emailaddress === undefined) return;
      if (emailAddress === undefined) return;
      
      //Choose the correct email address (context vs user defined route)
      let currentEmail = getUser?.emailaddress;
      if (emailAddress !== null) {

        currentEmail = emailAddress;
  
      }

      // Get the users document
      getDocument('users', currentEmail).then((document) => {

        // Load the page
        setCurrentUser(document);
        setPageStatus('onload');

      }).catch((error) => {

        setAppErrors(error);
        setPageStatus('error-fatal');

      });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUser, emailAddress]);
    
    
    // onLoad
    // 1. Check if an email address was passed
    // 2. Get the users document
    useEffect(() => {

      //Conditions
      if (getUser?.emailaddress === undefined) return;
      if (emailAddress === undefined) return;
      
      //Choose the correct email address (context vs user defined route)
      let currentEmail = getUser?.emailaddress;
      if (emailAddress !== null) {

        currentEmail = emailAddress;
  
      }

      function onLoadChange(files){

        const results = [];

        // Get the users profile uploads
        files.forEach(file => {
          
          // Save the valid files
          results.push({
            'id': file.fileid,
            'signedurl': file.signedurl,
            'filename': file.filename,
            'signedurlexpirydate': file.signedurlexpirydate,
            'status': 'existing',
          });

        });

        setProfileUploads([...results]);

      };
    
      function onError(error){

        setAppErrors(error);
        setPageStatus('error-fatal');

      };

      const unsubscribe = queryListener('files', [
        ['requesttype', '==', 'profile-uploads'],
        ['workeremail', '==', currentEmail],
        ['status', '==', 'transferred'],
      ], onLoadChange, onLoadChange, onError);

      return () =>{
        unsubscribe();
      };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);
  

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        status={pageStatus}
        body={
          <div className='Page-Container'>
            
            {/* ====================================== */}
            {/*              Page Header               */}
            {/* ====================================== */}

            <PageHeader
              currentUser={currentUser}
            ></PageHeader>
            
            {/* ====================================== */}
            {/*              Page Body                 */}
            {/* ====================================== */}

            <div className='TabView-Container'>

              {/* Nav Bar */}
              <div className='TabView-Nav'>
                <div className={'TabView-Nav-Selected'}>Profile</div>
                <div className={'TabView-Nav-Default'} onClick={() => navigate(`/prequalifications?emailaddress=${currentUser.emailaddress}`)}>Pre-Qualifications</div>
              </div>

              {/* Profile Form */}
              <div className='TabView-Body'>

                <ProfileForm
                  currentUser={currentUser}
                  profileUploads={profileUploads}
                  setPageStatus={setPageStatus}
                ></ProfileForm>
                
              </div>

            </div>

          </div>
        }
      ></PageComponent>
    )
}
