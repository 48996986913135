//================================================================
//  Component: ProfileModal of component
//================================================================
//
//  Purpose: Select a user modal
//
//  Properties:
//    - showModal = {useState with a boolen}
//    - setShowModal = {useState}
//
//  ProfileModal:
//    <ProfileModal
//      showModal={showModal}
//      setShowModal={setShowModal}
//    ></ProfileModal>    
//
//================================================================


//Libraries
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetProfileSelector, SetProfileSelector } from '../../../Library/GlobalContexts';

//Components

//Functions

//Images

//CSS
import '../Components/ProfileModal.css'


export default function ProfileModal({
  showModal,
  setShowModal
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getProfileSelector = useContext(GetProfileSelector);
  const setProfileSelector = useContext(SetProfileSelector);
  const navigate = useNavigate();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Used to handle when a profile is selected (switches profile)
  const handleProfileSelection = (profile) => {

    // Update the global context for selected worker to the selected profile
    getProfileSelector.selectedWorker = profile;
    setProfileSelector({ ...getProfileSelector });

    setShowModal(false);

    navigate(`/profile?emailaddress=${profile.emailaddress}`);

  };

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  //====================================================
  //  Modal Hidden
  //====================================================

  if (showModal === false) return null;

  //====================================================
  //  Show Profile Selector Modal
  //====================================================

  else {
    return (
      <div className='Modal-Background'>

        <dialog className='ProfileModal-Container'>

          {/* Header */}
          <div className='ProfileModal-Header'>
            Switch Profile
          </div>

          {/* User List */}
          <div className='ProfileModal-User-List'>
            {
              getProfileSelector?.workers.map((profile) => (

                <div key={profile.emailaddress} className='ProfileModal-User-Background' onClick={() => handleProfileSelection(profile)}>

                  {/* User */}
                  <div className='flex flex-col gap-2'>
                    <h2 className='text-[17px] font-medium capitalize m-0'>{profile.givenname} {profile.surname}</h2>
                    <p className='text-[#424242] text-[14px] m-0'>{profile.emailaddress}</p>
                  </div>

                </div>

              ))
            }
          </div>


          {/* <------------------CANCEL BUTTONS-----------------> */}

          <div className='text-right'>
            <button className='bg-white border-none font-medium mt-[10px] mx-[20px] hover:scale-[1.03]' onClick={() => setShowModal(false)}>Cancel</button>
          </div>

        </dialog>

      </div>
    );
  }
}
