//================================================================
//  Page: Project 
//================================================================

//  Purpose: This page shows the prequalification application form for a project (that a user has been invited to)

//  Supporting documentation
//    - N/A

//================================================================

//Libraries
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

//Contexts

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import PageHeader from '../../Components/PageHeader/PageHeader';
import PrequalificationForm from './Components/PrequalificationForm';

//Functions
import GetDocument from '../../Library/GetDocument';
import QueryDocument from '../../Library/QueryDocument';
import DownloadFile from '../../Library/DownloadFile';

//Images

//CSS
import './Project.css';


export default function Project() {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------
  
    const [ searchParms ] = useSearchParams();
    const emailAddress = searchParms.get('emailaddress');
    const projectId = searchParms.get('project');
    const navigate = useNavigate();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Saves the current user on the page
    const [currentUser, setCurrentUser] = useState();

    // Holds the current prequalification record
    const [prequalification, setPreQualification] = useState();

    // Holds the project document
    const [projectDoc, setProjectDoc] = useState();

    // Holds signed URL
    const [signedUrls, setSignedUrls] = useState({
      'nda': [],
      'existingDCAC': [],
      'agsva': [],
      'auscit': [],
      'npc': [],
      'comments': [],
      'conditionsofuse': []
    });


  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

    // onLoad
    // 1. Check if an email address & projectid was passed in
    // 2. Get the users document
    // 3. Get the users prequalifications 
    useEffect(() => {

      if (emailAddress === null && emailAddress === undefined) return setPageStatus('accessdenied');
      if (projectId === null && projectId === undefined) return setPageStatus('accessdenied');

      // Get the User Document
      GetDocument('users', emailAddress).then((userDoc) => {

        setCurrentUser(userDoc);

        // ----------------------------------------------
        //   Get the Prequalification Doc
        // ----------------------------------------------

        const prequalificationPromise = QueryDocument('prequalifications', [
          ['projectid', '==', projectId],
          ['workeremail', '==', userDoc.emailaddress],
        ])

        // ----------------------------------------------
        //   Get the Project Doc
        // ----------------------------------------------

        const projectPromise = GetDocument('projects', projectId)

        // ----------------------------------------------
        //   Get the NDA Signed URL
        // ----------------------------------------------

        const ndaPromise = QueryDocument('files', [
          ['projectid', '==', projectId],
          ['requesttype', '==', 'nondisclosure'],
          ['status', '==', 'transferred']
        ]);

        // ----------------------------------------------
        //   Get Existing DCAC Uploads
        // ----------------------------------------------

        const existingDCACPromise = QueryDocument('files', [
          ['workeremail', '==', userDoc.emailaddress],
          ['requesttype', '==', 'existing-dcac'],
          ['projectid', '==', projectId],
          ['organisationid', '==', userDoc.organisationid],
          ['status', '==', 'transferred']
        ]);

        // ----------------------------------------------
        //   Get AGSVA Uploads
        // ----------------------------------------------

        const agsvaPromise = QueryDocument('files', [
          ['workeremail', '==', userDoc.emailaddress],
          ['requesttype', '==', 'security-clearance'],
          ['projectid', '==', projectId],
          ['organisationid', '==', userDoc.organisationid],
          ['status', '==', 'transferred']
        ]);

        // ----------------------------------------------
        //   Get Aust Citizen Uploads
        // ----------------------------------------------

        const ausCitPromise = QueryDocument('files', [
          ['workeremail', '==', userDoc.emailaddress],
          ['requesttype', '==', 'aus-citizen-uploads'],
          ['projectid', '==', projectId],
          ['organisationid', '==', userDoc.organisationid],
          ['status', '==', 'transferred']
        ]);

        // ----------------------------------------------
        //   Get Non-Disclosable Outcome Uploads
        // ----------------------------------------------

        const npcPromise = QueryDocument('files', [
          ['workeremail', '==', userDoc.emailaddress],
          ['requesttype', '==', 'nondisclosable-outcomes'],
          ['organisationid', '==', userDoc.organisationid],
          ['status', '==', 'transferred']
        ]);

        // ----------------------------------------------
        //   Get Comments Uploads
        // ----------------------------------------------

        const commentsPromise = QueryDocument('files', [
          ['workeremail', '==', userDoc.emailaddress],
          ['requesttype', '==', 'additional-information'],
          ['projectid', '==', projectId],
          ['organisationid', '==', userDoc.organisationid],
          ['status', '==', 'transferred']
        ]);

        // ----------------------------------------------
        //   Get Comments Uploads
        // ----------------------------------------------

        const conditionsOfUsePromise = DownloadFile('conditionsofuse/Conditions_of_Use_DCAC.pdf');


        // ----------------------------------------------
        //   Resolve Promises
        // ----------------------------------------------
        
        // Get the Prequalification Document
        return Promise.all([prequalificationPromise, projectPromise, ndaPromise, existingDCACPromise, agsvaPromise, ausCitPromise, npcPromise, commentsPromise, conditionsOfUsePromise])
        .then((results) => {

          const prequalificationDocs = results[0];
          const projectsDoc = results[1];
          const ndaDoc = results[2];
          const existingDCACDoc = results[3];
          const agsvaDoc = results[4];
          const ausCitDoc = results[5];
          const npcDoc = results[6];
          const commentsDoc = results[7];
          const conditionsOfUseDoc = results[8];

          // Save all the signed URLs
          signedUrls.nda.push(ndaDoc[0]);
          signedUrls.existingDCAC = existingDCACDoc;
          signedUrls.agsva = agsvaDoc;
          signedUrls.auscit = ausCitDoc;
          signedUrls.npc = npcDoc;
          signedUrls.comments = commentsDoc;
          signedUrls.conditionsofuse = conditionsOfUseDoc;

          setSignedUrls(signedUrls);

          // Save prequalification doc
          setPreQualification(prequalificationDocs[0]);
          
          // Save the project doc
          setProjectDoc(projectsDoc);

          // Load the page
          setPageStatus('onload');

        });

      }).catch((error) => {

        console.log('error', error)
        setPageStatus('error-fatal');

      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailAddress]);
    

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        status={pageStatus}
        body={
          <div className='Page-Container'>
            
            {/* ====================================== */}
            {/*              Page Header               */}
            {/* ====================================== */}

            <PageHeader
              currentUser={currentUser}
            ></PageHeader>

            {/* ====================================== */}
            {/*              Page Body               */}
            {/* ====================================== */}

            <div className='TabView-Container'>

              {/* Nav Bar */}
              <div className='TabView-Nav'>
                <div className={'TabView-Nav-Default'} onClick={() => navigate(`/profile?emailaddress=${currentUser.emailaddress}`)}>Profile</div>
                <div className={'TabView-Nav-Selected'} onClick={() => navigate(`/prequalifications?emailaddress=${currentUser.emailaddress}`)}>Pre-Qualifications</div>
              </div>

              {/* Project Form */}
              <div className='TabView-Body'>

                <PrequalificationForm
                  currentUser={currentUser}
                  prequalification={prequalification}
                  setPageStatus={setPageStatus}
                  projectDoc={projectDoc}
                  signedUrls={signedUrls}
                ></PrequalificationForm>
                
              </div>

            </div>

          </div>
        }
      ></PageComponent>
    )
}
