//==========================================================================
//   Function: Update an Array in a Firestore Document
//   Description: Updates an array inside of a Firestore Document using 'ArrayUnion' and 'ArrayRemove'
//   Documentation:
//   - https://firebase.google.com/docs/firestore/manage-data/add-data#update_elements_in_an_array 
//==========================================================================

//   Example - How to use this function: 

//   UpdateArrayInDocument('live', app?.applicationid, column?.value, inputValue, `${column?.id}.value`)
//   .then(() =>{
//       console.log('Updated Array')
//       setFieldView('read');
//   
//   })
//   .catch((error) =>{
//       console.log('error', error)
//       setErrorVisible(true);
//   
//   })

//==========================================================================

//Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { firebaseConfig } from './FirebaseConfig';
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore"; 


export default async function UpdateArrayInDocument(collectionId, documentId, originalArray, newArray, docKey){

  //------------------------------------------------------
  //  Update one document with an object 
  //------------------------------------------------------

    //Update existing document
    try {

        //Firestore Database reference
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);

        // Document Reference
        const docRef = doc(db, collectionId, documentId);

        // Compare the original and new array
        const removedItems = originalArray.filter(function(val) {
            return newArray.indexOf(val) === -1;
          });
        
        const addedItems = newArray.filter(function(val) {
            return originalArray.indexOf(val) === -1;
        });

        // Define Array of Promises
        const docPromises = [];

        // Loop through added items > ArrayUnion
        addedItems.forEach(element => {
            docPromises.push(
                updateDoc(docRef, {
                    [docKey] : arrayUnion(element)
                })
            )
        });

        // Loop through removed items > ArrayRemove
        removedItems.forEach(element => {
            docPromises.push(
                updateDoc(docRef, {
                    [docKey] : arrayRemove(element)
                })
            )
        });

        //Write to document
        return Promise.all(docPromises)

    } catch (error) {
  
        throw new Error(`Function UpdateArrayInDocument failed to complete, error ${error}`);

    }

  //------------------------------------------------------
}