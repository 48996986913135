//================================================================
//  Component: Prequalification Form
//================================================================

//  Purpose: This is the prequalification form that a user fills in to apply for a DCAC pass for their defence project

//  Properties:
//    - currentUser = {useState, current users document}
//    - prequalification = {useState, current prequalification request for a project}
//    - setPageStatus = {useState, setPageStatus}
//    - projectDoc = {document, stores the current defence project document}
//    - signedUrls = {array, contains all the signed urls for all files on the form}

//  Example:
//    <PrequalificationForm
//      currentUser={currentUser}
//      prequalification={prequalification}
//      setPageStatus={setPageStatus}
//      projectDoc={projectDoc}
//      signedUrls={signedUrls}
//    ></PrequalificationForm>    

//================================================================


//Libraries
import React, { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts

//Components
import FileUploadDCACForm from './FileUploadDCACForm';
import CommentsThread from './CommentsThread/CommentsThread';

//Functions
import WriteDocument from '../../../Library/WriteDocument';
import ConvertDate from '../../../Library/ConvertDate';

//Images
import ExpandIcon from '../../../Components/Images/Expand_Icon.svg';
import CollapseIcon from '../../../Components/Images/Collapse_Icon.svg';
import Step1Icon from '../../../Components/Images/Step_1_Icon.svg';
import Step1CompleteIcon from '../../../Components/Images/Step_1_Complete_Icon.svg';
import Step2Icon from '../../../Components/Images/Step_2_Icon.svg';
import Step2CompleteIcon from '../../../Components/Images/Step_2_Complete_Icon.svg';
import Step3Icon from '../../../Components/Images/Step_3_Icon.svg';
import Step3CompleteIcon from '../../../Components/Images/Step_3_Complete_Icon.svg';
import TickedIcon from '../../../Components/Images/Ticked_Icon.svg';
import SendMail from '../../../Components/Images/Icon_Mail_White.svg';
import AttachFile from '../../../Components/Images/Icon_AttachFile_Teal.svg';
import History from '../../../Components/Images/Icon_History_Teal.svg';
import ViewFileIcon from '../../../Components/Images/View_File_Icon.svg';

//CSS
import '../Project.css';


export default function PrequalificationForm({
  currentUser,
  prequalification,
  setPageStatus,
  projectDoc,
  signedUrls
}) {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

    const navigate = useNavigate();

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store all the form input and their states
    const [formData, setFormData] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {

        // --- Existing DCAC Form Fields --- //
        'existingDCAC': prequalification?.lastexpirynotificationssent?.first === true ?         // Allows the resubmission of a DCAC, IF the expiry notice was sent
          'no' : prequalification?.existingdcac,                                                  // Does the user have an existing DCAC? - ('yes'/'no')
        'existingDCACFiles': signedUrls?.existingDCAC,
        'existingDCACValid': true,
        'existingDCACErrorMessage': '',

        // --- No DCAC Form Fields --- //
        // Section 1 | Privacy Policy
        'privacyPolicyChecked': prequalification?.privacystatementaccepted,                       // Determines whether privacy policy box is checked (true/false)                        
        'privacyOpen': false,                                                                     // Privacy policy pane expanded/collapsed (true/false)
        'privacyPolicyValid': true,

        // Section 2 | Application
        'applicationFormComplete': prequalification.status === 'not-submitted' ? false : true,    // Determines whether application form is complete (true/false)
        'applicationOpen': false,                                                                 // Application pane expanded/collapsed (true/false)

        // Application Form Fields
        'givenName': prequalification?.givenname,
        'givenNameValid': true,
        'lastName': prequalification?.surname,
        'lastNameValid': true,
        'notificationEmail': prequalification?.notificationemail,
        'notificationEmailValid': true,
        'dateOfBirth': prequalification?.dateofbirth,
        'dateOfBirthValid': true,
        'companyName': prequalification?.companyname,
        'companyNameValid': true,
        'companyPosition': prequalification?.companyposition,
        'companyPositionValid': true,
        'reasonForAccess': prequalification?.reasonforaccess,
        'reasonForAccessValid': true,
        'AGSVA': prequalification?.agsva,
        'AGSVAFiles': signedUrls?.agsva,
        'AGSVAValid': true,
        'AGSVAErrorMessage': '',
        'AusCit': prequalification?.auscit,
        'AusCitFiles': signedUrls?.auscit,
        'AusCitValid': true,
        'AusCitErrorMessage': '',
        'NPC': prequalification?.npc,
        'NPCFiles': signedUrls?.npc,
        'NPCValid': true,
        'NPCErrorMessage': '',
        'DecRightToWork': prequalification?.decrighttowork,                                        // Checkbox 1 | Right to work - (true/false)
        'DecRightToWorkValid': true,
        'DecConditionsOfUse': prequalification?.decconditionsofuse,                                // Checkbox 2 | Conditions of use - (true/false)
        'DecConditionsOfUseValid': true,
        'DecNDA': prequalification?.decnda,                                                        // Checkbox 3 | NDA - (true/false)
        'DecNDAValid': true,

        // Benno ~ If we get time, I will move this to Firestore
        'passOfficeData': [
          {
            'state': 'NSW',
            'offices': [
              'Glenbrook',
              'Orchard Hills',
              'Albatross',
              'Wagga',
              'Kapooka',
              'Holsworthy',
              'Defence Plaza Sydney',
              'Kuttabul',
              'Williamtown',
              'Singleton',
              'Richmond',
            ]
          },
          {
            'state': 'VIC',
            'offices': [
              'Simpson',
              'Victoria Barracks Melbourne',
              'Puckapunyall',
              'East Sale',
              'Cerberus',
              'Williams',
              'Bandiana',
            ]
          },
          {
            'state': 'TAS',
            'offices': [
              'Derwent',
            ]
          },
          {
            'state': 'QLD',
            'offices': [
              'Gallipoli',
              'Oakey',
              'Amberley',
              'Kokoda',
              'Townsville',
              'Lavarack',
              'Cairns',

            ]
          },
          {
            'state': 'WA',
            'offices': [
              'Irwin',
              'Stirling',
              'Stirling Gangway',
              'Pearce',
              'Taylor',
            ]
          },
          {
            'state': 'NT',
            'offices': [
              'Robertson',
              'Larrakeyah',
              'Darwin National Pass Office',
              'Tindal',
            ]
          },
          {
            'state': 'SA',
            'offices': [
              'Edinburgh',
            ]
          },
          {
            'state': 'ACT',
            'offices': [
              'Russel',
              'Campbell Park',
              'RMC',
            ]
          },
        ],
        'passOfficeState': projectDoc?.nominatedpassstate,
        'passOfficeStateValid': true,
        'passOfficeNameFilter': [
          prequalification?.nominatedpassoffice
        ],
        'passOfficeName': projectDoc?.nominatedpassoffice,
        'passOfficeNameValid': true,
        'passOfficeNew': prequalification?.nominatedpassofficenew,
        'passOfficeNewValid': true,

        // Section 3 | Additional Information 
        'addInfoComplete': (prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true) ? false : true,             // Determines whether additional information pane has been opened (true/false)
        'addInfoOpen': false,                                                                      // Additional Information pane expanded/collapsed (true/false)
        
        'addInfoUploadFile': false,                                                                // Changes to 'true' when 'Attach File' button clicked on Additional Information pane
        'addInfoFile': [],                                                                         // Array of files uploaded with a comment in Additional Information pane
        'addInfoValid': true,
        'addInfoErrorMessage': '',
        'addInfoSendValid': false,
        'addInfoCommentTextArea': '',                                                              // Stores the current text in the comments text area
        'addInfoComments': prequalification?.comments,                                             // Stores the array of comments posted on the comments thread

        // Prequalification History Fields
        'prequalHistoryOpen': false,                                                                // Determines whether the prequalification history pane is open

        // Prevent a save
        'preventSave': false                                                                       // Prevent the save button

      }
    );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Function to handle changes to input fields
    function handleInputChange(evt){
        
      const name = evt.target.name;
      const newValue = evt.target.value;

      //Validate field - 'givenName'
      if(name === 'givenName') {
        if(newValue.length <= 0) {
          setFormData({'givenNameValid': false})
        } else {
          setFormData({'givenNameValid': true})
        }
      }

      //Validate field - 'lastName'
      if(name === 'lastName') {
        if(newValue.length <= 0) {
          setFormData({'lastNameValid': false})
        } else {
          setFormData({'lastNameValid': true})
        }
      }

      //Validate field - 'notificationEmail'
      if(name === 'notificationEmail') {
        if(newValue.length <= 0) {
          setFormData({'notificationEmailValid': false})
        } else {
          setFormData({'notificationEmailValid': true})
        }
      }

      //Validate field - 'dateOfBirth'
      if(name === 'dateOfBirth') {
        if(newValue.length <= 0) {
          setFormData({'dateOfBirthValid': false})
        } else {
          setFormData({'dateOfBirthValid': true})
        }
      }

      //Validate field - 'companyName'
      if(name === 'companyName') {
        if(newValue.length <= 0) {
          setFormData({'companyNameValid': false})
        } else {
          setFormData({'companyNameValid': true})
        }
      }

      //Validate field - 'companyPosition'
      if(name === 'companyPosition') {
        if(newValue.length <= 0) {
          setFormData({'companyPositionValid': false})
        } else {
          setFormData({'companyPositionValid': true})
        }
      }

      //Validate field - 'reasonForAccess'
      if(name === 'reasonForAccess') {
        if(newValue.length <= 0) {
          setFormData({'reasonForAccessValid': false})
        } else {
          setFormData({'reasonForAccessValid': true})
        }
      }

      //Validate field - 'AGSVA'
      if(name === 'AGSVA') {
        if(newValue.length <= 0) {
          setFormData({'AGSVAValid': false})
        } else {
          setFormData({'AGSVAValid': true})
        }
      }

      //Validate field - 'AusCit'
      if(name === 'AusCit') {
        if(newValue.length <= 0) {
          setFormData({'AusCitValid': false})
        } else {
          setFormData({'AusCitValid': true})
        }
      }

      //Validate field - 'NPC'
      if(name === 'NPC') {
        if(newValue.length <= 0) {
          setFormData({'NPCValid': false})
        } else {
          setFormData({'NPCValid': true})
        }
      }

      setFormData({ [name]: newValue });

    };

    // Function to submit existing DCAC
    function handleExistingDCACSubmission() {

      // ----------------------------------------------------------
      // 1. Validate required fields
      // ----------------------------------------------------------

      let preventSubmit = false;

      // If existing DCAC > Check for file(s)
      if (
          formData.existingDCAC === 'yes' && 
          formData.existingDCACFiles.filter((object) => object.tempstatus !== 'deleted').length === 0
        ) {
        preventSubmit = true;
        formData.existingDCACValid = false;

      } else {
        formData.existingDCACValid = true;

      }

      // Update formData useReducer
      setFormData(formData);

      // Check if ALL validations have passed
      if (preventSubmit === true) return;

      // ----------------------------------------------------------
      // 2. Write to 'prequalifications' collection
      // ----------------------------------------------------------

      // If Not Submitted > Add single entry
      if(prequalification.status === 'not-submitted') {

        prequalification.audithistory.push(
          {
            'actiondate': new Date(),
            'actionedby': currentUser.emailaddress,
            'message': `Prequalification submitted for ${currentUser.givenname} ${currentUser.surname}`,
            'type': 'Prequalification Submitted'
          }
        );

      }

      // If More Info Required > Add additional entry
      if (prequalification.status === 'moreinfo') {

        prequalification.audithistory.push(
          {
            'actiondate': new Date(),
            'actionedby': currentUser.emailaddress,
            'message': `Prequalification submitted with more information requested`,
            'type': 'More Information Submitted'
          }
        );

      }


      const prequalificationId = `${currentUser.emailaddress}-${projectDoc?.projectid}`;
      const prequalificationDoc = {
        'prequalificationid': prequalificationId,
        'projectid': projectDoc?.projectid,
        'projectname': projectDoc?.projectname,
        'organisationid': currentUser.organisationid,
        'organisationname': currentUser.organisationname,
        'workeremail': currentUser.emailaddress,
        'existingdcac': formData.existingDCAC,
        'givenname': currentUser.givenname,
        'surname': currentUser.surname,
        'privacystatementaccepted': formData.privacyPolicyChecked,
        'notificationemail': formData.notificationEmail,
        'dateofbirth': formData.dateOfBirth,
        'companyname': formData.companyName,
        'companyposition': formData.companyPosition,
        'reasonforaccess': formData.reasonForAccess,
        'agsva': formData.AGSVA,
        'auscit': formData.AusCit,
        'npc': formData.NPC,
        'decrighttowork': formData.DecRightToWork,
        'decconditionsofuse': formData.DecConditionsOfUse,
        'decnda': formData.DecNDA,
        'audithistory': prequalification?.audithistory,
        'lastupdated': new Date(),
        'expirydate': '',
        'lastexpirynotificationssent': {
          'first': false,
          'second': false,
          'expired': false,
        },
        'referenceid': '',
        'comments': formData.addInfoComments,
        'status': 'review',
      };

      const prequalificationPromise = WriteDocument('prequalifications', prequalificationId, prequalificationDoc, true);

      // ----------------------------------------------------------
      // 2. Write to 'files' collection
      // ----------------------------------------------------------

      // ------------------ Existing DCAC ------------------ //

      const existingDCACPromises = [];
      formData.existingDCACFiles?.forEach((document) => {

        if (document.tempstatus !== 'deleted' && document.status === 'transferred') return;

        let existingDCACFileDoc;

        // Flag the file for deletion
        if (document.tempstatus === 'deleted'){

          existingDCACFileDoc = document;
          existingDCACFileDoc.status = 'pending-delete';
  
        // New file --> create new document
        } else {

          existingDCACFileDoc = {
            'fileid': document?.fileid,
            'filename': document?.filename,
            'requesttype': 'existing-dcac',
            'folderpath': `/${currentUser.organisationid}/${currentUser.emailaddress}/${projectDoc.projectid}`,
            'projectid': projectDoc.projectid,
            'organisationid': currentUser.organisationid,
            'createdby': currentUser.emailaddress,
            'status': 'pending transfer', 
            'description': '',
            'dateobtained': new Date(),
            'expirydate': '',
            'signedurl': '',
            'signedurlexpirydate': '',
            'workeremail': currentUser.emailaddress,
          };

        }

        existingDCACPromises.push(
          WriteDocument('files', existingDCACFileDoc.fileid, existingDCACFileDoc, true)
        );

      });

      // ----------------------------------------------------------
      // 3. Settle promises & route the user back to 'prequalifications' page
      // ----------------------------------------------------------

      Promise.all([prequalificationPromise, ...existingDCACPromises]).then(() => {

        navigate(`/prequalifications?emailaddress=${currentUser.emailaddress}`);
        window.location.reload();
        
      })
      .catch((error) => {

        console.log('Failed to submit existing DCAC submission.', error);
        setPageStatus('error-fatal');
        
      })

    }

    // Function to save existing DCAC
    function handleSaveDCACSubmission() {

      if (formData.preventSave === true) return;

      // ----------------------------------------------------------
      // Write to 'prequalifications' collection
      // ----------------------------------------------------------

      const prequalificationId = `${currentUser.emailaddress}-${projectDoc?.projectid}`;
      const prequalificationDoc = {
        'prequalificationid': prequalificationId,
        'projectid': projectDoc?.projectid,
        'projectname': projectDoc?.projectname,
        'organisationid': currentUser.organisationid,
        'organisationname': currentUser.organisationname,
        'workeremail': currentUser.emailaddress,
        'existingdcac': formData.existingDCAC,
        'givenname': currentUser.givenname,
        'surname': currentUser.surname,
        'privacystatementaccepted': formData.privacyPolicyChecked,
        'notificationemail': formData.notificationEmail,
        'dateofbirth': formData.dateOfBirth,
        'companyname': formData.companyName,
        'companyposition': formData.companyPosition,
        'reasonforaccess': formData.reasonForAccess,
        'agsva': formData.AGSVA,
        'auscit': formData.AusCit,
        'npc': formData.NPC,
        'decrighttowork': formData.DecRightToWork,
        'decconditionsofuse': formData.DecConditionsOfUse,
        'decnda': formData.DecNDA,
        'audithistory': prequalification.audithistory,
        'lastupdated': new Date(),
        'expirydate': '',
        'referenceid': '',
        'nominatedpassstate': formData.passOfficeState,
        'nominatedpassoffice': formData.passOfficeName,
        'nominatedpassofficenew': formData.passOfficeNew,
        'comments': formData.addInfoComments,
      }

      const prequalificationPromise = WriteDocument('prequalifications', prequalificationId, prequalificationDoc, true);

      // ----------------------------------------------------------
      // 2. Write to 'files' collection
      // ----------------------------------------------------------

      // ------------------ AGSVA Uploads ------------------ //

      const agsvaFilesPromises = [];
      formData.AGSVAFiles?.forEach((document) => {

        // Skip files already uploaded and NOT deleted
        if (document.tempstatus !== 'deleted' && document.status === 'transferred') return;

        // Flag the file for deletion
        if (document.tempstatus === 'deleted' && document.status === 'new') return;

        let agsvaFileDoc;

        // Flag the file for deletion
        if (document.status === 'transferred' && document.tempstatus === 'deleted'){

          agsvaFileDoc = document;
          agsvaFileDoc.status = 'pending-delete';

        // New file --> create new document
        } else {

          agsvaFileDoc = {
            'fileid': document?.fileid,
            'filename': document?.filename,
            'requesttype': 'security-clearance',
            'folderpath': `/${currentUser.organisationid}/${currentUser.emailaddress}/${projectDoc.projectid}`,
            'projectid': projectDoc.projectid,
            'organisationid': currentUser.organisationid,
            'createdby': currentUser.emailaddress,
            'status': 'pending transfer', 
            'description': '',
            'dateobtained': new Date(),
            'expirydate': '',
            'signedurl': '',
            'signedurlexpirydate': '',
            'workeremail': currentUser.emailaddress
          }

        }

        agsvaFilesPromises.push(
          WriteDocument('files', agsvaFileDoc.fileid, agsvaFileDoc, true)
        )

      });

      // ------------ Australian Citizen Uploads  ----------- //

      const auscitFilesPromises = [];
      formData.AusCitFiles?.forEach((document) => {

        // Skip files already uploaded and NOT deleted
        if (document.tempstatus !== 'deleted' && document.status === 'transferred') return;

        if (document.tempstatus === 'deleted' && document.status === 'new') return;

        let auscitFileDoc;

        // Flag the file for deletion
        if (document.tempstatus === 'deleted' && document.status === 'transferred'){

          auscitFileDoc = document;
          auscitFileDoc.status = 'pending-delete';

        // New file --> create new document
        } else {

          auscitFileDoc = {
            'fileid': document?.fileid,
            'filename': document?.filename,
            'requesttype': 'aus-citizen-uploads',
            'folderpath': `/${currentUser.organisationid}/${currentUser.emailaddress}/${projectDoc.projectid}`,
            'projectid': projectDoc.projectid,
            'organisationid': currentUser.organisationid,
            'createdby': currentUser.emailaddress,
            'status': 'pending transfer', 
            'description': '',
            'dateobtained': new Date(),
            'expirydate': '',
            'signedurl': '',
            'signedurlexpirydate': '',
            'workeremail': currentUser.emailaddress
          }

        }

        auscitFilesPromises.push(
          WriteDocument('files', auscitFileDoc.fileid, auscitFileDoc, true)
        )

      });

      // ------------- Non-Disclosable Outcomes ------------- //

      const npcFilesPromises = [];
      formData.NPCFiles?.forEach((document) => {

        // Skip files already uploaded and NOT deleted
        if (document.tempstatus !== 'deleted' && document.status === 'transferred') return;

        if (document.tempstatus === 'deleted' && document.status === 'new') return;

        let npcFileDoc;

        // Flag the file for deletion
        if (document.status === 'transferred' && document.tempstatus === 'deleted'){

          npcFileDoc = document;
          npcFileDoc.status = 'pending-delete';

        // New file --> create new document
        } else {

          npcFileDoc = {
            'fileid': document?.fileid,
            'filename': document?.filename,
            'requesttype': 'nondisclosable-outcomes',
            'folderpath': `/${currentUser.organisationid}/${currentUser.emailaddress}/${projectDoc.projectid}`,
            'projectid': projectDoc.projectid,
            'organisationid': currentUser.organisationid,
            'createdby': currentUser.emailaddress,
            'status': 'pending transfer', 
            'description': '',
            'dateobtained': new Date(),
            'expirydate': '',
            'signedurl': '',
            'signedurlexpirydate': '',
            'workeremail': currentUser.emailaddress
          }
        
        }

        npcFilesPromises.push(
          WriteDocument('files', npcFileDoc.fileid, npcFileDoc, true)
        )

      });

      // -------------- Additional Information -------------- //

      const addInfoCommentsPromises = [];
      formData.addInfoComments?.addInfoFile?.forEach((document) => {

        // Skip files already uploaded and NOT deleted
        if (document.tempstatus !== 'deleted' && document.status === 'transferred') return;

        if (document.tempstatus === 'deleted' && document.status === 'new') return;

        let addInfoCommentsDoc;

        // Flag the file for deletion
        if (document.status === 'transferred' && document.tempstatus === 'deleted'){

          addInfoCommentsDoc = document;
          addInfoCommentsDoc.status = 'pending-delete';

        // New file --> create new document
        } else {

          addInfoCommentsDoc = {
            'fileid': document?.fileid === undefined ? '' : document?.fileid,
            'filename': document?.filename === undefined ? '' : document?.filename,
            'requesttype': 'additional-information',
            'folderpath': `/${currentUser.organisationid}/${currentUser.emailaddress}/${projectDoc.projectid}`,
            'projectid': projectDoc.projectid,
            'organisationid': currentUser.organisationid,
            'createdby': currentUser.emailaddress,
            'status': 'pending transfer', 
            'description': '',
            'dateobtained': new Date(),
            'expirydate': '',
            'signedurl': '',
            'signedurlexpirydate': '',
            'workeremail': currentUser.emailaddress
          }
        
        }

        addInfoCommentsPromises.push(
          WriteDocument('files', addInfoCommentsDoc.fileid, addInfoCommentsDoc, true)
        )

      });


      // ----------------------------------------------------------
      // 3. Settle promises & route the user back to 'prequalifications' page
      // ----------------------------------------------------------

      Promise.all([prequalificationPromise, ...agsvaFilesPromises, ...auscitFilesPromises, ...npcFilesPromises, ...addInfoCommentsPromises])
      .then(() => {
        navigate(`/prequalifications?emailaddress=${currentUser.emailaddress}`)
        
      })
      .catch((error) => {

        console.log('Failed to submit new DCAC submission.', error);
        setPageStatus('error-fatal');
        
      })


    }
  
    // Function to submit new DCAC
    function handleNewDCACSubmission(){

      // ----------------------------------------------------------
      // 1. Validate required fields
      // ----------------------------------------------------------

      let preventSubmit = false;

      // Privacy Policy
      if (!formData.privacyPolicyChecked) {
        preventSubmit = true;
        formData.privacyPolicyValid = false;

      } else {
        formData.privacyPolicyValid = true;

      }

      // Given Name
      if (formData.givenName.length === 0) {
        preventSubmit = true;
        formData.givenNameValid = false;

      } else {
        formData.givenNameValid = true;

      }

      // Last Name
      if (formData.lastName.length === 0) {
        preventSubmit = true;
        formData.lastNameValid = false;

      } else {
        formData.lastNameValid = true;

      }

      // Email Address
      if (formData.notificationEmail.length === 0) {
        preventSubmit = true;
        formData.notificationEmailValid = false;

      } else {
        formData.notificationEmailValid = true;

      }

      // Date of Birth
      if (formData.dateOfBirth.length === 0) {
        preventSubmit = true;
        formData.dateOfBirthValid = false;

      } else {
        formData.dateOfBirthValid = true;

      }

      // Company Name
      if (formData.companyName.length === 0) {
        preventSubmit = true;
        formData.companyNameValid = false;

      } else {
        formData.companyNameValid = true;

      }

      // Company Position
      if (formData.companyPosition.length === 0) {
        preventSubmit = true;
        formData.companyPositionValid = false;

      } else {
        formData.companyPositionValid = true;

      }

      // Reason for Access
      if (formData.reasonForAccess.length === 0) {
        preventSubmit = true;
        formData.reasonForAccessValid = false;

      } else {
        formData.reasonForAccessValid = true;

      }

      // AGSVA Security Clearance
      if (formData.AGSVA.length === 0 || (formData.AGSVA === 'yes' && formData.AGSVAFiles.filter((object) => object.tempstatus !== 'deleted').length === 0)) 
        {
        preventSubmit = true;
        formData.AGSVAValid = false;

      } else {
        formData.AGSVAValid = true;

      }

      // Australian Citizen
      if (formData.AGSVA.length === 0 || (formData.AGSVA === 'no' && (formData.AusCit.length === 0 || formData.AusCitFiles.filter((object) => object.tempstatus !== 'deleted').length === 0))) 
        {
        preventSubmit = true;
        formData.AusCitValid = false;

      } else {
        formData.AusCitValid = true;

      }

      // Non-disclosable Outcomes
      if (formData.AGSVA === 'no' && (formData.NPC === 'yes' && formData.NPCFiles.filter((object) => object.tempstatus !== 'deleted').length === 0)) 
        {
  
        preventSubmit = true;
        formData.NPCValid = false;

      } else {

        formData.NPCValid = true;

      }

      // Nominated Pass Office * ~ Requires one of two fields to be populated
      //  Option 1 ~ State & Office selected from dropdowns
      //  Option 2 ~ Office manually typed in
      let nominatedPassState = '';
      let nominatedPassOffice = '';
      if (formData.passOfficeName.length > 0) {

        formData.passOfficeNameValid = true;
        formData.passOfficeNewValid = true;
        nominatedPassState = formData.passOfficeState;
        nominatedPassOffice = formData.passOfficeName;

      } else if (formData.passOfficeNew.length > 0) {

        formData.passOfficeNameValid = true;
        formData.passOfficeNewValid = true;
        nominatedPassState = 'N/A';
        nominatedPassOffice = '';

      } else {

        preventSubmit = true;
        formData.passOfficeNameValid = false;
        formData.passOfficeNewValid = false;

      }

      // Declaration
      // * Checkbox 1 - Right to Work
      if (!formData.DecRightToWork) {
        preventSubmit = true;
        formData.DecRightToWorkValid = false;

      } else {
        formData.DecRightToWorkValid = true;
      }

      // * Checkbox 2 - Conditions of Use
      if (!formData.DecConditionsOfUse) {
        preventSubmit = true;
        formData.DecConditionsOfUseValid = false;

      } else {
        formData.DecConditionsOfUseValid = true;
      }

      // * Checkbox 3 - Non-disclosure Agreement
      if (signedUrls.nda[0]?.fileid?.length > 0 && !formData.DecNDA) {
        preventSubmit = true;
        formData.DecNDAValid = false;

      } else {
        formData.DecNDAValid = true;
      }

      // Update formData useReducer
      setFormData(formData);

      // Check if ALL validations have passed
      if (preventSubmit === true) return;

      // ----------------------------------------------------------
      // 2. Write to 'prequalifications' collection
      // ----------------------------------------------------------

      // If Not Submitted > Add single entry
      if (prequalification.status === 'not-submitted') {
        prequalification.audithistory.push(
          {
            'actiondate': new Date(),
            'actionedby': currentUser.emailaddress,
            'message': `Prequalification submitted for ${currentUser.givenname} ${currentUser.surname}`,
            'type': 'Prequalification Submitted'
          }
        )
      }

      //If More Info Required > Add additional entry
      if (prequalification.status === 'moreinfo') {
        prequalification.audithistory.push(
          {
            'actiondate': new Date(),
            'actionedby': currentUser.emailaddress,
            'message': `Prequalification submitted with more information requested`,
            'type': 'More Information Submitted'
          }
        )
      }

      const prequalificationId = `${currentUser.emailaddress}-${projectDoc?.projectid}`;
      const prequalificationDoc = {
        'prequalificationid': prequalificationId,
        'projectid': projectDoc?.projectid,
        'projectname': projectDoc?.projectname,
        'organisationid': currentUser.organisationid,
        'organisationname': currentUser.organisationname,
        'workeremail': currentUser.emailaddress,
        'existingdcac': formData.existingDCAC,
        'givenname': currentUser.givenname,
        'surname': currentUser.surname,
        'privacystatementaccepted': formData.privacyPolicyChecked,
        'notificationemail': formData.notificationEmail,
        'dateofbirth': formData.dateOfBirth,
        'companyname': formData.companyName,
        'companyposition': formData.companyPosition,
        'reasonforaccess': formData.reasonForAccess,
        'agsva': formData.AGSVA,
        'auscit': formData.AusCit,
        'npc': formData.NPC,
        'decrighttowork': formData.DecRightToWork,
        'decconditionsofuse': formData.DecConditionsOfUse,
        'decnda': formData.DecNDA,
        'audithistory': prequalification.audithistory,
        'lastupdated': new Date(),
        'expirydate': '',
        'lastexpirynotificationssent': {
          'first': false,
          'second': false,
          'expired': false,
        },
        'referenceid': '',
        'nominatedpassstate': nominatedPassState,
        'nominatedpassoffice': nominatedPassOffice,
        'nominatedpassofficenew': formData.passOfficeNew,
        'comments': formData.addInfoComments,
        'status': 'review',
      }

      const prequalificationPromise = WriteDocument('prequalifications', prequalificationId, prequalificationDoc, true);

      // ----------------------------------------------------------
      // 2. Write to 'files' collection
      // ----------------------------------------------------------

      // ------------------ AGSVA Uploads ------------------ //

      const agsvaFilesPromises = [];
      formData.AGSVAFiles?.forEach((document) => {

        if (document.tempstatus !== 'deleted' && document.status === 'transferred') return;

        let agsvaFileDoc;

        // Flag the file for deletion
        if (document.tempstatus === 'deleted'){

          agsvaFileDoc = document;
          agsvaFileDoc.status = 'pending-delete';

        // New file --> create new document
        } else {

          agsvaFileDoc = {
            'fileid': document?.fileid,
            'filename': document?.filename,
            'requesttype': 'security-clearance',
            'folderpath': `/${currentUser.organisationid}/${currentUser.emailaddress}/${projectDoc.projectid}`,
            'projectid': projectDoc.projectid,
            'organisationid': currentUser.organisationid,
            'createdby': currentUser.emailaddress,
            'status': 'pending transfer', 
            'description': '',
            'dateobtained': new Date(),
            'expirydate': '',
            'signedurl': '',
            'signedurlexpirydate': '',
            'workeremail': currentUser.emailaddress
          }

        }

        agsvaFilesPromises.push(
          WriteDocument('files', agsvaFileDoc.fileid, agsvaFileDoc, true)
        )

      });

      // ------------ Australian Citizen Uploads  ----------- //

      const auscitFilesPromises = [];
      formData.AusCitFiles?.forEach((document) => {

        if (document.tempstatus !== 'deleted' && document.status === 'transferred') return;

        let auscitFileDoc;

        // Flag the file for deletion
        if (document.tempstatus === 'deleted'){

          auscitFileDoc = document;
          auscitFileDoc.status = 'pending-delete';

        // New file --> create new document
        } else {

          auscitFileDoc = {
            'fileid': document?.fileid,
            'filename': document?.filename,
            'requesttype': 'aus-citizen-uploads',
            'folderpath': `/${currentUser.organisationid}/${currentUser.emailaddress}/${projectDoc.projectid}`,
            'projectid': projectDoc.projectid,
            'organisationid': currentUser.organisationid,
            'createdby': currentUser.emailaddress,
            'status': 'pending transfer', 
            'description': '',
            'dateobtained': new Date(),
            'expirydate': '',
            'signedurl': '',
            'signedurlexpirydate': '',
            'workeremail': currentUser.emailaddress
          }
        
        }

        auscitFilesPromises.push(
          WriteDocument('files', auscitFileDoc.fileid, auscitFileDoc, true)
        )

      });

      // ------------- Non-Disclosable Outcomes ------------- //

      const npcFilesPromises = [];
      formData.NPCFiles?.forEach((document) => {

        if (document.tempstatus !== 'deleted' && document.status === 'transferred') return;

        let npcFileDoc;

        // Flag the file for deletion
        if (document.tempstatus === 'deleted'){

          npcFileDoc = document;
          npcFileDoc.status = 'pending-delete';

        // New file --> create new document
        } else {

          npcFileDoc = {
            'fileid': document?.fileid,
            'filename': document?.filename,
            'requesttype': 'nondisclosable-outcomes',
            'folderpath': `/${currentUser.organisationid}/${currentUser.emailaddress}/${projectDoc.projectid}`,
            'projectid': projectDoc.projectid,
            'organisationid': currentUser.organisationid,
            'createdby': currentUser.emailaddress,
            'status': 'pending transfer', 
            'description': '',
            'dateobtained': new Date(),
            'expirydate': '',
            'signedurl': '',
            'signedurlexpirydate': '',
            'workeremail': currentUser.emailaddress
          }
        
        }

        npcFilesPromises.push(
          WriteDocument('files', npcFileDoc.fileid, npcFileDoc, true)
        )

      });

      // -------------- Additional Information -------------- //

      const addInfoCommentsPromises = [];
      formData.addInfoComments?.addInfoFile?.forEach((document) => {

        if (document.tempstatus !== 'deleted' && document.status === 'transferred') return;

        let addInfoCommentsDoc;

        // Flag the file for deletion
        if (document.tempstatus === 'deleted'){

          addInfoCommentsDoc = document;
          addInfoCommentsDoc.status = 'pending-delete';

        // New file --> create new document
        } else {

          addInfoCommentsDoc = {
            'fileid': document?.fileid === undefined ? '' : document?.fileid,
            'filename': document?.filename === undefined ? '' : document?.filename,
            'requesttype': 'additional-information',
            'folderpath': `/${currentUser.organisationid}/${currentUser.emailaddress}/${projectDoc.projectid}`,
            'projectid': projectDoc.projectid,
            'organisationid': currentUser.organisationid,
            'createdby': currentUser.emailaddress,
            'status': 'pending transfer', 
            'description': '',
            'dateobtained': new Date(),
            'expirydate': '',
            'signedurl': '',
            'signedurlexpirydate': '',
            'workeremail': currentUser.emailaddress
          }
        
        }

        addInfoCommentsPromises.push(
          WriteDocument('files', addInfoCommentsDoc.fileid, addInfoCommentsDoc, true)
        )

      });


      // ----------------------------------------------------------
      // 3. Settle promises & route the user back to 'prequalifications' page
      // ----------------------------------------------------------

      Promise.all([prequalificationPromise, ...agsvaFilesPromises, ...auscitFilesPromises, ...npcFilesPromises, ...addInfoCommentsPromises])
      .then(() => {
        navigate(`/prequalifications?emailaddress=${currentUser.emailaddress}`)
        
      })
      .catch((error) => {

        console.log('Failed to submit new DCAC submission.', error);
        setPageStatus('error-fatal');
        
      })


    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <div>

        {/* -------------------------------------------- */}
        {/*  Prequalification Form Header                */}
        {/* -------------------------------------------- */}

        <div className='PreQ-Form-Header'>

          <div className='flex flex-row gap-2 items-center mb-0'>
            {/* Project Name */}
            <label style={{fontSize: '17px'}}>{prequalification.projectname}</label>

            {/* Status Label */}
            {/* TODO - Change this to StatusLabel component */}
            {
              [
                { 'name': 'In Review', 'background': '#84C1D4', 'status': 'review' },
                { 'name': 'Requires More Info', 'background': '#E47525', 'status': 'moreinfo' },
                { 'name': 'Pending Approval', 'background': '#E4BA25', 'status': 'pendingapproval' },
                { 'name': 'Approved', 'background': '#61B280', 'status': 'approved' },
                { 'name': 'Rejected', 'background': '#D85C5C', 'status': 'rejected' },
                { 'name': 'Expired', 'background': '#C0C0C0', 'status': 'expired' },
                { 'name': 'Not Submitted', 'background': '#D484A6', 'status': 'not-submitted' }
              ]
              .filter((style) => style.status === prequalification.status)
              .map((object, index) => (

                <label className='PreQualifications-Status' key={index} style={{ backgroundColor: object.background }}>
                  {object.name}
                </label>

              ))
            }
          </div>

          <button className='Primary-Button mb-0' onClick={() => navigate(`/prequalifications?emailaddress=${currentUser.emailaddress}`)}>Back</button>

        </div>

        {
          prequalification.status !== 'not-submitted' && 
          (
            <div>
                
              {/* -------------------------------------------- */}
              {/*  Prequalification Status                     */}
              {/* -------------------------------------------- */}

              {
                [
                  { 'name': 'In Review', 'description': `This application was submitted on ${ConvertDate(prequalification?.audithistory.filter((item) => item.type === 'Prequalification Submitted')[0]?.actiondate)} and is currently being reviewed for approval.`, 'background': '#EBF4FF', 'status': 'review', 'color': '#194A59'},
                  { 'name': 'Requires More Information', 'description': 'This application requires more information, refer to Prequalification History below to determine what information is required.', 'background': '#FFF0D2', 'status': 'moreinfo', 'color': '#E47525'},
                  { 'name': 'Pending Security Officer Approval', 'description': 'This application was sent to a security officer for approval.', 'background': '#FFF5D2', 'status': 'pendingapproval', 'color': '#E4BA25'},
                  { 'name': 'Approved', 'description': `This application was approved on ${ConvertDate(prequalification?.audithistory.filter((item) => item.type === 'Approved')[0]?.actiondate)}. The DCAC will expire on ${prequalification?.expirydate}.`, 'background': '#EAF3DA', 'status': 'approved', 'color': '#61B280'},
                  { 'name': 'Rejected', 'description': `This application was rejected on ${ConvertDate(prequalification?.audithistory.filter((item) => item.type === 'Rejected')[0]?.actiondate)}. View Prequalification History below to see the reason for rejection.`, 'background': '#FFE9E9', 'status': 'rejected', 'color': '#D85C5C'},
                  { 'name': 'Expired', 'description': `This application expired on ${prequalification?.expirydate}.`, 'background': '#F0F0F0', 'status': 'expired', 'color': '#C0C0C0'}
                ]
                .filter((style) => style.status === prequalification.status)
                .map((object, index) => (

                  <div className='PreQ-Form-Status-Pane' key={index} style={{ backgroundColor: object.background, borderLeft: `5px solid ${object.color}` }}>
                    <h6>{object.name.toUpperCase()}</h6>
                    <p >{object.description}</p>
                  </div>

                ))
              }

              {/* -------------------------------------------- */}
              {/*  Prequalification History                    */}
              {/* -------------------------------------------- */}

              <div className='PreQ-Form-Section-Container'>
            
                {/* -------------- EXPANDABLE PANE -------------*/}
                <div className='PreQ-History-Pane'>

                  {/* PREQUALIFICATION HISTORY */}
                  <div style={{display: 'flex', flexDirection: 'row', gap: '15px', alignItems: 'center'}}>
                    <img src={History} alt='History' width='30px'></img>
                    <h6 style={{margin: '0px'}}>PREQUALIFICATION HISTORY</h6>
                  </div>

                  {/* V */}
                  <img 
                    className='PreQ-Form-Expandable-Pane-ExpandCollapseIcon'
                    src={formData.prequalHistoryOpen ? CollapseIcon : ExpandIcon} 
                    alt='Expand' 
                    onClick={() => setFormData({ 'prequalHistoryOpen': !formData.prequalHistoryOpen })}
                  ></img>

                </div>

                {/* -------------- PREQUALIFICATION HISTORY TABLE -------------*/}
                {
                  formData.prequalHistoryOpen &&
                  <table className='PreQ-History-Pane-Table'>
                    <colgroup>
                      <col span='1' style={{width: '5%'}}></col>
                      <col span='1' style={{width: '25%'}}></col>
                      <col span='1' style={{width: '25%'}}></col>
                      <col span='1' style={{width: '45%'}}></col>
                    </colgroup>
                    <thead>
                      <tr> 
                        <th></th>
                        <th>DATE</th>
                        <th>ACTION BY</th>
                        <th>DETAILS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        prequalification?.audithistory.map((prequalification) => (
                          <tr>
                            <td></td>
                            <td>{ConvertDate(prequalification?.actiondate)}</td>
                            <td>{prequalification?.actionedby}</td>
                            <td>{prequalification?.message}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                }

              </div>

            </div>
          )
        }

        {/* -------------------------------------------- */}
        {/*  Prequalification Form                       */}
        {/* -------------------------------------------- */}

          <>

            {/* ------------------------------------------ */}
            {/*  Do you have an existing DCAC?             */}
            {/* ------------------------------------------ */}

            {
              prequalification.status === 'not-submitted' &&

              <div className='PreQ-Form-Section-Container' style={{fontWeight: '500'}}>

                <div className='PreQ-Form-ExistingDCAC'>
                  <label> Do you have an existing DCAC? </label>
                  
                  <div>
                    <button 
                      className={formData.existingDCAC === 'yes' ? 'Secondary-Button-Active' : 'Secondary-Button'} 
                      onClick={() => { 
                        setFormData({ 'existingDCAC': 'yes' });
                      }}
                    >
                      Yes
                    </button>

                    <button 
                      className={formData.existingDCAC === 'no' ? 'Secondary-Button-Active' : 'Secondary-Button'}
                      onClick={() => {
                        setFormData({ 'existingDCAC': 'no' });
                      }}
                    >
                      No
                    </button>
                  </div>

                </div>

              </div>
            }
            
            {/* ------------------------------------------ */}
            {/*  DCAC Form                                 */}
            {/* ------------------------------------------ */}

            <div>
              {
                // ------------------------------------------ //
                //   Existing DCAC                            //
                // ------------------------------------------ //

                formData.existingDCAC === 'yes' ? 
                (
                  <div>

                    {/* Upload DCAC */}
                    <div className='PreQ-Form-Section-Container' style={{padding: '40px'}}>
                      
                      <h6>UPLOAD DCAC</h6>
                      <p style={{padding: '5px 0px'}}>Upload a copy of your Defence Common Access Card below. Please ensure you have uploaded both the front and back view of your card.</p>
                    
                      {/* If Status is 'Not Submitted' OR 'In Review' > Allow Uploads */}
                      {
                        prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? 
                        (
                          <FileUploadDCACForm
                            formData={formData}
                            setFormData={setFormData}
                            dataField={'existingDCACFiles'}
                            errorMessageField={'existingDCACErrorMessage'}
                            validField={'existingDCACValid'}
                           ></FileUploadDCACForm>
                        )
                        :
                        (
                          <div className='PreQ-Uploads-List-Container'>
                            <b>FILE NAME</b>
        
                            {/* File Link */}
                            {
                              formData.existingDCACFiles.map((object, index) => (
                                <div className='PreQ-Uploads-List-Row' key={index}>

                                  {/* File Name */}
                                  <a 
                                    href={object.signedurl} 
                                    alt={object.filename} 
                                    target='_blank' 
                                    rel='noreferrer'>
                                      {object.filename}
                                  </a>
  
                                  {/* View File */}
                                  <div className='PreQ-Uploads-List-ViewFile'>

                                    <a
                                      href={object.signedurl} 
                                      alt={object.filename} 
                                      target='_blank' 
                                      rel='noreferrer'>
                                      <img className='Get-Signed-Url-ViewFile' src={ViewFileIcon} alt='View File' title='View File'></img>
                                    </a>  

                                  </div>
                                      
                                </div>
                              ))
                            }
                          </div>
                        )
                      }

                    </div>

                    {/* Submit Form */}
                    <div className='PreQ-Form-Container-Buttons '>
                
                      <button 
                        className='Primary-Button' 
                        disabled={
                          (prequalification.status === 'not-submitted' || prequalification.status === 'moreinfo') &&
                          (formData.existingDCACValid && formData.existingDCACFiles.length) > 0 ? false : true
                        } 
                        onClick={handleExistingDCACSubmission}
                      > 
                        Submit 
                      </button>      

                      <button className='Secondary-Button' onClick={() => navigate(`/prequalifications?emailaddress=${currentUser.emailaddress}`)}> Cancel </button>
      
                    </div>

                  </div>
                ) 
                
                // ------------------------------------------ //
                //   New DCAC                                 //
                // ------------------------------------------ //
                
                : formData.existingDCAC === 'no' ? 
                (
                  <div>

                    <div className='PreQ-Form-Section-Container' style={{padding: '0px'}}>

                    {/* ------------------------------------ */}
                    {/*   PRIVACY STATEMENT                  */}
                    {/* ------------------------------------ */}
                      
                      {/* -------------- EXPANDABLE PANE -------------*/}

                      <div className='PreQ-Form-Expandable-Pane' onClick={() => setFormData({ 'privacyOpen': !formData.privacyOpen })}>

                        {/* Step 1 */}
                        <img 
                          className='PreQ-Form-Expandable-Pane-Step' 
                          src={formData.privacyPolicyChecked ? TickedIcon : formData.privacyOpen ? Step1CompleteIcon : Step1Icon} 
                          alt='Privacy Statement'
                        ></img>

                        {/* PRIVACY STATEMENT */}
                        <label className='PreQ-Form-Expandable-Pane-Heading'>PRIVACY STATEMENT</label>

                        {/* V */}
                        <img 
                          className='PreQ-Form-Expandable-Pane-ExpandCollapseIcon' 
                          src={formData.privacyOpen ? CollapseIcon : ExpandIcon} 
                          alt='Expand' 
                          onClick={() => setFormData({ 'privacyOpen': !formData.privacyOpen })}
                        ></img>

                      </div>

                      {/* ---------- PRIVACY STATEMENT CHECKBOXES ---------- */}
                      {
                        formData.privacyOpen && 
                        (
                          <div className='PreQ-Form-Expandable-Pane-Content'>

                            <p style={{fontWeight: '500'}}>OUR PRIVACY POLICY</p>
                            <p>The information collected will only be used for the purpose of obtaining a DCAC and/or as part of security onboarding. The information will be retained on this platform until the project or DCAC expiry (whichever occurs first).</p>
                            
                            {/* Checkbox */}
                            <div className='PreQ-Form-PrivacyPolicy-Container'>
                              <label className='PreQ-Form-Checkbox-Container'>
                                <input
                                  required
                                  type='checkbox'
                                  name='privacystatement'
                                  checked={formData.privacyPolicyChecked}
                                  onChange={() => setFormData({ 
                                    'privacyPolicyChecked': !formData.privacyPolicyChecked,
                                    'privacyPolicyValid': true
                                  })}
                                  disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                ></input>
                                <span className='PreQ-Form-Checkbox'></span>
                              </label>
                              <label>Yes, I have read and understood the privacy policy and consent to my information being used as detailed above.</label>
                            </div>
               
                          {/* Error Message */}
                          {!formData.privacyPolicyValid && <label className='PreQ-Uploads-Error-Message' style={{paddingBottom: '0px'}}> Please acknowledge you have read the privacy statement by checking the box above.</label>}
   
                          </div>

                        ) 
                      }

                    {/* ------------------------------------ */}
                    {/*   APPLICATION                        */}
                    {/* ------------------------------------ */}

                      {/* -------------- EXPANDABLE PANE -------------*/}

                      <div className='PreQ-Form-Expandable-Pane' onClick={() => setFormData({ 'applicationOpen': !formData.applicationOpen })}>

                        {/* Step 2 */}
                        <img 
                          className='PreQ-Form-Expandable-Pane-Step' 
                          src={
                            prequalification.status === 'review' ||
                            prequalification.status === 'pending' ||
                            prequalification.status === 'pendingapproval' ||
                            prequalification.status === 'approved' ||
                            prequalification.status === 'rejected' ||
                            prequalification.status === 'expired' ||
                            (
                              formData.givenName?.length > 0 &&
                              formData.lastName?.length > 0 &&
                              formData.notificationEmail?.length > 0 &&
                              formData.dateOfBirth?.length > 0 &&
                              formData.companyName?.length > 0 &&
                              formData.companyPosition?.length > 0 &&
                              formData.reasonForAccess?.length> 0 &&
                              (
                                (formData.AGSVA === 'yes' && formData.AGSVAFiles.length > 0) 
                                ||
                                (
                                  formData.AGSVA === 'no' && 
                                  (
                                    ((formData.AusCit === 'yes' && formData.AusCitFiles?.length > 0) || formData.AusCit === 'no') && 
                                    ((formData.NPC === 'yes' && formData.NPCFiles?.length > 0) || formData.NPC === 'no')
                                  )
                                )
                              ) && 
                              formData.DecRightToWork &&
                              formData.DecConditionsOfUse &&
                              (signedUrls.nda[0]?.fileid?.length > 0 && formData.DecNDA)
                            )
                            ? TickedIcon : formData.applicationOpen ? Step2CompleteIcon : Step2Icon} 
                          alt='Application'
                        ></img>
                          
                        {/* APPLICATION */}
                        <label className='PreQ-Form-Expandable-Pane-Heading'>APPLICATION</label>

                        {/* V */}
                        <img 
                          className='PreQ-Form-Expandable-Pane-ExpandCollapseIcon' 
                          src={formData.applicationOpen ? CollapseIcon : ExpandIcon} 
                          alt='Expand' 
                          onClick={() => setFormData({ 'applicationOpen': !formData.applicationOpen })}
                        ></img>
                        
                      </div>

                      {/* ---------- APPLICATION FORM ---------- */}

                      {
                        formData.applicationOpen && (

                          <div className='PreQ-Form-Expandable-Pane-Content'>
                            <div className='PreQ-Form-Application-Container'>

                              {/* ----------------------------------------------- */}
                              {/*   Question 1 - Full Name                        */}
                              {/* ----------------------------------------------- */}
                              
                              <div>
                                <p className='PreQ-Form-Application-Question'>1. Please provide your full name *</p>
                                <div className='PreQ-Form-Application-Dual-Column-Grid'>
                                             
                                  {/* First Name */}
                                  <div className='PreQ-Form-Application-Names'>
                                    <label> FIRST NAME </label>
                                    <input 
                                      required 
                                      className={formData.givenNameValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                      type='text' 
                                      name='givenName' 
                                      value={formData.givenName}
                                      onChange={handleInputChange}
                                      disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                    ></input>
                                  </div>

                                  {/* Last Name */}
                                  <div className='PreQ-Form-Application-Names'>
                                    <label> LAST NAME </label>
                                    <input 
                                      required 
                                      className={formData.lastNameValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                      type='text' 
                                      name='lastName' 
                                      value={formData.lastName}
                                      onChange={handleInputChange}
                                      disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                    ></input>
                                  </div>

                                </div>
                              </div>

                              {/* ----------------------------------------------- */}
                              {/*   Question 2 - Email Address                    */}
                              {/* ----------------------------------------------- */}
                              
                              <div>
                                <p className='PreQ-Form-Application-Question'>2. Please provide the email address where you would like the application notification to be sent *</p>
                                <div className='PreQ-Form-Application-Single-Column-Grid'>
                                  <input 
                                    required 
                                    className={formData.notificationEmailValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                    type='text'
                                    name='notificationEmail' 
                                    value={formData.notificationEmail}
                                    onChange={handleInputChange}
                                    disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                    autoComplete='off'
                                  ></input>
                                </div>
                              </div>

                              {/* ----------------------------------------------- */}
                              {/*   Question 3 - Date of Birth                    */}
                              {/* ----------------------------------------------- */}

                              <div>
                                <p className='PreQ-Form-Application-Question'>3. Date of Birth *</p>
                                <div>
                                  <input 
                                    required
                                    className={formData.dateOfBirthValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                    type='date'
                                    name='dateOfBirth'
                                    value={formData.dateOfBirth}
                                    onChange={handleInputChange}
                                    disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                  ></input>
                                </div>
                              </div>

                              {/* ----------------------------------------------- */}
                              {/*   Question 4 - Company Name                     */}
                              {/* ----------------------------------------------- */}

                              <div>
                                <p className='PreQ-Form-Application-Question'>4. Company Name *</p>
                                <div className='PreQ-Form-Application-Single-Column-Grid'>
                                  <input
                                    required
                                    className={formData.companyNameValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                    type='text' 
                                    name='companyName'
                                    placeholder='Enter your company name' 
                                    value={formData.companyName}
                                    onChange={handleInputChange}
                                    disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                  ></input>
                                </div>
                              </div>
                              
                              {/* ----------------------------------------------- */}
                              {/*   Question 5 - Company Position                 */}
                              {/* ----------------------------------------------- */}
                              
                              <div>
                                <p className='PreQ-Form-Application-Question'>5. Company Position *</p>
                                <div className='PreQ-Form-Application-Single-Column-Grid'>
                                  <input
                                    required
                                    className={formData.companyPositionValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                    type='text'
                                    name='companyPosition'
                                    placeholder='Enter your company position' 
                                    value={formData.companyPosition}
                                    onChange={handleInputChange}
                                    disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                  ></input>
                                </div>
                              </div>

                              {/* ----------------------------------------------- */}
                              {/*   Question 6 - Reason for Access                */}
                              {/* ----------------------------------------------- */}
                              
                              <div>
                                <p className='PreQ-Form-Application-Question'>6. Please provide a reason for your access *</p>
                                <div className='PreQ-Form-Application-Full-Width-Grid '>
                                  <textarea
                                    required
                                    className={formData.reasonForAccessValid ? 'PreQ-Form-Application-Input' : 'PreQ-Form-Application-Input-Error'} 
                                    name='reasonForAccess'
                                    placeholder='Enter a reason for access' 
                                    rows='5' 
                                    cols='100' 
                                    value={formData.reasonForAccess}
                                    onChange={(e) => setFormData({ 'reasonForAccess': e.target.value })}
                                    disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                  ></textarea>
                                </div>
                              </div>

                              {/* ----------------------------------------------- */}
                              {/*   Question 7 - AGSVA Clearance (Yes/No)         */}
                              {/* ----------------------------------------------- */}

                              <div> 
                                
                                {/* Question */}
                                <div className='PreQ-Form-Application-Question' style={{display: 'flex', flexDirection: 'row'}}>
                                  7. Do you currently hold an AGSVA security clearance (i.e. baseline or higher)? * 
                                </div>

                                {/* Radio Buttons */}
                                <div className='PreQ-Radio-Buttons-Container '>
                                  <label className='Radio-Buttons-Container'>
                                    Yes
                                    <input 
                                      type='radio' 
                                      name='AGSVA' 
                                      value='yes' 
                                      checked={formData.AGSVA === 'yes'} 
                                      onChange={handleInputChange}
                                      disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                    ></input>
                                    <span className={formData.AGSVAValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                                  </label>

                                  <label className='Radio-Buttons-Container'>
                                    No
                                    <input 
                                      type='radio' 
                                      name='AGSVA' 
                                      value='no' 
                                      checked={formData.AGSVA === 'no'} 
                                      onChange={handleInputChange}
                                      disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                      ></input>
                                    <span className={formData.AGSVAValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                                  </label>
                                </div>

                              </div>

                              {
                                // -----------------------------------------------//
                                //   AGSVA is 'Yes' > Upload Files                //
                                // -----------------------------------------------//

                                formData.AGSVA === 'yes' ? 
                                (

                                  <div className='PreQ-Form-Application-Required-Uploads' style={{padding: '15px'}}>

                                    <h6>REQUIRED UPLOADS</h6>
                                    <p>Please upload a copy of your current driver's licence (front and back).</p>

                                    {/* If Status is 'Not Submitted', Expiring OR 'In Review' > Allow Uploads */}
                                    {
                                      prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? 
                                      (
                                        <FileUploadDCACForm
                                          formData={formData}
                                          setFormData={setFormData}
                                          dataField={'AGSVAFiles'}
                                          errorMessageField={'AGSVAErrorMessage'}
                                          validField={'AGSVAValid'}
                                        ></FileUploadDCACForm>
                                      )
                                      :
                                      (
                                        <div className='PreQ-Uploads-List-Container'>
                                          <b>FILE NAME</b>
                      
                                          {/* File Link */}
                                          {
                                              formData.AGSVAFiles.map((object, index) => (
                                                  <div className='PreQ-Uploads-List-Row' key={index}>

                                                    {/* File Name */}
                                                    <a 
                                                      href={object.signedurl} 
                                                      alt={object.filename} 
                                                      target='_blank' 
                                                      rel='noreferrer'>
                                                        {object.filename}
                                                    </a>
                    
                                                    {/* View File */}
                                                    <div className='PreQ-Uploads-List-ViewFile'>
                                                      <a
                                                        href={object.signedurl} 
                                                        alt={object.filename} 
                                                        target='_blank' 
                                                        rel='noreferrer'>
                                                        <img className='Get-Signed-Url-ViewFile' src={ViewFileIcon} alt='View File' title='View File'></img>
                                                      </a>  
                                                    </div>
                                                        
                                                  </div>
                                              ))
                                          }
                                        </div>
                                      )
                                    }

                                  </div>

                                )
                                :
                                // ------------------------------------------------//
                                //   AGSVA is 'No' > Skip to Next Question         //
                                // ------------------------------------------------//
                                (
                                
                                <>
                                  {/*---------------------------------------------------------------*/}
                                  {/*  Question 8 - Are you an Australian Citizen?                  */}
                                  {/*---------------------------------------------------------------*/}

                                  <div>
                                    
                                    {/* Question */}
                                    <p className='PreQ-Form-Application-Question' style={{display: 'flex', flexDirection: 'row'}}>
                                      8. Are you an Australian citizen? *
                                    </p>

                                    {/* Radio Buttons */}
                                    <div className='PreQ-Radio-Buttons-Container'>
                                      <label className='Radio-Buttons-Container'>
                                        <input 
                                          type='radio' 
                                          name='AusCit'
                                          value='yes' 
                                          checked={formData.AusCit === 'yes'} 
                                          onChange={handleInputChange} 
                                          disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                        ></input>
                                        <span className={formData.AusCitValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                                        Yes
                                      </label>

                                      <label className='Radio-Buttons-Container'>
                                        <input 
                                          type='radio' 
                                          name='AusCit'
                                          value='no' 
                                          checked={formData.AusCit === 'no'} 
                                          onChange={handleInputChange} 
                                          disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                        ></input>
                                        <span className={formData.AusCitValid ? 'Radio-Checkmark'  : 'Radio-Checkmark-Error'}></span>
                                        No
                                      </label>
                                    </div>

                                    {/* Required Uploads */}
                                    <div>
                                      {
                                        formData.AusCit === 'yes' ? (

                                        // --------------------------------------------------------------- //
                                        //   Australian Citizen ('Yes') > Upload License/Passport          //
                                        // --------------------------------------------------------------- //

                                          <div className='PreQ-Form-Application-Required-Uploads'>

                                            <h6>REQUIRED UPLOADS</h6>

                                            <p>Please upload a copy of your current driver's licence (front and back).</p>

                                            <div className='PreQ-Form-Application-Upload-Option-Container'>
                                              <div className='PreQ-Form-Application-Upload-Option'>A</div>
                                              <ul>
                                                <li className='list-disc'>Current Australian Passport</li>
                                                <li className='list-disc'>Current National Police Check (cannot be older than 30 days on date of approval). <b>Note: </b>needs to be uploaded by 21 days to allow for processing. </li>
                                              </ul>
                                            </div>

                                            <p>OR</p>

                                            <div className='PreQ-Form-Application-Upload-Option-Container'>
                                              <div className='PreQ-Form-Application-Upload-Option'>B</div>
                                              <ul>
                                                <li className='list-disc'>Current Driver's Licence (Front and Back)</li>
                                                <li className='list-disc'>Birth Certificate</li>
                                                <li className='list-disc'>Current National Police Check (cannot be older than 30 days on date of approval). <b>Note: </b>needs to be uploaded by 21 days to allow for processing. </li>
                                              </ul>
                                            </div>

                                            <p style={{padding: '15px 0px 10px 0px'}}><b>Note: </b>If you were born overseas but are an Australian Citizen and do not hold a current Australian Passport, then upload a copy of your Australian Citizenship Certificate and current Driver's Licence (Front and Back).</p>


                                            {/* If Status is 'Not Submitted' OR 'In Review' > Allow Uploads */}
                                            {
                                              prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? 
                                              (

                                                <FileUploadDCACForm
                                                  formData={formData}
                                                  setFormData={setFormData}
                                                  dataField={'AusCitFiles'}
                                                  errorMessageField={'AusCitErrorMessage'}
                                                  validField={'AusCitValid'}
                                                ></FileUploadDCACForm>

                                              )
                                              :
                                              (
                                                <div className='PreQ-Uploads-List-Container'>
                                                  <b>FILE NAME</b>
                              
                                                  {/* File Link */}
                                                  {
                                                      formData.AusCitFiles.map((object, index) => (
                                                          <div className='PreQ-Uploads-List-Row' key={index}>

                                                            {/* File Name */}
                                                            <a 
                                                              href={object.signedurl} 
                                                              alt={object.filename} 
                                                              target='_blank' 
                                                              rel='noreferrer'>
                                                                {object.filename}
                                                            </a>
                            
                                                            {/* View File */}
                                                            <div className='PreQ-Uploads-List-ViewFile'>
                                                              <a
                                                                href={object.signedurl} 
                                                                alt={object.filename} 
                                                                target='_blank' 
                                                                rel='noreferrer'>
                                                                <img className='Get-Signed-Url-ViewFile' src={ViewFileIcon} alt='View File' title='View File'></img>
                                                              </a>  
                                                            </div>
                                                                
                                                          </div>
                                                      ))
                                                  }
                                                </div>
                                              )
                                            }

                                          </div>

                                        // --------------------------------------------------------------- //
                                        //   Australian Citizen ('No') > Upload Foreign Passport           //
                                        // --------------------------------------------------------------- //

                                        ) 
                                        : 
                                        formData.AusCit === 'no' ? (
                                          <div className='PreQ-Form-Application-Required-Uploads'>                                          
                                            <h6>REQUIRED UPLOADS</h6>
                                            <p>Please upload the following documents</p>
                                            <ul>
                                              <li className='list-disc'>Current Foreign Passport</li>
                                              <li className='list-disc'>VEVO check obtained via the Department of Home Affairs Website.
                                                <p style={{margin: '0px'}}><b>Note: </b>should be two pages in length and not older than 1 month. </p>
                                                <a href='https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online'>https://immi.homeaffairs.gov.au/visas/already-have-a-visa/check-visa-details-and-conditions/check-conditions-online</a>
                                              </li>
                                              <li className='list-disc'>
                                                <p style={{margin: '0px'}}>Current National Police Check (cannot be older than 30 days on date of approval).</p>
                                                <b>Note: </b>needs to be uploaded by 21 days to allow for processing.
                                              </li>
                                            </ul>

                                            <br></br>

                                            {/* If Status is 'Not Submitted' OR 'In Review' > Allow Uploads */}
                                            {
                                              prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? 
                                              (
                                                <FileUploadDCACForm
                                                  formData={formData}
                                                  setFormData={setFormData}
                                                  dataField={'AusCitFiles'}
                                                  errorMessageField={'AusCitErrorMessage'}
                                                  validField={'AusCitValid'}
                                                ></FileUploadDCACForm>
                                              )
                                              :
                                              (
                                                <div className='PreQ-Uploads-List-Container'>
                                                  <b>FILE NAME</b>
                              
                                                  {/* File Link */}
                                                  {
                                                      formData.AusCitFiles.map((object, index) => (
                                                          <div className='PreQ-Uploads-List-Row' key={index}>

                                                            {/* File Name */}
                                                            <a 
                                                              href={object.signedurl} 
                                                              alt={object.filename} 
                                                              target='_blank' 
                                                              rel='noreferrer'>
                                                                {object.filename}
                                                            </a>
                            
                                                            {/* View File */}
                                                            <div className='PreQ-Uploads-List-ViewFile'>
                                                              <a
                                                                href={object.signedurl} 
                                                                alt={object.filename} 
                                                                target='_blank' 
                                                                rel='noreferrer'>
                                                                <img className='Get-Signed-Url-ViewFile' src={ViewFileIcon} alt='View File' title='View File'></img>
                                                              </a>   
                                                            </div>
                                                                
                                                          </div>
                                                      ))
                                                  }
                                                </div>
                                              )
                                            }

                                          </div>
                                        )
                                        : null
                                      }
                                    </div>

                                  </div>

                              
                                  {/*---------------------------------------------------------------*/}
                                  {/*  Question 9 - Disclosable Outcomes                            */}
                                  {/*---------------------------------------------------------------*/}

                                  <div>

                                    {/* Question */}
                                    <p className='PreQ-Form-Application-Question' style={{display: 'flex', flexDirection: 'row'}}>
                                      9. Do you have any disclosable outcomes of your National Police Check? *
                                    </p>

                                    {/* Radio Buttons */}
                                    <div className='PreQ-Radio-Buttons-Container '>
                                      <label className='Radio-Buttons-Container'>
                                        <input 
                                          type='radio'
                                          name='NPC'
                                          value='yes'
                                          checked={formData.NPC === 'yes'}
                                          onChange={handleInputChange} 
                                          disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                        ></input>
                                        <span className={formData.NPCValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                                        Yes
                                      </label>

                                      <label className='Radio-Buttons-Container'>
                                        <input 
                                          type='radio'
                                          name='NPC'
                                          value='no'
                                          checked={formData.NPC === 'no'}
                                          onChange={handleInputChange} 
                                          disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                        ></input>
                                        <span className={formData.NPCValid ? 'Radio-Checkmark' : 'Radio-Checkmark-Error'}></span>
                                        No
                                      </label>
                                    </div>

                                    {/* Required Uploads */}
                                    {
                                      formData.NPC === 'yes' && (
                                        <div className='PreQ-Form-Application-Required-Uploads'>
                                          <h6>REQUIRED UPLOADS</h6>
                                          <p>Please upload a copy of a referral letter from your current employer below.</p>

                                          {/* If Status is 'Not Submitted' OR 'In Review' > Allow Uploads */}
                                          {
                                            prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? 
                                            (

                                              <FileUploadDCACForm
                                                formData={formData}
                                                setFormData={setFormData}
                                                dataField={'NPCFiles'}
                                                errorMessageField={'NPCErrorMessage'}
                                                validField={'NPCValid'}
                                              ></FileUploadDCACForm>

                                            )
                                            :
                                            (
                                              <div className='PreQ-Uploads-List-Container'>
                                                <b>FILE NAME</b>
                            
                                                {/* File Link */}
                                                {
                                                    formData.NPCFiles.map((object, index) => (
                                                        <div className='PreQ-Uploads-List-Row' key={index}>

                                                          {/* File Name */}
                                                          <a 
                                                            href={object.signedurl} 
                                                            alt={object.filename} 
                                                            target='_blank' 
                                                            rel='noreferrer'>
                                                              {object.filename}
                                                          </a>
                          
                                                          {/* View File */}
                                                          <div className='PreQ-Uploads-List-ViewFile'>
                                                            <a
                                                              href={object.signedurl} 
                                                              alt={object.filename} 
                                                              target='_blank' 
                                                              rel='noreferrer'>
                                                              <img className='Get-Signed-Url-ViewFile' src={ViewFileIcon} alt='View File' title='View File'></img>
                                                            </a>  
                                                          </div>
                                                              
                                                        </div>
                                                    ))
                                                }
                                              </div>
                                            )
                                          }

                                        </div>
                                      )
                                    }

                                  </div>
                                
                                </>  
                                )
                              }

                              {/* ----------------------------------------------- */}
                              {/*  Nominated Pass Office                          */}
                              {/* ----------------------------------------------- */}

                              <div>

                                {/* Question */}
                                <p className='PreQ-Form-Application-Question' style={{display: 'flex', flexDirection: 'row'}}>
                                  10. Nominated Pass Office *
                                </p>

                                {/* Select an office */}
                                <div className='PreQ-Form-NominatedOffice-Container'>

                                  {/* State Dropdown (Pre-populated) */}
                                  <select className='PreQ-Form-NominatedOffice-Select' value={projectDoc?.nominatedpassstate} disabled>
                                    <option selected>{projectDoc?.nominatedpassstate}</option>
                                  </select>
                                  
                                  {/* Office Dropdown (Pre-populated) */}
                                  <select className='PreQ-Form-NominatedOffice-Select' value={projectDoc?.nominatedpassoffice} disabled>
                                    <option selected>{projectDoc?.nominatedpassoffice}</option>
                                  </select>

                                  <div style={{paddingTop: '10px'}}>
                                    <label style={{color: '#545454', padding: '10px 0px'}}>If you require an alternate pass office, please enter the name of the pass office below.</label>
                                    <div>
                                      <input
                                        style={{width: '50%', minWidth: '250px', margin: '0px'}}
                                        className={formData.passOfficeNewValid ? ('PreQ-Form-Application-Input'): ('PreQ-Form-Application-Input-Error')} 
                                        type='text'
                                        onChange={(e) => setFormData({'passOfficeNew': e.target.value})}
                                        value={formData.passOfficeNew}
                                        disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                      ></input>
                                    </div>

                                  </div>

                                </div>

                              </div>

                              {/* ----------------------------------------------- */}
                              {/*  DECLARATION                                    */}
                              {/* ----------------------------------------------- */}

                              <div className='PreQ-Form-Application-Declaration'>
                                <h6>DECLARATION</h6>

                                {/* Checkbox 1 - Right to Work */}
                                <div>
                                  <label className='PreQ-Form-Checkbox-Container'>
                                    <input
                                      required
                                      type='checkbox'
                                      id='DecRightToWork'
                                      name='DecRightToWork'
                                      checked={formData.DecRightToWork}
                                      onChange={() => setFormData({ 'DecRightToWork': !formData.DecRightToWork, 'DecRightToWorkValid': true })}
                                      disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                    ></input>
                                    <span className={formData.DecRightToWorkValid ? 'PreQ-Form-Checkbox' : 'PreQ-Form-Checkbox-Error'}></span>
                                  </label>
                                  <p>By ticking this box, I confirm that the information provided in this form and my profile is true and accurate. I am lawfully entitled to work in Australia and can provide all relevant documentation confirming this right to work if requested.</p>
                                </div>

                                {/* Checkbox 2 - Conditions of Use */}
                                <div>
                                  <label className='PreQ-Form-Checkbox-Container'>
                                    <input
                                      required
                                      type='checkbox'
                                      id='DecConditionsOfUse'
                                      name='DecConditionsOfUse'
                                      checked={formData.DecConditionsOfUse}
                                      onChange={() => setFormData({ 'DecConditionsOfUse': !formData.DecConditionsOfUse, 'DecConditionsOfUseValid': true })}
                                      disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                    ></input>
                                    <span className={formData.DecConditionsOfUseValid ? 'PreQ-Form-Checkbox' : 'PreQ-Form-Checkbox-Error'}></span>
                                  </label>
                                  <p>I agree that on collection of my DCAC at the nominated pass office, I will read, sign and abide by the Dept of Defence <a href={signedUrls.conditionsofuse} target='_blank' rel='noreferrer'>Conditions of Use</a> of a Defence Common Access Card.</p>
                                </div>

                                {/* Checkbox 3 - Non-Disclosure Agreement --> OPTIONAL */}
                                {
                                  signedUrls.nda[0]?.fileid?.length > 0 && 

                                    <div>
                                      <label className='PreQ-Form-Checkbox-Container'>
                                        <input
                                          required
                                          type='checkbox'
                                          id='DecNDA'
                                          name='DecNDA'
                                          checked={formData.DecNDA}
                                          onChange={() => setFormData({ 'DecNDA': !formData.DecNDA, 'DecNDAValid': true })}
                                          disabled={prequalification.status === 'not-submitted' || prequalification?.lastexpirynotificationssent?.first === true || prequalification.status === 'moreinfo' ? false : true}
                                        ></input>
                                        <span className={formData.DecNDAValid ? 'PreQ-Form-Checkbox' : 'PreQ-Form-Checkbox-Error'}></span>
                                      </label>

                                      <p>I have read and agree to the terms outlined in the <a href={signedUrls.nda[0]?.signedurl} alt={signedUrls.nda[0]?.filename} target='_blank' rel='noreferrer'>Non-Disclosure Agreement</a>.</p>
                                      
                                    </div>
                                }

                              </div>

                            </div>
                          </div>
        
                        )
                      }

                    {/* ------------------------------------ */}
                    {/*   ADDITIONAL INFORMATION             */}
                    {/* ------------------------------------ */}

                      {/* -------------- EXPANDABLE PANE -------------*/}
                      
                      <div 
                        className='PreQ-Form-Expandable-Pane'
                        onClick={() => {

                          if(formData.addInfoOpen) setFormData({'addInfoComplete': true})
                          setFormData({
                            'addInfoOpen': !formData.addInfoOpen,
                          })
                        
                        }}
                      >
                        
                        {/* Step 3 */}
                        <img 
                          className='PreQ-Form-Expandable-Pane-Step' 
                          src={formData.addInfoComplete ? TickedIcon : formData.addInfoOpen ? Step3CompleteIcon : Step3Icon} 
                          alt='Additional Information'
                        ></img>

                        {/* ADDITIONAL INFORMATION */}
                        <label className='PreQ-Form-Expandable-Pane-Heading'>ADDITIONAL INFORMATION</label>

                        {/* V */}
                        <img 
                          className='PreQ-Form-Expandable-Pane-ExpandCollapseIcon' 
                          src={formData.addInfoOpen ? CollapseIcon : ExpandIcon} 
                          alt='Expand' 
                          onClick={() => {
                            if(formData.addInfoOpen) setFormData({'addInfoComplete': true})
                            setFormData({
                              'addInfoOpen': !formData.addInfoOpen,
                            })
                          }}
                        ></img>

                      </div>

                      {/* ---------- ADDITIONAL INFORMATION ---------- */}

                      {
                        formData.addInfoOpen && (
                          <div className='PreQ-Form-Expandable-Pane-Content'>
                            <div className='PreQ-Form-AddInfo-Container'>

                              <p>Provide any additional information regarding your application for the reviewer below.</p>
                              <h6>COMMENTS</h6>

                              {/* ----------------- COMMENTS ----------------- */}

                              <div className='PreQ-Form-AddInfo-Comments-Container'>

                                {/* Text Area */}
                                <div className='PreQ-Form-AddInfo-Comments'>
                                  <textarea 
                                    rows='5' 
                                    cols='100'
                                    placeholder='Enter any additional comments you may want your reviewer to see.' 
                                    value={formData.addInfoCommentTextArea}
                                    onChange={(e) => {
                                      setFormData({
                                        'addInfoCommentTextArea': e.target.value,
                                        'addInfoSendValid': true
                                      })
                                    }}
                                    disabled={prequalification.status === 'not-submitted' || prequalification.status === 'moreinfo' || prequalification.status === 'review' || prequalification?.lastexpirynotificationssent?.first === true ? false : true}
                                  ></textarea>

                                  
                                  {/* ----------------- UPLOAD FILE ----------------- */}
                                  {
                                    formData.addInfoUploadFile &&

                                      <FileUploadDCACForm
                                        formData={formData}
                                        setFormData={setFormData}
                                        dataField={'addInfoFile'}
                                        errorMessageField={'addInfoErrorMessage'}
                                        validField={'addInfoValid'}
                                      ></FileUploadDCACForm>

                                  }
                                </div>

                                <div className='PreQ-Form-AddInfo-Comments-Container-Buttons'>

                                  {/* Send Button */}
                                  <button 
                                    className='Primary-Button flex flex-row gap-2 justify-center items-center' 
                                    disabled={formData.addInfoSendValid ? false : true}
                                    onClick={() => {
                                      
                                      formData.addInfoComments.push(
                                        {
                                          'actionedby': `${currentUser?.givenname} ${currentUser?.surname} `,
                                          'attachedfile': formData.addInfoFile,
                                          'comment': formData.addInfoCommentTextArea,
                                          'actiondate': new Date()
                                        }
                                      )
                                      formData.addInfoUploadFile = false;
                                      formData.addInfoFile = [];
                                      formData.addInfoSendValid = false;
                                      setFormData(formData);
                                      setFormData({'addInfoCommentTextArea': ''});
                                  
                                    }}
                                  >  
                                    <img src={SendMail} alt='Send Mail'></img> 
                                    <p className='m-0'>Send</p>
                                  </button>

                                  {/* Attach File Button */}
                                  <button 
                                    className='Secondary-Button flex flex-row gap-2 justify-center items-center' 
                                    onClick={() => setFormData({ 'addInfoUploadFile': true })} 
                                    disabled={prequalification.status === 'not-submitted' || prequalification.status === 'moreinfo' || prequalification.status === 'review' || prequalification?.lastexpirynotificationssent?.first === true ? false : true}
                                    >  
                                    <img src={AttachFile} alt='Attach File' style={{padding: '0px 5px 0px 10px'}}></img> 
                                    <p className='m-0'> Attach File </p>
                                  </button>
                                  
                                </div>

                              </div>

                              <hr></hr>

                              {/* ----------------- COMMENTS THREAD ----------------- */}

                              {
                                formData.addInfoComments.length > 0 && 

                                  <CommentsThread
                                    formData={formData}
                                  ></CommentsThread>

                              }

                            </div>
                          </div>
                        )
                      }
                    </div>
                  
                    {/* ------------------------------------ */}
                    {/*   SUBMIT BUTTONS                     */}
                    {/* ------------------------------------ */}

                    <div className='PreQ-Form-Container-Buttons '>

                      <button 
                        className='Primary-Button' 
                        onClick={handleNewDCACSubmission} 
                        disabled={
                          prequalification.status === 'review' ||
                          prequalification.status === 'pending' ||
                          prequalification.status === 'pendingapproval' ||
                          (prequalification.status === 'approved' && prequalification?.lastexpirynotificationssent?.first === false) ||
                          prequalification.status === 'rejected'
                          ? true : false
                        }
                      > 
                        Submit
                      </button>  

                      <button 
                        className='Secondary-Button' 
                        onClick={handleSaveDCACSubmission}
                        disabled={formData.preventSave || prequalification.status === 'not-submitted' || prequalification.status === 'moreinfo' || (prequalification.status === 'approved' && prequalification?.lastexpirynotificationssent?.first === true) ? false : true}
                      > 
                        Save 
                      </button>   

                      <button className='Secondary-Button' onClick={() => navigate(`/prequalifications?emailaddress=${currentUser.emailaddress}`)}> Cancel </button>
      
                    </div>

                  </div>
                ) 
                :
                null
              }
            </div>

          </>


      </div>
    )
}