//Libraries
import React, { useContext } from 'react';
import App from '../App';
import { BrowserRouter } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

//Contexts
import { GetAppStatus, GetAppErrors } from './GlobalContexts';

//Components
import MicrosoftLoginForm from './MicrosoftLoginForm/MicrosoftLoginForm';
import GoogleLoginForm from './GoogleLoginForm/GoogleLoginForm';
import Logout from './Logout';

//Images
import LoadingIcon from '../Components/Images/Image_Loading_Ripple.svg';
import Logo from '../Components/Images/Logo_Lendlease.svg';

//CSS
import './LoginHandler.css';

/**
 * Handler for all login events. Designed to handle the login process, handle failures and if success give a user an getAccessToken
 * The 'LoginHandlerComponent' is built to handle both 'authState'
 *    'authenticated'
 *    'unauthenticated'
 * 
 *  * ```
 * ReactDOM.render(
 *     <UserContexts>
 *       <AuthProvider>
 *         <LoginHandler></LoginHandler>
 *       </AuthProvider>
 *     </UserContexts>,
 *   document.getElementById('root')
 * );
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/auth/web/microsoft-oauth#next_steps
 * 
 */


function LoginHandler() {

  //------------------------------------------------------
  //  Import Firebase
  //  https://firebase.google.com/docs/auth/web/microsoft-oauth#next_steps
  //------------------------------------------------------

    // eslint-disable-next-line
    const auth = getAuth();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getAppStatus = useContext(GetAppStatus)
    const getAppErrors = useContext(GetAppErrors)

  //------------------------------------------------------
  //  Authorised Users
  //------------------------------------------------------

    if (getAppStatus === 'authenticated'){

      //Return the App
      return (
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      )
    }

  //------------------------------------------------------
  //  Pending
  //------------------------------------------------------

    else if (getAppStatus === 'pending'){
  
      return (
        <div className='LoginHandler-Pending'>
          <img className='w-[20%]' alt='loading-circle-icon' src={LoadingIcon}></img>
          <Logout title='Cancel'></Logout>
        </div>
      )
    }

  //------------------------------------------------------
  //  Access Denied
  //------------------------------------------------------
    
    else if (getAppStatus === 'accessdenied'){

      return (
        <div className='LoginHandler-Failed-Container'>

          <div className='LoginHandler-Failed-Img'>
            <img alt='Logo' src={Logo}></img>
          </div>

          <div className='LoginHandler-Failed-Error'>
            <h3>Access Denied!</h3>
            <p>If you feel this is a mistake, reach out to the Lendlease Project Admin.</p>
            <br></br>
            <Logout title='Return to sign in page'></Logout>
          </div>

        </div>
      )
    }

  //------------------------------------------------------
  //  Failed
  //------------------------------------------------------

    else if (getAppStatus === 'failed'){

      //------------------------------------------------------
      //  Handles each known error message, with a generic catch all response!
      //------------------------------------------------------

        function HandleErrorMessage(message){

          // General catch, to ensure something exists
          if (message === undefined) return;

          // Allow third-party cookies
          if (message.includes('web-storage-unsupported')){
            return(
              <div>
                Third party cookies are disabled. Enable <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop'>third-party cookies</a> in your browser and try again.
              </div>
            )
          }

          // Failed to verify the users credenitals, user must close tab and sign in again
          if (message.includes('auth/invalid-credential')){
            return(
              <div>
                Failed verify your credentials. Close the browser and try again.
              </div>
            )
          }

          // User closed the sign in pop up window
          if (message.includes('auth/popup-closed-by-user')){
            return(
              <div>
                You closed the sign in pop up. Click 'return to the sign in page' and try again.
              </div>
            )
          }

          // User is blocking popups
          if (message.includes('auth/popup-blocked')){
            return(
              <div>
                Browser is blocking pop ups. Allow <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop'>pop ups</a> and try again.
              </div>
            )
          }

          // There is an existing Firebase account with this users UPN 
          if (message.includes('auth/account-exists-with-different-credential')){
            return(
              <div>
                Found an existing account with the same email address.
              </div>
            )
          }

          // Poor connection
          if (message.includes('bad-network-connection')){
            return(
              <div>
                Unable to connect, this typically indicates your device does not have a healthy Internet connection. Try again on another device or network.
              </div>
            )
          }

          // General catch all
          else{
            return(
              <div>
                An unknown issue has occurred.
              </div>
            )
          }
        }

      //------------------------------------------------------
      //  Show 'Oops, something went wrong!' page 
      //------------------------------------------------------

        return (
          <div className='LoginHandler-Failed-Container'>

            <div className='LoginHandler-Failed-Img'>
              <img alt='Logo' src={Logo}></img>
            </div>

            <div className='LoginHandler-Failed-Error'>
              <h3>Oops, something went wrong!</h3>
              {HandleErrorMessage(getAppErrors)}
              <p>Generally these issues are related to an unhealthy internet connection and/or device. Try again in 20 minutes or from another device.</p>
            </div>

          </div>
        )
    }

  //------------------------------------------------------
  //  Un-Authorised Users
  //------------------------------------------------------

    else if (getAppStatus === 'unauthenticated'){

      return (
        <div className='LoginPage-Container'>
          <div className='Login-Container'>

            {/* Login Header */}
            <h4>Lendlease DCAC Portal</h4>

            {/* Microsoft Login */}
            <MicrosoftLoginForm></MicrosoftLoginForm>

            {/* Google Login */}
            <GoogleLoginForm></GoogleLoginForm>
              
          </div>
        </div>
      )

    }

  //------------------------------------------------------
}

export default LoginHandler;