//================================================================
//  Component: PreQualifications of component
//================================================================

//  Purpose: This is the standard for creating a component

//  Properties:
//    - name = {A string, this is the persons name}
//    - message = {A string, a short message}

//  Example:
//    <ProfileForm
//      name={"Mark Bennett"}
//      message={"stop breaking stuff!"}
//    ></ProfileForm>    

//================================================================


//Libraries
import React, { useReducer, useEffect, useContext } from 'react';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components
import ProfileUploads from '../Components/ProfileUploads';

//Functions
import writeDocument from '../../../Library/WriteDocument';

//Images

//CSS
import '../Profile.css';


export default function ProfileForm({
  currentUser,
  profileUploads,
  setPageStatus
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    const [formData, setFormData] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        //Profile Form states
        'givenname': currentUser?.givenname,
        'givennamevalid': true,
        'surname': currentUser?.surname,
        'surnamevalid': true,
        'addresslineone': currentUser?.addresslineone,
        'addresslineonevalid': true,
        'addresslinetwo': currentUser?.addresslinetwo,
        'addresslinetwovalid': true,
        'city': currentUser?.city,
        'cityvalid': true,
        'state': currentUser?.state,
        'statevalid': true,
        'zipcode': currentUser?.zipcode,
        'zipcodevalid': true,
        'mobile': currentUser?.mobile,
        'mobilevalid': true,
        'phone': currentUser?.phone,
        'phonevalid': true,
        'dob': currentUser?.dob,
        'dobvalid': true,
        'gender': currentUser?.gender,
        'gendervalid': true,
        'emergencycontact': currentUser?.emergencycontact,
        'emergencycontactvalid': true,
        'emergencycontactphone': currentUser?.emergencycontactphone,
        'emergencycontactphonevalid': true,
        'ancestry': currentUser?.ancestry,
        'ancestryvalid': true,
        'militaryveteran': currentUser?.militaryveteran,
        'militarystatusvalid': true,
        'apprentice': currentUser?.apprentice,
        'apprenticevalid': true,
        'subby': currentUser?.subby,
        'subbyvalid': true,
        'subbyorg': currentUser?.subbyorg,
        'subbyorgvalid': true,
        'medical': currentUser?.medical,
        'medicalvalid': true,
        'allergies': currentUser?.allergies,
        'allergiesvalid': true,
        'profileUploadsFiles': profileUploads,
        'profileUploadsValid': true,
        'profileUploadsErrorMessage': '',

        // Form Errors
        'formError': '',

      }
    );

  
  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    function onSubmit(e){

      e.preventDefault();

      // ----------------------------------------------------------
      // 0. Prepare
      // ----------------------------------------------------------

      // Simple function to handle undefined values
      function handleUndefined(value){

        if (value === undefined) {

          return '';

        }

        return value;

      }

      // ----------------------------------------------------------
      // 1. Validatation
      // ----------------------------------------------------------

        let preventSubmit = false;

        //Validate field - 'addresslineone'
        if(formData.addresslineone?.length === 0) {

          setFormData({'addresslineonevalid': false});
          preventSubmit = true;

        } else {

          setFormData({'addresslineonevalid': true});

        }

        //Validate field - 'city'
        if(formData.city?.length === 0) {

          setFormData({'cityvalid': false});
          preventSubmit = true;

        } else {

          setFormData({'cityvalid': true});

        }

        //Validate field - 'state'
        if(formData.state?.length === 0) {

          setFormData({'statevalid': false});
          preventSubmit = true;

        } else {

          setFormData({'statevalid': true});

        }

        //Validate field - 'zipcode'
        if(formData.zipcode?.length === 0) {

          setFormData({'zipcodevalid': false});
          preventSubmit = true;

        } else {

          setFormData({'zipcodevalid': true});

        }

        //Validate field - 'ancestry'
        if(formData.ancestry?.length === 0) {

          setFormData({'ancestryvalid': false});
          preventSubmit = true;

        } else {

          setFormData({'ancestryvalid': true});

        }

        //Validate field - 'militaryveteran'
        if(formData.militaryveteran?.length === 0) {

          setFormData({'militarystatusvalid': false});
          preventSubmit = true;

        } else {

          setFormData({'militarystatusvalid': true});

        }

        //Validate field - 'dob'
        if(formData.dob?.length === 0) {

          setFormData({'dobvalid': false});
          preventSubmit = true;

        } else {

          setFormData({'dobvalid': true});

        }

        //Validate field - 'gender'
        if(formData.gender?.length === 0) {

          setFormData({'gendervalid': false});
          preventSubmit = true;

        } else {

          setFormData({'gendervalid': true});

        }
        
        //Validate field - 'apprentice'
        if(formData.apprentice?.length === 0) {

          setFormData({'apprenticevalid': false});
          preventSubmit = true;

        } else {

          setFormData({'apprenticevalid': true});

        }

        //Validate field - 'subby'
        if(formData.subby?.length === 0) {

          setFormData({'subbyvalid': false});
          preventSubmit = true;

        } else {

          setFormData({'subbyvalid': true});

          if (formData.subby === 'Yes' && formData.subbyorg?.length === 0) {

            setFormData({'subbyorgvalid': false});
            preventSubmit = true;

          } else {

            setFormData({'subbyorgvalid': true});

          }

        }

        //Validate field - 'mobile'
        if(formData.mobile?.length === 0) {

          setFormData({'mobilevalid': false});
          preventSubmit = true;

        } else {

          setFormData({'mobilevalid': true});

        }

        // Check if ALL validations have passed
        if (preventSubmit === true) {

          return setFormData({'formError': 'Complete the form before proceeding'});

        }

        setPageStatus('pending');

      // ----------------------------------------------------------
      // 2. Extract any new file uploads
      // ----------------------------------------------------------

      const arrayFilePromises = [];

      // For each file uploaded, write to files collection
      formData.profileUploadsFiles.forEach(file => {

        // Only process files with the FRONTEND ONLY status of 'new' or 'deleted'
        if (file.status === 'existing') return;

        //TODO-NOWSHIN & BENNO ~ Chat about duplicates, where I replace an existing fileid/name with an updated version
        const fileDoc = {
          fileid: file.id,
          filename: file.filename,
          requesttype: 'profile-uploads',
          folderpath: '',
          projectid: '',
          organisationid: currentUser.organisationid,
          createdby: getUser.emailaddress,
          workeremail: currentUser.emailaddress,
          status: file.status === 'new' ? ('pending transfer'): ('pending-delete'),
          description: '',
          dateobtained: new Date(),
          expirydate: '',
          signedurl: file.signedurl,
          signedurlexpirydate: ''
        }

        // Write to 'files' collection 
        arrayFilePromises.push(
          writeDocument('files', fileDoc.fileid, fileDoc, true)
        );
        
      });

      // ----------------------------------------------------------
      // 2. Update users document
      // ----------------------------------------------------------

      const userDoc = {
        'givenname': handleUndefined(formData.givenname),
        'surname': handleUndefined(formData.surname),
        'addresslineone': handleUndefined(formData.addresslineone),
        'addresslinetwo': handleUndefined(formData.addresslinetwo),
        'city': handleUndefined(formData.city),
        'state': handleUndefined(formData.state),
        'zipcode': handleUndefined(formData.zipcode),
        'mobile': handleUndefined(formData.mobile),
        'phone': handleUndefined(formData.phone),
        'dob': handleUndefined(formData.dob),
        'gender': handleUndefined(formData.gender),
        'emergencycontact': handleUndefined(formData.emergencycontact),
        'emergencycontactphone': handleUndefined(formData.emergencycontactphone),
        'ancestry': handleUndefined(formData.ancestry),
        'militaryveteran': handleUndefined(formData.militaryveteran),
        'apprentice': handleUndefined(formData.apprentice),
        'subby': handleUndefined(formData.subby),
        'subbyorg': handleUndefined(formData.subbyorg),
        'medical': handleUndefined(formData.medical),
        'allergies': handleUndefined(formData.allergies),
      };
      const userPromise = [
        writeDocument('users', currentUser.emailaddress, userDoc, true)
      ];
      
      // ----------------------------------------------------------
      // 3. Settle Promises
      // ----------------------------------------------------------

      return Promise.all([...arrayFilePromises, ...userPromise]).then(() =>{

        setPageStatus('onload');

      }).catch((error) => {

        console.log(error);
        //BENNO-TODO ~ Add error handling!
    
      });

    };


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // onChange
    //  1. Update 'profileUploadsFiles' when 'profileUploads' changes
    useEffect(() => {

      if (profileUploads === undefined) return;

      setFormData({'profileUploadsFiles': profileUploads});

    }, [profileUploads]);

    // onChange
    //  1. Update 'profileUploadsFiles' when 'profileUploads' changes
    useEffect(() => {

      if (currentUser === undefined) return;

      setFormData({
        //Profile Form states
        'givenname': currentUser?.givenname,
        'givennamevalid': true,
        'surname': currentUser?.surname,
        'surnamevalid': true,
        'addresslineone': currentUser?.addresslineone,
        'addresslineonevalid': true,
        'addresslinetwo': currentUser?.addresslinetwo,
        'addresslinetwovalid': true,
        'city': currentUser?.city,
        'cityvalid': true,
        'state': currentUser?.state,
        'statevalid': true,
        'zipcode': currentUser?.zipcode,
        'zipcodevalid': true,
        'mobile': currentUser?.mobile,
        'mobilevalid': true,
        'phone': currentUser?.phone,
        'phonevalid': true,
        'dob': currentUser?.dob,
        'dobvalid': true,
        'emergencycontact': currentUser?.emergencycontact,
        'emergencycontactvalid': true,
        'emergencycontactphone': currentUser?.emergencycontactphone,
        'emergencycontactphonevalid': true,
        'ancestry': currentUser?.ancestry,
        'ancestryvalid': true,
        'apprentice': currentUser?.apprentice,
        'apprenticevalid': true,
        'subby': currentUser?.subby,
        'subbyvalid': true,
        'subbyorg': currentUser?.subbyorg,
        'subbyorgvalid': true,
        'medical': currentUser?.medical,
        'medicalvalid': true,
        'allergies': currentUser?.allergies,
        'allergiesvalid': true,
        'profileUploadsFiles': profileUploads,
        'profileUploadsValid': true,
        'profileUploadsErrorMessage': '',
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

  
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <form className='Profile-Form' onSubmit={(e) => onSubmit(e)}>

      {/* ===================================== */}
      {/*          First Name & Surname         */}
      {/* ===================================== */}

      <div className='Profile-Form-Row'>

        <div>
          <label><b className='font-medium'>FIRST NAME</b> </label>
          <input
            className={formData.givennamevalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
            type='text'
            onChange={(e) => setFormData({'givenname': e.target.value})}
            value={formData.givenname}
          ></input>
        </div>

        <div>
          <label><b className='font-medium'>LAST NAME</b> </label>
          <input
            className={formData.surnamevalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
            type='text'
            onChange={(e) => setFormData({'surname': e.target.value})}
            value={formData.surname}
          ></input>
        </div>
    
      </div>

      {/* ===================================== */}
      {/*           ADDRESS LINE 1              */}
      {/* ===================================== */}

      <div className='Profile-Form-Row' style={{flexDirection: 'column'}}>

        <label><b className='font-medium'>ADDRESS LINE 1 *</b> </label>
        <input
          className={formData.addresslineonevalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
          type='text'
          onChange={(e) => setFormData({'addresslineone': e.target.value})}
          value={formData.addresslineone}
        ></input>

      </div>

      {/* ===================================== */}
      {/*           ADDRESS LINE 2              */}
      {/* ===================================== */}

      <div className='Profile-Form-Row' style={{flexDirection: 'column'}}>

        <label><b className='font-medium'>ADDRESS LINE 2</b> </label>
        <input
          className={formData.addresslinetwovalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
          type='text'
          onChange={(e) => setFormData({'addresslinetwo': e.target.value})}
          value={formData.addresslinetwo}
        ></input>

      </div>

      {/* ======================================================================== */}
      {/*        SUBURB/CITY    &   STATE/PROVINCE/AREA   &   POSTAL/ZIP CODE      */}
      {/* ======================================================================== */}

      <div className='Profile-Form-Row'>

        <div>
          <label><b className='font-medium'>SUBURB/CITY *</b> </label>
          <input
            className={formData.cityvalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
            type='text'
            onChange={(e) => setFormData({'city': e.target.value})}
            value={formData.city}
          ></input>
        </div>

        <div>
          <label><b className='font-medium'>STATE/PROVINCE/AREA *</b> </label>
          <input
            className={formData.statevalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
            type='text'
            onChange={(e) => setFormData({'state': e.target.value})}
            value={formData.state}
          ></input>
        </div>

        <div className='ProfileForm-ZipCode'>
          <label><b className='font-medium'>POSTAL/ZIP CODE *</b> </label>
          <input
            className={formData.zipcodevalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
            type='text'
            onChange={(e) => setFormData({'zipcode': e.target.value})}
            value={formData.zipcode}
          ></input>
        </div>

      </div>

      {/* ===================================== */}
      {/*         MOBILE & HOME PHONE           */}
      {/* ===================================== */}

      <div className='Profile-Form-Row'>

        <div>
          <label><b className='font-medium'>MOBILE *</b> </label>
          <input
            className={formData.mobilevalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
            type='text'
            onChange={(e) => setFormData({'mobile': e.target.value})}
            value={formData.mobile}
          ></input>
        </div>

        <div>
          <label><b className='font-medium'>HOME PHONE</b> </label>
          <input
            className={formData.phonevalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
            type='text'
            onChange={(e) => setFormData({'phone': e.target.value})}
            value={formData.phone}
          ></input>
        </div>

      </div>

      {/* ===================================== */}
      {/*         EMAIL & DATE OF BIRTH         */}
      {/* ===================================== */}

      <div className='Profile-Form-Row'>

        <div>
          <label><b className='font-medium'>EMAIL</b> </label>
          <input
            className='Input-Field-Text'
            type='text'
            disabled={true}
            value={currentUser?.emailaddress}
            autoComplete='off'
          ></input>
        </div>

        <div>
          <label><b className='font-medium'>DATE OF BIRTH *</b> </label>
          <input
            className={formData.dobvalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
            type='date'
            onChange={(e) => setFormData({'dob': e.target.value})}
            value={formData.dob}
          ></input>
        </div>

        <div className='flex flex-col pl-[20px]'>
          <label><b className='font-medium'>GENDER *</b> </label>
          <select 
            className={formData.gendervalid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
            onChange={(e) => setFormData({'gender': e.target.value})}
            value={formData.gender}
            >
              <option hidden value=''>-</option>
              <option value='Male'>Male</option>
              <option value='Female'>Female</option>
              <option value='Gender Not Listed'>Gender Not Listed</option>
          </select>
        </div>

      </div>

      {/* ========================================================= */}
      {/*    EMERGENCY CONTACT NAME   &   EMERGENCY CONTACT NUMBER  */}
      {/* ========================================================= */}

      <div className='Profile-Form-Row'>

        <div>
          <label><b className='font-medium'>EMERGENCY CONTACT NAME</b> </label>
          <input
            className={formData.emergencycontactvalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
            type='text'
            onChange={(e) => setFormData({'emergencycontact': e.target.value})}
            value={formData.emergencycontact}
          ></input>
        </div>

        <div>
          <label><b className='font-medium'>EMERGENCY CONTACT NUMBER</b> </label>
          <input
            className={formData.emergencycontactphonevalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
            type='text'
            onChange={(e) => setFormData({'emergencycontactphone': e.target.value})}
            value={formData.emergencycontactphone}
          ></input>
        </div>

      </div>

      {/* ========================================================== */}
      {/*    ABORIGINAL OR TORRES STRAIT ISLANDER & MILITARY VETERAN */}
      {/* ========================================================== */}

      <div className='Profile-Form-Row'>
        
        <div className='flex flex-col'>
          <label><b className='font-medium'>ABORIGINAL OR TORRES STRAIT ISLANDER *</b> </label>
          <select 
            className={formData.ancestryvalid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
            onChange={(e) => setFormData({'ancestry': e.target.value})}
            value={formData.ancestry}
            >
              <option hidden value=''>-</option>
              <option value='Yes'>Yes</option>
              <option value='No'>No</option>
          </select>
        </div>

        <div className='flex flex-col'>
          <label><b className='font-medium'>MILITARY VETERAN *</b> </label>
          <select 
            className={formData.militarystatusvalid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
            onChange={(e) => setFormData({'militaryveteran': e.target.value})}
            value={formData.militaryveteran}
            >
              <option hidden value=''>-</option>
              <option value='Yes'>Yes</option>
              <option value='No'>No</option>
          </select>
        </div>

      </div>

      {/* ========================================= */}
      {/*       APPRENTICE   &   ORGANISATION       */}
      {/* ========================================= */}

      <div className='Profile-Form-Row'>

        <div className='flex flex-col'>
          <label><b className='font-medium'>APPRENTICE *</b> </label>
          <select 
            className={formData.apprenticevalid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
            onChange={(e) => setFormData({'apprentice': e.target.value})}
            value={formData.apprentice}
            >
              <option hidden value=''>-</option>
              <option value='Yes'>Yes</option>
              <option value='No'>No</option>
          </select>
        </div>

        <div>
          <label><b className='font-medium'>ORGANISATION</b> </label>
          <input
            className='Input-Field-Text'
            type='text'
            disabled={true}
            value={currentUser.organisationname}
            autoComplete='off'
          ></input>
        </div>

      </div>

      {/* ============================================================ */}
      {/*     SUB-SUBCONTRACTOR  &   SUB-SUBCONTRACTOR ORGANISATION    */}
      {/* ============================================================ */}

      <div className='Profile-Form-Row'>

        <div className='flex flex-col'>
          <label><b className='font-medium'>SUB-SUBCONTRACTOR *</b> </label>
          <select 
            className={formData.subbyvalid ? ('Input-Field-Select'):('Input-Field-Select-Error')}
            onChange={(e) => setFormData({'subby': e.target.value})}
            value={formData.subby}
            >
              <option hidden value=''>-</option>
              <option value='Yes'>Yes</option>
              <option value='No'>No</option>
          </select>
        </div>

        <div>
        {
          formData.subby === 'Yes' && (
            <>
              <label><b className='font-medium'>SUB-SUBCONTRACTOR ORGANISATION *</b> </label>
              <input
                className={formData.subbyorgvalid ? ('Input-Field-Text'): ('Input-Field-Text-Error')} 
                type='text'
                onChange={(e) => setFormData({'subbyorg': e.target.value})}
                value={formData.subbyorg}
              ></input>
            </>
          )
        }
        </div>

      </div>

      {/* ========================================= */}
      {/*             MEDICAL CONDITIONS            */}
      {/* ========================================= */}

      <div className='Profile-Form-Row'>

        <div>
          <label><b className='font-medium'>MEDICAL CONDITIONS</b> </label>
          <textarea
            className={formData.medicalvalid ? ('Input-Field-TextArea'): ('Input-Field-TextArea-Error')} 
            onChange={(e) => setFormData({'medical': e.target.value})}
            value={formData.medical}
          ></textarea>
        </div>

      </div>

      {/* ========================================= */}
      {/*                ALLERGIES                  */}
      {/* ========================================= */}

      <div className='Profile-Form-Row'>

        <div>
          <label><b className='font-medium'>ALLERGIES</b> </label>
          <textarea
            className={formData.allergiesvalid ? ('Input-Field-TextArea'): ('Input-Field-TextArea-Error')} 
            onChange={(e) => setFormData({'allergies': e.target.value})}
            value={formData.allergies}
          ></textarea>
        </div>

      </div>

      {/* ========================================= */}
      {/*            PROFILE UPLOADS                */}
      {/* ========================================= */}

      <div className='Profile-Form-Row'>

        <div>
          {/* Header */}
          <label>
            <b className='font-medium'>PROFILE UPLOADS</b> 
          </label>

          {/* Files */}
          <ProfileUploads
            formData={formData}
            setFormData={setFormData}
          ></ProfileUploads>
        </div>

      </div>

      {/* ========================================= */}
      {/*               Buttons                    */}
      {/* ========================================= */}

      <div className='Profile-Form-Row' style={{marginTop: '20px'}}>

        <button style={{marginRight: '10px'}} className='Primary-Button'>Save Changes</button>
        <button className='Secondary-Button'>Discard</button>
      
      </div>

      {/* Error Messages */}
      <label className='Input-Field-Text-Error-Message' style={{paddingTop: '10px'}}>{formData.formError}</label>

    </form>
  )
}
