//================================================================
//  Component: Mobile Responsive PreQualifications Table
//================================================================

//  Purpose: This is the table for showing all the prequalification applications for a worker

//  Properties:
//    - currentUser = {useState, stores the current user's document (from the users collection)}
//    - formData = {useReducer, formData}

//  Example:
//    <PreQualificationsTable
//      currentUser={currentUser}
//      formData={formData}
//    ></PreQualificationsTable>    

//================================================================



//Libraries
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { GetAppSize } from '../../../Library/GlobalContexts.js';

//Components

//Functions
import ConvertDate from '../../../Library/ConvertDate.js';

//Images
import EditLogo from '../../../Components/Images/Icon_Edit_Grey.svg'

//CSS
import '../PreQualifications.css';


export default function PreQualificationsTableMobile({
  currentUser,
  formData,
}) {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------

    const navigate = useNavigate();
    const getAppSize = useContext(GetAppSize);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------


  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------


  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------


  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // Only show if the device is a mobile
  if (getAppSize.width > 1100) return null;

  return (

    <div className='PreQualifications-Table-Mobile'>

      <div className='PreQualifications-Table-Body-Mobile'>
        {
          formData.filtered.length === 0 ? (

            <div style={{ margin:'10px', padding: '5px'}}>
              No Pre-Qualifications found.
            </div>

          ): (

            formData.filtered.map((prequalification, index) => (

              <div key={index} className='PreQualifications-Row-Mobile'>
            
                {/* ======================= */}
                {/*     Project & Status    */}
                {/* ======================= */}

                <p className='PreQualifications-Mobile-Header'>

                  <span style={{display: 'flex', flexDirection: 'row'}}>
                    <img className='PreQualifications-Mobile-Edit' src={EditLogo} alt='' onClick={() => navigate(`/prequalifications/project?emailaddress=${currentUser.emailaddress}&project=${prequalification.projectid}`)}></img>

                    <label className='PreQualifications-Mobile-Header-Project' onClick={() => navigate(`/prequalifications/project?emailaddress=${currentUser.emailaddress}&project=${prequalification.projectid}`)}>
                      {prequalification.projectname}
                    </label>
                  </span>


                  {/*  Status */}
                  {
                    [
                      {'name': 'In Review', 'background': '#84C1D4', 'status': 'review'}, 
                      {'name': 'Requires More Info', 'background': '#E47525', 'status': 'moreinfo'}, 
                      {'name': 'Pending Approval', 'background': '#E4BA25', 'status': 'pending'}, 
                      {'name': 'Approved', 'background': '#61B280', 'status': 'approved'}, 
                      {'name': 'Rejected', 'background': '#D85C5C', 'status': 'rejected'}, 
                      {'name': 'Expired', 'background': '#C0C0C0', 'status': 'expired'}, 
                      {'name': 'Not Submitted', 'background': '#D484A6', 'status': 'not-submitted'}
                    ].filter((style) => style.status === prequalification.status).map((object, index) => (

                      <label
                        key={index} 
                        style={{backgroundColor: object.background}} 
                        className='PreQualifications-Status'
                      >
                        {object.name}
                      </label>
                    
                    ))
                  }

                </p>

                {

                  prequalification?.audithistory?.length === 0 ? (

                    <>
                      {/* ======================= */}
                      {/*      Modified           */}
                      {/* ======================= */}

                      <div>
                        <label>Modified</label>
                        N/A
                      </div>
                
                      {/* ======================= */}
                      {/*      Actioned By        */}
                      {/* ======================= */}

                      <div>
                        <label>Modified by</label>
                        N/A
                      </div>

                    </>

                  ) : (

                    <>
                      {/* ======================= */}
                      {/*      Modified           */}
                      {/* ======================= */}

                      <div>
                        <label>Modified</label>
                        {ConvertDate(prequalification?.audithistory?.at(-1)?.actiondate)}
                      </div>
                
                      {/* ======================= */}
                      {/*      Actioned By        */}
                      {/* ======================= */}

                      <div>
                        <label>Actioned By</label>
                        {prequalification?.audithistory?.at(-1)?.actionedby}
                      </div>

                    </>

                  )

                }

                {/* ======================= */}
                {/*      Expires On         */}
                {/* ======================= */}

                <div>
                  <label>Expires On</label>
                  {

                    prequalification?.expirydate?.length === 0 ? (

                      <>
                        N/A
                      </>

                    ) : (

                      <>
                        {prequalification?.expirydate}
                      </>

                    )

                  }
                </div>
                
              </div>
      
            ))

          )
        }

      </div>

    </div>

  )
}
