// Libraries
import { getStorage, ref, getDownloadURL } from "firebase/storage";

/**
 * Generic function to create a Firebase signed url for an Firebase GCS object.
 * 
 * @param {string} path
 * 
 * ```
 * const path = 'images/rads_legend.png';
 * 
 * DownloadFile(path).then((url) =>{
 * 
 *   console.log('url', url);
 * 
 * }).catch((error) =>{
 * 
 *   console.log('error', error);
 * 
 * });
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/storage/web/start
 */

export default async function DownloadFile(path){

  //------------------------------------------------------
  //  Get Collection
  //------------------------------------------------------

    try {

      // Create a root reference
      const storage = getStorage();
     
      // Create a reference to 'file' we want to download
      // 'images/figma.png'
      const storageRef = ref(storage, path);

      // Get download url for file
      return getDownloadURL(storageRef).then((results) =>{
        return results;
      })

    } catch (error) {
      throw new Error(`Function DownloadFile failed to complete, error ${error}`)
    }

  //------------------------------------------------------
}