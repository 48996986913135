//Libraries
import React, { useContext } from 'react';
import { getAuth, signOut } from 'firebase/auth';

//Contexts
import { SetAppStatus } from './GlobalContexts'

//Images
import LogoutImage from '../Components/Images/Icon_Logout_White.svg'

/**
 * Trigger a Firebase signout on button click
 * 
 * @param {undefined} NotApplicable
 * 
 * ```
 * 
 * <Logout></Logout>    
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/auth/web/microsoft-oauth#handle_the_sign-in_flow_with_the_firebase_sdk
 */

export default function Logout({
  title,
  setStyle
}) {

  //------------------------------------------------------
  // useContexts & useStates
  //------------------------------------------------------

    //Firebase
    const setAppStatus = useContext(SetAppStatus);

  //------------------------------------------------------
  //  Import Firebase
  //  https://firebase.google.com/docs/auth/web/microsoft-oauth#next_steps
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  //  Logout handler
  //------------------------------------------------------

    function LogOutHandler(){

      signOut(auth).then(() => {

        setAppStatus('unauthenticated');

      })
      //An error happened.
      .catch(() => {

        setAppStatus('unauthenticated');

      })
    }

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  Navbar signout button
    //------------------------------------------------------

      if (setStyle === 'navbar'){
        return (

          <div className='flex flex-row justify-between gap-4 px-4 py-[13px] mb-0 cursor-pointer' onClick={() => LogOutHandler()}>
            Sign Out
            <img 
              src={LogoutImage} 
              alt='Logout Icon' 
              width='25px' 
              height='25px' 
            ></img>
          </div>

        )
      }
    
    //------------------------------------------------------
    //  Normal button
    //------------------------------------------------------

      else{
        return (
          <div style={{alignContent: 'center'}}>
            <button className='Primary-Button' onClick={() => LogOutHandler()}>
              {title}
            </button>
          </div>
        )
      }

    //------------------------------------------------------
}