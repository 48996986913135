//================================================================
//  Component: Remove Worker Modal
//================================================================

//  Purpose: Allows organisation admins to remove workers from an organisation

//  Properties:
//    - userObject = {object, users object from the table row}
//    - setModalVisible = {A setState function - used to toggle the modal visibility}

//  Example:
//    <RemoveWorkerModal
//      userObject={userObject}
//      setModalVisible={setModalVisible}
//    ></RemoveWorkerModal>    

//================================================================


//Libraries
import React, { useContext, useState } from 'react';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components

//Functions
import WriteDocument from '../../../Library/WriteDocument';
import WriteAuditLog from '../../../Library/WriteAuditLog';

//Images
import DeleteIcon from '../../../Components/Images/Icon_Delete_Red.svg';
import LoadingIcon from '../../../Components/Images/Image_Loading_Ripple.svg';



export default function RemoveWorkerModal({
  formData,
  setFormData,
}) {

  //------------------------------------------------------
  //  Contexts
  //------------------------------------------------------

  const getUser = useContext(GetUser)

  //------------------------------------------------------
  //  Contexts
  //------------------------------------------------------

  // Used to determine the state of the modal > 'onload', 'pending', 'error-fatal'
  const [modalStatus, setModalStatus] = useState('onload');

  // Used to determine if the 'Remove User' button is active
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(false);

  // Firebase error message
  const [modalErrors, setModalErrors] = useState('');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Function that updates the user document
  function handleDeleteUser(){

    setRemoveButtonDisabled(true);
    setModalStatus('pending');

    // We ALWAYS delete org admins/workers to PII data is removed
    const document = {
      'status': 'inactive',
      'decommissiondate': new Date()
    };

    WriteDocument('users', formData.deleteModalUser?.emailaddress, document, true).then(() => {

      // Auditing
      const message = `${getUser.emailaddress} -- Deleted worker ${formData.deleteModalUser?.emailaddress} from organisation ${getUser.organisationname}.`;
      const types = [
        'organisation settings'
      ];
      const references = [
        getUser.organisationname,
        formData.deleteModalUser?.emailaddress,
        getUser.emailaddress,
        'organisations',
      ];

      return WriteAuditLog(getUser.emailaddress, message, types, references).then(() => {

        setFormData({
          'pageStatus': 'pending',
          'deleteModalUser': undefined
        });
        setModalStatus('onload');

      });

    }).catch((error) => {

      console.log('Remove Workers Modal', error);
      setModalStatus('error-fatal');
      setModalErrors(error);

    });

  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  if (formData.deleteModalUser === undefined) return null;

  //====================================================
  //  Pending
  //====================================================

  else if (modalStatus === 'pending') {

    return (
      <div className='Modal-Background'>
        <dialog className='Remove-Worker-Container'>
          <div className=' flex justify-center'>
            <img alt="loading-circle-icon" src={LoadingIcon} width='200px'></img>
          </div>
        </dialog>
      </div>
    )
  }

  //====================================================
  //  Error-Fatal
  //====================================================

  else if (modalStatus === 'error-fatal') {

    return (
      <div className='Modal-Background'>
        <dialog className='Remove-Worker-Container'>
          <div className='flex flex-col text-center gap-2'>
              <h4>Oops something went wrong!</h4>
              
              <p className=''>
                An unknown error has occurred while attempting to delete <strong>{formData.deleteModalUser?.emailaddress}</strong>
                <br></br><br></br>
                If the error persists, please speak with your administrator. 
              </p>
              <p > 
                <b>Message:</b> {modalErrors}
              </p>

              <div>
                <button className='Primary-Button' style={{backgroundColor: '#D85C5C', border: '1px solid #D85C5C'}} onClick={() => setFormData({ 'deleteModalUser': undefined })}>Go Back</button>
              </div>
          </div>
        </dialog>
      </div>
    )
  }

  //====================================================
  //  OnLoad
  //====================================================

  else {
    return (
      <div className='Modal-Background'>
        <div className='Remove-Worker-Container'>
          <div className='Action-Modal-Container'>
  
            {/* Modal Icon */}
            <div className='Action-Modal-Icon'>
              <img src={DeleteIcon} alt='Delete-Icon' width='45px'></img>
            </div>
            
            {/* Modal Body */}
            <div className='Action-Modal-Body'>
  
              <h4 className='mt-2'> Delete Worker </h4>
  
              <p> 
                Are you sure you want to delete <strong>{formData.deleteModalUser?.emailaddress}</strong>?
                <br></br>
                <br></br>
                All PII data for this worker will be <strong>permanently deleted</strong> and cannot be recovered.
              </p>
  
              {/* Invite User Form */}
              <div className='Action-Modal-Body-Form'>
                
                {/* Submit Button */}
                <div className='flex flex-row gap-2 mt-3'>
                  <button 
                    style={{backgroundColor: '#D85C5C', border: '1px solid #D85C5C'}}
                    className='Primary-Button px-3' 
                    onClick={() => handleDeleteUser()}
                    type='button'
                    disabled={removeButtonDisabled}
                  > 
                    Remove
                  </button>
                  <button 
                    className='Secondary-Button'
                    style={{minWidth: '100px', color: '#D85C5C', borderColor: '#D85C5C'}} 
                    onClick={() => setFormData({'deleteModalUser': undefined})}
                    type='button'
                  >
                    Cancel
                  </button>
                </div>
  
              </div>
  
            </div>
          </div>
        </div>
      </div>
    )
  }

}
