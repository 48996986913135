// Library
import React, { useContext } from 'react'
import { getAuth, signInWithRedirect, signInWithPopup } from 'firebase/auth';

// Contexts
import { GetMicrosoftFireBaseProvider, SetAppStatus, SetAppErrors } from '../GlobalContexts';

// Images
import MicrosoftLogo from '../../Components/Images/Microsoft_Logo.png';

// CSS
import './MicrosoftLoginForm.css';

/**
 *  Trigger a login through Firebase Microsoft Auth Provider
 * 
 *  @param {undefined} NotApplicable
 * 
 *  ```
 *  <MicrosoftLoginForm></MicrosoftLoginForm>    
 *  ```
 * 
 *  Documentation
 *  - https://firebase.google.com/docs/auth/web/microsoft-oauth
 */

export default function MicrosoftLoginForm() {

  //------------------------------------------------------
  //  Import Firebase
  //  https://firebase.google.com/docs/auth/web/microsoft-oauth#handle_the_sign-in_flow_with_the_firebase_sdk
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  // useContexts & useStates
  //------------------------------------------------------

    //Firebase
    const Provider = useContext(GetMicrosoftFireBaseProvider);
    const setAppStatus = useContext(SetAppStatus);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  // This function triggers a redirect signin > AuthProvider.js will handle the results
  // https://firebase.google.com/docs/auth/web/microsoft-oauth
  //------------------------------------------------------

    function SignIn(){

      // If Development > Pop-up
      if (process.env.REACT_APP_FIREBASE_AUTH_DOMAIN.includes('firebaseapp.com')) {

        return signInWithPopup(auth, Provider)
        .catch((error) =>{
  
          setAppStatus('failed');
          setAppErrors(error.message);
  
        });
        
      }

      // If Production > Redirect
      signInWithRedirect(auth, Provider)
      .catch((error) =>{

        setAppStatus('failed');
        setAppErrors(error.message);

      });

    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <button className='Microsoft-Login-Button' onClick={SignIn}>

        {/* Microsoft Icon */}
        <img alt='Microsoft_Logo' src={MicrosoftLogo} width='20px'></img>
        
        Sign in with Microsoft
      
      </button>
    )

  //------------------------------------------------------
}