// Library
import React, { useContext } from 'react'
import { getAuth, signInWithRedirect } from "firebase/auth";

// Contexts
import { GetGoogleFireBaseProvider, SetAppStatus, SetAppErrors } from '../GlobalContexts';

// Images
import GoogleLogo from '../../Components/Images/Google_Logo.png';

// CSS
import './GoogleLoginForm.css';

/**
 *  Trigger a login through Firebase Google Auth Provider
 * 
 *  @param {undefined} NotApplicable
 * 
 *  ```
 *  <GoogleLoginForm></GoogleLoginForm>    
 *  ```
 * 
 *  Documentation 
 *  - https://firebase.google.com/docs/auth/web/google-signin#handle_the_sign-in_flow_with_the_firebase_sdk
 *  - https://lendleasegroup.atlassian.net/wiki/spaces/ARI/pages/223372017796/Google+Auth+--+Error+400+redirect+uri+mismatch
 */

export default function GoogleLoginForm() {

  //------------------------------------------------------
  //  Import Firebase
  //  https://firebase.google.com/docs/auth/web/google-signin#handle_the_sign-in_flow_with_the_firebase_sdk
  //------------------------------------------------------

    const auth = getAuth();

  //------------------------------------------------------
  // useContexts & useStates
  //------------------------------------------------------

    //Firebase
    const Provider = useContext(GetGoogleFireBaseProvider);
    const setAppStatus = useContext(SetAppStatus);
    const setAppErrors = useContext(SetAppErrors);

  //------------------------------------------------------
  // This function triggers a redirect signin > AuthProvider.js will handle the results
  // https://firebase.google.com/docs/auth/web/google-signin
  //------------------------------------------------------

    function SignIn(){

      signInWithRedirect(auth, Provider)
      .catch((error) =>{

        setAppStatus('failed');
        setAppErrors(error.message);

      });

    }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <button className='Google-Login-Button' onClick={SignIn}>

        {/* Google Icon */}
        <img alt="Google_Logo" src={GoogleLogo} width="20px"></img>
        
        Sign in with Google
      
      </button>
    )

  //------------------------------------------------------
}