//================================================================
//  Page: Settings 
//================================================================

//  Purpose: This page allows organisation admins to manage their workers and their projects

//================================================================

// Libraries
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Contexts
import { GetUser } from '../../Library/GlobalContexts';

// Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import TabView from '../../Components/TabView/TabView';
import Workers from './Components/Workers';

// Images
import SettingsIcon from '../../Components/Images/Icon_Settings_Blue.svg';
import BackIcon from '../../Components/Images/Icon_Back_Blue.svg';

export default function Settings() {

  // ------------------------------------------------------
  //  React Router & Contexts
  // ------------------------------------------------------

    const navigate = useNavigate();
    const getUser = useContext(GetUser);

  // ------------------------------------------------------
  //  HTML
  // ------------------------------------------------------

  return (
    <PageComponent
      requiredRoles={['orgAdmin']}
      requiredRolesValues={ [getUser?.organisationid] }
      status={'onload'}
      header={
        <div className='flex flex-row justify-between m-[2%] [@media(max-width:500px)]:my-[8%] [@media(max-width:500px)]:mt-[5%]'>

          <div className='flex flex-row gap-4 items-center'>

            {/* Icon */}
            <img src={SettingsIcon} alt='Settings'></img>
          
            {/* Title */}
            <div className='font-medium text-[1.45rem]'>
              Settings
            </div>

          </div>

          {/* Back Button */}
          <button className='Secondary-Button flex flex-row gap-2 mb-0' onClick={() => navigate('/')}>
            <img src={BackIcon} alt='Back'></img>
            Back
          </button>
          
        </div>
      }
      body={
        <div className='mx-[2%] my-0'>
          <TabView
            defaultView={1}
            oneTitle={'Workers'}
            oneContent={
              <Workers></Workers>
            }
            oneRoute={'/settings&view=workers'}
          ></TabView>
        </div>
      }
    ></PageComponent>
  )
}
