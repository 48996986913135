//================================================================
//  Component: Profile Uploads
//  Created by Eve Taylor
//  Modified by Benno ~ 25/08/2023
//================================================================

//  Purpose: This component handles the uploading of profile file to the GCS bucket

//  Properties:
//  - formData = {useState, used to store file inputs for form}
//  - setFormData = {useState, used to update useState with uploaded files url}

//  Example:
//    <ProfileUploads
//      formData={formData}
//      setFormData={setFormData}
//     ></ProfileUploads>

//================================================================


//Libraries
import React, { useState, useContext, useEffect } from 'react';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components

//Functions
import UploadFile from '../../../Library/UploadFile';

//Images
import LoadingIcon from '../.././../Components/Images/Icon_LoadingFile_Grey.gif';
import RemoveFileIcon from '../.././../Components/Images/Remove.svg';

//CSS
import '../Profile.css'


export default function ProfileUploads({
    formData,
    setFormData,
}) {

    //------------------------------------------------------
    //  useContext
    //------------------------------------------------------

    const getUser = useContext(GetUser);

    //------------------------------------------------------
    //  useStates & useRef
    //------------------------------------------------------

    // Handles the state of the upload > 'onchange', 'pending', 'success', 'error-fatal'
    const [uploadStatus, setUploadStatus] = useState('onchange');

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // File Selector > Event Handler
    function handleChange(file) {

        //------------------------------------------------------
        // Validate incoming file
        //------------------------------------------------------

        if (!file) return setFormData({'profileUploadsErrorMessage' : 'Select a File'});

        let fileType;
        try {
            fileType = file.name.split('.')
            fileType = fileType[fileType.length - 1].toLowerCase();

        } catch (error) {
            
            return setFormData({
                'profileUploadsValid': false,
                'profileUploadsErrorMessage' : 'Supported file types include png, jpeg, jpg and pdf.'
            });

        }

        //------------------------------------------------------
        //  Client side validation --> Otherwise the upload MUST complete before the storage rules reject the request
        //------------------------------------------------------

        if (file.size > 20000000) return setFormData({
            'profileUploadsValid': false,
            'profileUploadsErrorMessage' : 'File must be smaller then 20MB'
        });
        if (fileType !== 'png' && fileType !== 'jpeg' && fileType !== 'jpg' && fileType !== 'pdf') return setFormData({
            'profileUploadsValid': false,
            'profileUploadsErrorMessage' : 'Supported file types include png, jpeg, jpg and pdf.'
        });


        //------------------------------------------------------
        // Passed all checks > proceed with upload
        //------------------------------------------------------

        setFormData({
            'profileUploadsErrorMessage' : '',
            'profileUploadsValid' : true
        });
        setUploadStatus('pending');

        const fileName = `${Date.now()}.${fileType}`;

        //Upload the file to GCS
        UploadFile(`tempuploads/${getUser?.emailaddress}/${fileName}`, file).then((url) => {

            // Save the file URL and file name to formData
            formData.profileUploadsFiles.push({
                'id': fileName,
                'signedurl': url,
                'filename': file.name,
                'status': 'new',
            });
            setUploadStatus('success');

        }).catch((error) => {

            setFormData({'profileUploadsErrorMessage' : error.message});
            setUploadStatus('error-fatal');

        });

    }

    // Remove file handler
    function removeFileHandler(object) {

        // Flag file to be deleted
        object.status = 'deleted';

        setFormData({'profileUploadsFiles': formData.profileUploadsFiles});

        // If no files exists, change status
        if (formData.profileUploadsFiles.length === 0){
            setUploadStatus('onchange');
        }

    }

    // Try Again Button Handler
    function handleUploadStatus(status) {

        setFormData({'profileUploadsErrorMessage' : ''});
        setUploadStatus(status);

    }

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    //onLoad
    // 1. Check if there are existing files
    // 2. If so, change the components status
    useEffect(() => {

        if (formData.profileUploadsFiles === undefined) return;
        if (formData.profileUploadsFiles.length === 0) return;
        if (uploadStatus !== 'onchange') return;

        setUploadStatus('success');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.profileUploadsFiles])

    //------------------------------------------------------
    //  Return HTML
    //------------------------------------------------------

        //------------------------------------------------------
        //  onchange
        //------------------------------------------------------

        if (uploadStatus === 'onchange') {
            return (
                <>
                    {/* File Input Field */}
                    <div className='ProfileUploads-Container'>
                        <input
                            className={formData.profileUploadsValid ? ('Input-Field-Text') : ('Input-Field-Text-Error')}
                            type='file'
                            onChange={(e) => handleChange(e.target.files[0])}
                            style={{padding: '0px'}}
                        ></input>
                    </div>

                    {/* Uploads Table */}
                    {
                        formData?.profileUploadsFiles.length > 0 &&
                        <div className='ProfileUploads-List-Container'>

                            <b>FILE NAME</b>

                            {/* File Link */}
                            {
                                formData?.profileUploadsFiles.map((object, index) => (
                                    object.status !== 'deleted' && (
                                        <div className='ProfileUploads-List-Row' key={index}>
                                            {/* Delete File */}
                                            <img 
                                                className='ProfileUploads-List-Delete' 
                                                src={RemoveFileIcon} 
                                                alt='Remove File' 
                                                title='Clear File' 
                                                onClick={() => removeFileHandler(object)}
                                            ></img>

                                            {/* File Name & Link */}
                                            <p><a href={object?.signedurl} target='_blank' rel='noreferrer'>{object.filename}</a></p>
                                        </div>
                                    )
                                ))
                            }
                        </div>
                    }

                    {/* Error Message */}
                    {!formData.profileUploadsValid && <label className='ProfileUploads-Error-Message'>{formData.profileUploadsErrorMessage}</label>}

                </>
            )

        }

        //------------------------------------------------------
        //  pending
        //------------------------------------------------------

        else if (uploadStatus === 'pending') {
            return (
                <>
                    <div className='ProfileUploads-Container'>
                        <img src={LoadingIcon} alt='loading-icon' width='20px' height='20px'></img>
                        <div>
                            Uploading...
                        </div>
                    </div>
                </>
            )

        }

        //------------------------------------------------------
        //  success
        //------------------------------------------------------

        else if (uploadStatus === 'success') {
            return (
                <>
                    {/* File Input Field */}
                    <div className='ProfileUploads-Container'>
                        <input
                            className={formData.profileUploadsValid ? ('Input-Field-Text') : ('Input-Field-Text-Error')}
                            type='file'
                            onChange={(e) => handleChange(e.target.files[0])}
                            style={{padding: '0px'}}
                        ></input>
                    </div>

                    {/* Uploads Table */}
                    <div className='ProfileUploads-List-Container'>

                        <b>FILE NAME</b>

                        {/* File Link */}
                        {
                            formData.profileUploadsFiles.map((object, index) => (

                                object.status !== 'deleted' && (

                                    <div className='ProfileUploads-List-Row' key={index}>

                                        {/* Delete File */}
                                        <img 
                                            className='ProfileUploads-List-Delete' 
                                            src={RemoveFileIcon} 
                                            alt='Remove File' 
                                            title='Clear File' 
                                            onClick={() => removeFileHandler(object)}
                                        ></img>

                                        {/* File Name & Link */}
                                        <p><a href={object?.signedurl} target='_blank' rel='noreferrer'>{object.filename}</a></p>


                                    </div>

                                )

                            ))
                        }
                    </div>

                    {/* Error Message */}
                    {!formData.profileUploadsValid && <label className='ProfileUploads-Error-Message'>{formData.profileUploadsErrorMessage}</label>}
                    
                </>
            )

        }

        //------------------------------------------------------
        //  error-fatal
        //------------------------------------------------------

        else if (uploadStatus === 'error-fatal') {

            return (
                <div className='ProfileUploads-Error-Container'>
                    Failed to upload file, please try again.

                    {/* Try Again */}
                    <button className='Primary-Button' style={{ width: 'fit-content' }} onClick={() => handleUploadStatus('onchange')}>Try Again</button>
                </div>
            )

        }

    //------------------------------------------------------
}
